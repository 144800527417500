import React from 'react';
import { useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
// import popupBg from './../Images/popup-bg1.jpeg';
// import popupBg from './../Images/website-images/popup3.png';
// import popupBg from './../Images/website-images/popup4.png';
import popupBg from './../Images/website-images/popup5.png';

function Popup(props) {
  const navigate = useNavigate();
  function handleJoin() {
    navigate('/signup');
  }
  return (
    props.trigger
  ) ? (
    <div className='popup'>
      <div className='popup-inner' >

        <button type="button" class="btn-close" aria-label="Close" style={{}} onClick={() => props.setTrigger(false)}></button>


        <div className="float-popup-bg"
          style={{ backgroundImage: `url(${popupBg})`, backgroundPositionX: 'left', height: '35rem' }}
        >
          
          <div
            // className="float-content"
            style={{
              height: '35rem', width: '60rem', padding: '3rem 0 0 2rem'
            }}>
            <h2>READY TO BE A PART OF <br /> SOMTHING <span class='orange-big'> BIG</span>?</h2>
            <p>we're on a mission to <span class='orange-big'> REVOLUTIONIZE </span> <br /> the construction & steel industry!</p>
            <br />
            {/* <button class="popup-btn btn1">JOIN US NOW  <i class="bi bi-arrow-right"></i> </button> */}
            <button onClick={handleJoin} class="btn-down btn1">JOIN US NOW  <i class="bi bi-arrow-right"></i> </button>

            <h2>STILL UN<span class='orange-big'>SURE?</span></h2>
            <p>Try our servises firsthand <br /> with <span class='orange-big'> FREE </span> trial</p>

            {/* <button class="popup-btn btn2">GET FREE ESTIMATE <i class="bi bi-arrow-right"></i> </button> */}
            <HashLink to="/#popup1" onClick={() => props.setTrigger(false)}  class="btn-down btn2 text-decoration-none">GET FREE ESTIMATE <i class="bi bi-arrow-right"></i> </HashLink>

            {/* <img src={popupBg} width='100%' height='100%' style={{ display:'hidden'}}/ >   */}
            {/* <div className="float-txt contact-heading mb-4">
              <h1 className='mb-4'>Get ready, <br />
                for a revolution in Construction</h1>
              <h2>intelBUILD Engineering Services LLP</h2>
            </div>

            <div className="pp-btn">

              <HashLink className='nav-btn' onClick={() => props.setTrigger(false)} to="/#popup" >Get Your Free Estimation</HashLink>
            </div> */}

          </div>

          {/* <div className='btm_lines'>
            <div className='btm_rectangle'></div>
          </div> */}
        </div>
      </div>
    </div>
  ) : "";
}

export default Popup