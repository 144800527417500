 import structureImage from "../../Images/peb/intel slider images/1.jpg";
import structureImage2 from "../../Images/peb/intel slider images/2.jpg";
import structureImage3 from "../../Images/peb/intel slider images/3.jpg";
import structureImage4 from "../../Images/peb/intel slider images/4.jpg";
import structureImage5 from "../../Images/peb/intel slider images/5.jpg";
import structureImage6 from "../../Images/peb/intel slider images/6.jpg";
import structureImage7 from "../../Images/peb/intel slider images/7.jpg";
import structureImage8 from "../../Images/peb/intel slider images/8.jpg";
import structureImage9 from "../../Images/peb/intel slider images/9.jpg";
import structureImage10 from "../../Images/peb/intel slider images/10.jpg";
import structureImage11 from "../../Images/peb/intel slider images/11.jpg";

export const structuredData = [
  {
    title: "Project Assessment",
    content:
      "Understand the project requirements, including the purpose of the building, its size, location, and any specific functional or design considerations.",
    image: structureImage,
  },
  {
    title: "Site Evaluation",
    content:
      "Assess the site where the pre-engineered building will be constructed, taking into account factors such as topography, soil conditions, accessibility, and local building codes.",
    image: structureImage2,
  },
  {
    title: "Conceptual Design",
    content:
      "Develop a preliminary design concept for the pre-engineered building, considering factors like the layout, architectural style, and overall functionality. This includes determining the clear span requirements and any specific design features.",
    image: structureImage3,
  },
  {
    title: "Structural Analysis",
    content:
      "Perform a structural analysis to determine the load-bearing capacity required for the building based on factors such as wind loads, snow loads, seismic activity, and other relevant environmental considerations.",
    image: structureImage4,
  },
  {
    title: "Material Selection",
    content:
      "Choose the appropriate materials for the pre-engineered building, considering factors such as structural integrity, durability, thermal performance, and aesthetics. This includes selecting the type of steel framing, roofing systems, wall panels, insulation, and other components.",
    image: structureImage5,
  },
  {
    title: "Detailed Design",
    content:
      "Develop detailed construction drawings and specifications based on the conceptual design, structural analysis, and material selection. This includes the design of individual components, connections, and interfaces.",
    image: structureImage6,
  },
  {
    title: "Permittting & Approvals",
    content:
      "Obtain the necessary permits and approvals from local authorities and regulatory bodies before proceeding with the construction of the pre-engineered building.",
    image: structureImage7,
  },
  {
    title: "Fabrication & Manufacturing",
    content:
      "Fabricate the pre-engineered building components in a controlled factory environment, ensuring precise measurements, quality control, and adherence to design specifications.",
    image: structureImage8,
  },
  {
    title: "Site Preparation",
    content:
      "Prepare the construction site by clearing the area, leveling the ground, and ensuring proper foundation preparation in accordance with the building design and local requirements.",
    image: structureImage9,
  },
  {
    title: "Erection & Installation",
    content:
      "Assemble and install the pre-engineered building components on-site, following the manufacturer's guidelines and recommended construction practices. This includes erecting the steel frame, installing wall panels, roofing systems, and other structural elements.",
    image: structureImage10,
  },
  {
    title: "Finishing & Interior Work",
    content:
      "Complete the finishing touches of the pre-engineered building, including interior partitions, electrical and plumbing systems, HVAC installations, and any other required interior features.",
    image: structureImage11,
  },
  {
    title: "uality Assurance and Inspections: ",
    content:
      "Conduct thorough inspections throughout the construction process to ensure compliance with design specifications, quality standards, and safety regulations.",
    image: structureImage11,
  },
  {
    title: "Commissioning and Handover: ",
    content:
      "Test and commission all building systems and components to ensure proper functioning. Once the pre-engineered building is deemed ready, hand it over to the client or end-user.",
    image: structureImage11,
  },
  
];
