 import structureImage from "../../Images/peb/intel slider images/1.jpg";
import structureImage2 from "../../Images/peb/intel slider images/2.jpg";
import structureImage3 from "../../Images/peb/intel slider images/3.jpg";
import structureImage4 from "../../Images/peb/intel slider images/4.jpg";
import structureImage5 from "../../Images/peb/intel slider images/5.jpg";
import structureImage6 from "../../Images/peb/intel slider images/6.jpg";
import structureImage7 from "../../Images/peb/intel slider images/7.jpg";
import structureImage8 from "../../Images/peb/intel slider images/8.jpg";
import structureImage9 from "../../Images/peb/intel slider images/9.jpg";
import structureImage10 from "../../Images/peb/intel slider images/10.jpg";
import structureImage11 from "../../Images/peb/intel slider images/11.jpg";

export const structuredData = [
  { 
    id:'1',
    title: "Architectural Importance",
    content:
      " Our BIM products enhance design quality, elevating the creativity and aesthetics of structures. Through strong concept design and modelling, projects become more flexible, achieving solid and sustainable designs. The benefits of BIM include efficient collaboration with structural and mechanical engineers, reduced project duration, improved design control, and enhanced communication with clients through 3D visuals.",
    image: structureImage,
  },
  {
    id:'2',
    title: "Structure Design and Analysis",
    content:
      " BIM enables accurate documentation and coordination of interlinked activities, optimizing project outcomes. The 3D model database system facilitates efficient design, detailing, and fabrication of structures. With improved communication and well-oriented performance, decisions can be made easily, reducing design errors and achieving sustainable designs. Efficient coordination of workers on-site and streamlined management of information lead to significant reductions in construction time.",
    image: structureImage2,
  },
  {
    id:'3',
    title: "MEP (Mechanical, Electrical, and Plumbing)",
    content:
      " Our expertise extends to residential, industrial, and commercial structures. BIM expedites project delivery from design to construction, enhancing project quality and accuracy. It enables efficient goal achievement and offers high-quality objects in terms of geometry and information. Key services include BIM coordination for clash detection and generating clash reports, MEP shop drawings for fabrication and erection details, MEP 3D modeling, and providing as-built drawings to demonstrate the scope of work.",
    image: structureImage3,
  },
  
  
];
