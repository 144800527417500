import React from 'react'
import SusIcon from './../Images/website-images/line-segment.png';


function Sustainable(){
    return(
<>
         {/* sustainale start */}

         <div class="sus-resizable">
         <div class="sus-square">
             <div class="sus-square2">
                 <div class="sus-top-notch"></div>
                 <h1>Key Principles of
                     <br/>
                     Sustainable Building Design
                 </h1>
                 <div>
                     <div class="sus-box b1">
                         <div class="sus-info">
                             <h3>step 1</h3>
                             <p>Set goals and objectives for sustainability </p>
                             <img style={{width: "auto"}} src={SusIcon} alt=""/> 
                         </div>
                         <div class="sus-circle"><i class="bi bi-search"></i></div>
                     </div>
                     <div class="sus-box b2">
                         <div class="sus-info">
                             <h3>step 2</h3>
                             <p>Design for energy efficiency, water conservation, and optimal indoor environmental quality. </p>
                                 <img style={{width: "auto"}} src={SusIcon} alt=""/> 
                         </div>
                         <div class="sus-circle"><i class="bi bi-file-earmark-text"></i></div>
                     </div>
                     <div class="sus-box b3">
                         <div class="sus-info">
                             <h3>step 3</h3>
                             <p>Select sustainable materials with low environmental impact </p>
                             <img style={{width: "auto"}} src={SusIcon} alt=""/> 
 
                         </div>
                         <div class="sus-circle"><i class="bi bi-file-earmark-text-fill"></i></div>
                     </div>
                     <div class="sus-box b4">
                         <div class="sus-info">
                             <h3>step 4</h3>
                             <p>Incorporate renewable energy sources into the design </p>
                             <img style={{width: "auto"}} src={SusIcon} alt=""/> 
 
                         </div>
                         <div class="sus-circle"><i class="bi bi-lightbulb"></i></div>
                     </div>
                     <div class="sus-box b5">
                         <div class="sus-info">
                             <h3>step 5</h3>
                             <p>Monitor and optimize performance, engage stakeholders, and seek green building certifications. </p>
                             <img style={{width: "auto"}} src={SusIcon} alt=""/> 
 
                         </div>
                         <div class="sus-circle"><i class="bi bi-hand-thumbs-up"></i></div>
                     </div>
                     <div class="sus-big-circle"></div>
 
                 </div>
             </div>
         </div>
     </div>
     </>
 
                    //  {/* sustainale end */}
    )
};
export default Sustainable
// import React from 'react'
// import SusIcon from './../Images/website-images/line-segment.png';


// function Sustainable(){
//     return(
// <>
//          {/* sustainale start */}

//          <div class="sus-resizable">
//          <div class="sus-square">
//              <div class="sus-square2">
//                  <div class="sus-top-notch"></div>
//                  <h1>Key Principles of
//                      <br/>
//                      Sustainable Building Design
//                  </h1>
//                  <div>
//                      <div class="sus-box b1">
//                          <div class="sus-info">
//                              <h3>step 1</h3>
//                              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.  </p>
//                              <img src={SusIcon} alt=""/> 
//                          </div>
//                          <div class="sus-circle"><i class="bi bi-search"></i></div>
//                      </div>
//                      <div class="sus-box b2">
//                          <div class="sus-info">
//                              <h3>step 2</h3>
//                              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
//                                  <img src={SusIcon} alt=""/> 
//                          </div>
//                          <div class="sus-circle"><i class="bi bi-file-earmark-text"></i></div>
//                      </div>
//                      <div class="sus-box b3">
//                          <div class="sus-info">
//                              <h3>step 3</h3>
//                              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.  </p>
//                              <img src={SusIcon} alt=""/> 
 
//                          </div>
//                          <div class="sus-circle"><i class="bi bi-file-earmark-text-fill"></i></div>
//                      </div>
//                      <div class="sus-box b4">
//                          <div class="sus-info">
//                              <h3>step 4</h3>
//                              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
//                              <img src={SusIcon} alt=""/> 
 
//                          </div>
//                          <div class="sus-circle"><i class="bi bi-lightbulb"></i></div>
//                      </div>
//                      <div class="sus-box b5">
//                          <div class="sus-info">
//                              <h3>step 5</h3>
//                              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
//                              <img src={SusIcon} alt=""/> 
 
//                          </div>
//                          <div class="sus-circle"><i class="bi bi-hand-thumbs-up"></i></div>
//                      </div>
//                      <div class="sus-big-circle"></div>
 
//                  </div>
//              </div>
//          </div>
//      </div>
//      </>
 
//                     //  {/* sustainale end */}
//     )
// };
// export default Sustainable