import {useState} from "react"
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, EffectCube } from "swiper/modules";
import "./iBlibrary.css";
import { Link } from "react-router-dom";
import 'swiper/css';
import Calculator1 from "../Images/IbLibrary/cal1.jpeg";
import Calculator2 from "../Images/IbLibrary/cal2.jpeg";
import Cal3 from "../Images/IbLibrary/Group 4382(1).png";
// import caulator2 from "";
import Book12 from "../Images/IbLibrary/book12.png";
import Book11 from "../Images/IbLibrary/Book11.jpg";
import BGHome from "../Images/bg-home-3.png"
import { useNavigate } from "react-router-dom";
export const Slider = ({ slides }) => {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false)
  const handleExplore = () => { navigate("/iblibrary") }
  function handleReadMore(){
    setToggle(prev=>!prev)
  }
  return (
  
  <>
  
    <Swiper
      className="swipper"
      modules={[Navigation, Pagination, Scrollbar, A11y, EffectCube]}
      spaceBetween={50}
      // slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
      // effect={"cube"}
      // cubeEffect={{
      //   shadow: false,
      //   slideShadows: true,
      //   shadowOffset: 20,
      //   shadowScale: 0.94,
      // }}
    >
      <SwiperSlide>
        <div className="slider-container">
          <div className="left-side position-relative">
            <img src={BGHome} alt="BGHome-Img"/>
            <div className="left-side-content">
              <h2>IB Library</h2>
              <p>{!toggle ? "Are you a civil/ structural engineer, or rather a student, researcher, or educator who is trying to stay up-to-date with the latest information & resources essential for your success in staying ahead of your competitors? You are at the right place, alongside the top engineers on the globe we have curated the iBLibrary for your convenience which offers you with premium access to a digital collection of 200+ high-quality e-books, journals, magazines, articles, research papers and ..." : "Are you a civil/ structural engineer, or rather a student, researcher, or educator who is trying to stay up-to-date with the latest information & resources essential for your success in staying ahead of your competitors? You are at the right place, alongside the top engineers on the globe we have curated the iBLibrary for your convenience which offers you with premium access to a digital collection of 200+ high-quality e-books, journals, magazines, articles, research papers and other resources related to structural and civil engineering, including design, analysis, and construction which are updated on a regular basis. The iBLibrary subscription provides you with unlimited access to the personalized collection of resources, and recommendations based on your interests, with the ability to save and organize resources for future use. In addition, the iBLibrary subscription is designed to be user-friendly and easy to use, with a simple search interface that allows you to quickly find the information you need. You can access our library from anywhere, at any time, making it a convenient solution for your information needs. Would you like to learn more about the iBLibrary subscription and how it can benefit you as a structural engineer? Click on the button below."}</p>
              <Link className="link d-flex">
                <button className="read-more" onClick={handleReadMore}>{!toggle ? "Read More" : "Read Less"}</button>
                {/* <button className="explore"> */}
                  {/* Explore */}
                  <Link to="/iblibrary" className="text-decoration-none explore">Explore</Link>
                {/* </button> */}
              </Link>
            </div>
          </div>
          <div className="right-side">
            <div className="book-detail">
              <img src={Book12} alt="" />
              <div className="detail">
                <h2>Explore The Library</h2>
                {/* <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p> */}
                {/* <h2>₹150</h2> */}
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="slider-container">
          <div className="left-side position-relative">
            <img src={BGHome} alt="BGHome-Img"/>
            <div className="left-side-content">
              <h2>IB Calculator1</h2>
              <p>{!toggle ? "Are you a civil/ structural engineer, or rather a student, researcher, or educator who is trying to stay up-to-date with the latest information & resources essential for your success in staying ahead of your competitors? You are at the right place, alongside the top engineers on the globe we have curated the iBLibrary for your convenience which offers you with premium access to a digital collection of 200+ high-quality e-books, journals, magazines, articles, research papers and ..." : "Are you a civil/ structural engineer, or rather a student, researcher, or educator who is trying to stay up-to-date with the latest information & resources essential for your success in staying ahead of your competitors? You are at the right place, alongside the top engineers on the globe we have curated the iBLibrary for your convenience which offers you with premium access to a digital collection of 200+ high-quality e-books, journals, magazines, articles, research papers and other resources related to structural and civil engineering, including design, analysis, and construction which are updated on a regular basis. The iBLibrary subscription provides you with unlimited access to the personalized collection of resources, and recommendations based on your interests, with the ability to save and organize resources for future use. In addition, the iBLibrary subscription is designed to be user-friendly and easy to use, with a simple search interface that allows you to quickly find the information you need. You can access our library from anywhere, at any time, making it a convenient solution for your information needs. Would you like to learn more about the iBLibrary subscription and how it can benefit you as a structural engineer? Click on the button below."}</p>
              <Link className="link d-flex">
                <button className="read-more" onClick={handleReadMore}>{!toggle ? "Read More" : "Read Less"}</button>
                {/* <button className="explore"> */}
                  {/* Explore */}
                  <Link to="/iblibrary" className="text-decoration-none explore">Explore</Link>
                {/* </button> */}
              </Link>
            </div>
          </div>
          <div className="right-side" style={{width:"600px"}}>
            <div className="book-detail" style={{boxShadow:"none"}}>
              <img src={Calculator1} alt="" style={{height:"500px" , width:"450px"}} />
              {/* <div className="detail">
                <h2>No Longer Human</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
                <h2>₹150</h2>
              </div> */}
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="slider-container">
          <div className="left-side position-relative">
            <img src={BGHome} alt="BGHome-Img"/>
            <div className="left-side-content">
              <h2>IB Calculator</h2>
              <p>{!toggle ? "  iBCalculator is an innovative structural analysis tool, unavailable elsewhere in the Indian market. It offers wind, interpolation, and base plate calculators. This user-friendly tool ensures accurate results, saves time, and provides comprehensive customer support for engineers and architects. Join the growing community and experience seamless calculations for your projects today.Welcome to iBCalculator,... " : "iBCalculator is an innovative structural analysis tool, unavailable elsewhere in the Indian market. It offers wind, interpolation, and base plate calculators. This user-friendly tool ensures accurate results, saves time, and provides comprehensive customer support for engineers and architects. Join the growing community and experience seamless calculations for your projects today Welcome to iBCalculator. iBCalculator is an innovative structural analysis tool, unavailable elsewhere in the Indian market. It offers wind, interpolation, and base plate calculators. This user-friendly tool ensures accurate results, saves time, and provides comprehensive customer support for engineers and architects. Join the growing community and experience seamless calculations for your projects today Welcome to iBCalculator,"}</p>
              <Link className="link d-flex">
                <button className="read-more" onClick={handleReadMore}>{!toggle ? "Read More" : "Read Less"}</button>
                {/* <button className="explore"> */}
                  {/* Explore */}
                  <Link to="/iblibrary" className="text-decoration-none explore">Explore</Link>
                {/* </button> */}
              </Link>
            </div>
          </div>
          <div className="right-side" style={{width:"600px"}}>
            <div className="book-detail" style={{boxShadow:"none"}}>
              <img src={Calculator2} alt="" style={{height:"500px" , width:"450px"}} />
              {/* <div className="detail">
                <h2>No Longer Human</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
                <h2>₹150</h2>
              </div> */}
            </div>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>

<div><img style={{width:"100%"}} src={Cal3}></img></div>
    </>
  );
};
export default Slider;
