
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import rome from './../Images/rome.png';
// import { IoArrowForward } from "react-icons/io5";
// import { IoArrowBack, IoTrendingUpSharp } from "react-icons/io5";
// import { BsStarFill, BsStarHalf, BsStar } from "react-icons/bs";
// import { BiCheckShield } from "react-icons/bi";

// import { FaStar, FaChevronRight, FaChevronLeft, FaUserAlt, FaMapMarkerAlt, FaWallet, FaRegBuilding } from "react-icons/fa";


// import client1 from './../Images/HINDALCO.png';
// import client2 from './../Images/gcc-hotel-and-club.png';
// import client3 from './../Images/HP.jpg';
// import client4 from './../Images/indian-oil.jpg';
// import client5 from './../Images/ltgrouplogo.jpg';
// import client6 from './../Images/platinum.jpg';
// import client7 from './../Images/Raheja_Universal.jpg';
// import client8 from './../Images/sail.png';



export default function Reviews() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        autoplayHoverPause: true,
        slidesToScroll: 1,
        // prevArrow: <PrevButton />,
        // nextArrow: <NextButton />,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,

                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,

                }
            }

        ],
        beforeChange: (current, next) => {
            console.log(next);
        }
    };
    return (

        <div className="w-100 p-5">

            <Slider {...settings}>

                <div className="item slide py-3 px-2 w-100">


                    <div class="rev-bx text-center">
                        <div class="rev-txt mb-3">
                            <img className="mx-auto" src={rome} alt="" />
                        </div>
                        <div class="rev-txt text-center max-600 mx-auto p-3">

                            <h6>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</h6>
                            <p><span>Kristin Watson</span> /  CEO, Rome</p>
                        </div>
                    </div>


                </div>
                <div className="item slide py-3 px-2  w-100">


                    <div class="rev-bx text-center">
                        <div class="rev-txt mb-3">
                            <img className="mx-auto" src={rome} alt="" />
                        </div>
                        <div class="rev-txt text-center max-600 mx-auto p-3">

                            <h6>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</h6>
                            <p><span>Kristin Watson</span> /  CEO, Rome</p>
                        </div>
                    </div>


                </div>
                <div className="item slide py-3 px-2  w-100">


                    <div class="rev-bx text-center">
                        <div class="rev-txt mb-3">
                            <img className="mx-auto" src={rome} alt="" />
                        </div>
                        <div class="rev-txt text-center max-600 mx-auto p-3">

                            <h6>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</h6>
                            <p><span>Kristin Watson</span> /  CEO, Rome</p>
                        </div>
                    </div>


                </div>



            </Slider>
        </div>
    );
}