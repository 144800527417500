import React, { useRef } from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import OilAndGasBanner from '../SubComponents/Banner'
import { Link } from 'react-router-dom'
import abt1 from './../Images/abt2.jpg';
import Cont from './../Images/ian-mustafa-icRnKh7J2Hw-unsplash.jpg';
import OilGas2 from './../Images/oil-gas2.png';
import OilGas3 from './../Images/oil-gas3.png';
import Bld1 from './../Images/bld1.png';
import Bld2 from './../Images/bld2.png';
import Bld3 from './../Images/bld3.png';
import Bld4 from './../Images/bld4.png';
import Magazine from './../Images/Magzine.jpg';
import Certificate2 from './../Images/certificate2.jpeg';
import emailjs from 'emailjs-com';
import Image1 from './../Images/image-1.png';
// tabs

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Banner from '../SubComponents/Banner'

import contact from './../Images/oil-gas1.png';
import TrustedSlider from '../SubComponents/TrustedSlider'
import Reviews from '../SubComponents/Reviews'
import SectionWipes from '../SubComponents/ParallaxHero'
// import MultiScrollSlider from '../SubComponents/MultiScrollSlider'

function Contact() {
    
    const form=useRef()
    function  sendEmail(e) {
        e.preventDefault();
        
    console.log(form.current,"current")
    emailjs.sendForm('service_gv7h749', 'template_vnalpac', form.current,'K49l0okANjKtyoz_w')
          .then((result) => {
            console.log(result.text);
            if(result.text){
                window.location='/contact'
                alert('your information is submited')
                // window.location.reload()
            }
            // window.history.reload()
          }, (error) => {
            console.log(error.text);
            alert('your information not submited')
          });
   

      }
    
    return (
        <>
            <Header />
            {/* <Banner img={Cont} title='Contact Us' /> */}
      
            <SectionWipes img={Cont} title='Contact US'/>
          {/* Contact form starts */}

<div className='pos-rel'>
          <div class="w-100 form-section py-5 gr-bg">
                <div class="px-4">
                    <div className="contact-area">
                        <div class="row contact-form contact-us">
                            <div class="col-lg-6">
                                <div className="contact-heading abt-txt">
                                    <h1>Let's Talk</h1>

                                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo neque distinctio delectus nihil exercitationem nam labore voluptatum nobis similique.
                                    </p>

                                </div>
                                <form action="" ref={form} onSubmit={sendEmail}>
                                    <div className="row">

                                        <div className="col-lg-6 mb-4">




                                            <label>First Name</label>
                                            <input type="text" placeholder="Enter First name" name='toFirstName' />

                                        </div>
                                        <div className="col-lg-6 mb-4">


                                            <label>Last Name</label>
                                            <input type="text" placeholder="Enter Last name" name='toLastName' />


                                        </div>
                                        <div className="col-lg-6 mb-4">

                                            <label>Email</label>
                                            <input type="email" placeholder="Enter Email Address" name='toEmailId' />

                                        </div>
                                        <div className="col-lg-6 mb-4">
                                            <label>Subject</label>
                                            <input type="text" placeholder="Enter Subject" name='subject' />
                                        </div>
                                        <div className="mb-4">
                                            <label>Message</label>
                                            <textarea type="text" rows={4} placeholder="Enter Message" name='message' />
                                        </div>

                                       
<div className="send-button">
                            
                              <button className="nav-btn contact-send-btn">Send</button>

                            </div> 


                                    </div>
                                    <div className="">
                                        

                                    </div>
                                </form>
                            </div>
                            <div class="col-lg-6">

                            </div>
                        </div>

                    </div>
                </div>
            </div >
          {/* contact form ends */}
          
        

            <div className="w-100 pt-0">
                <div className="px-0 mb-0">
                   
                    <div className="slider">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.288027065578!2d72.83254031437775!3d19.13886545496809!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b6277c974603%3A0x198d5b4c3ee0e30a!2sintelBUILD%20Engineering%20Services%20LLP!5e0!3m2!1sen!2sin!4v1672460416900!5m2!1sen!2sin" width="100%" height="350" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    
                    </div>
                </div>
            </div>
            {/* buildings starts */}
           
            {/* buildings ends */}




            <Footer />
            </div>
        </>
    )
}   export default Contact