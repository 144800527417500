import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import VideoPlayer from "../SubComponents/VideoPlayer";
import Testimonials from "../SubComponents/Testimonials2";
import Clients from "../SubComponents/Clients";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import Blogs from "../SubComponents/Blogs";
import OilGasBg from "./../Images/header/oil&gas.jpg";
import PebBg from "./../Images/header/peb.jpg";
import BimBg from "./../Images/header/bim.jpg";
import StructureBg from "./../Images/header/structure-bg1.jpeg";
import SustainBg from "./../Images/header/sustainale.jpg";
import RealEstateBg from "./../Images/header/real-estate.jpg";
import Popup from "../Components/Popup";
import attachBg from "./../Images/attach-bg.jpeg";
import { HashLink } from "react-router-hash-link";
import EnquiryPopup from "../Components/EnquiryPopup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import EnquiryForm from "../Forms/EnquiryForm";
import AOS from "aos";
import "aos/dist/aos.css";
import EnquiryOilGas from "../Forms/EnquiryOilGas";
import EnquiryStructuralAudit from "../Forms/EnquiryStructuralAudit";
import EnquiryRealEstate from "../Forms/EnquiryRealEstate";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { MotionAnimate } from "react-motion-animate";
import Slider from "../SubComponents/slider";

function Home(props) {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  const [showOilGasModal, setValueOilGas] = useState(false);
  const [showPEBShedModal, setValuePEBShed] = useState(false);
  const [showRealEstate, setValueRealEstate] = useState(false);
  const [showStructuralAudit, setValueStructuralAudit] = useState(false);
  const [categoryStructure, setCategoryStructure] = useState("");
  const [showFormModal, setShowModal] = useState(false);
  const [buttonPopup, setButtonPopup] = useState(false);
  const [focus, setFocus] = React.useState(false);
  const [timedPopup, setTimedPopup] = useState(false);
  const [color, setColor] = useState(false);

  const handleClose = () => setShowModal(false);

  const handleShow = () => {
    setValuePEBShed(false);
    setValueOilGas(false);
    setValueRealEstate(false);
    setValueStructuralAudit(false);

    if (categoryStructure === "PEB Shed") setValuePEBShed(true);
    else if (categoryStructure === "Oil and Gas") setValueOilGas(true);
    else if (categoryStructure === "Real Estate") setValueRealEstate(true);
    else if (categoryStructure === "Structural Audit")
      setValueStructuralAudit(true);
    setShowModal(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setTimedPopup(true);
    }, 15000);
  }, []);

  const changeColor = () => {
    if (window.scrollY >= 250) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  function structureMineChangeUrl(url) {
    window.location = url;
  }

  return (
    <>
      <Popup trigger={timedPopup} setTrigger={setTimedPopup}></Popup>

      <EnquiryPopup
        trigger={buttonPopup}
        setTrigger={setButtonPopup}
      ></EnquiryPopup>
      <div className={color ? "color-header home-scroll" : "home-scroll"}>
        <Header />
        <div className="intel-hero">
          <VideoPlayer />
          <div className="menu-for-large">
            <MotionAnimate animation="scrollFadeOut">
              <ul className="menu ps-0">
                <li className="menu-item">
                  <NavLink
                    to="/about"
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    About
                  </NavLink>
                </li>

                <li className="menu-item">
                  <NavLink
                    to="/products"
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    iBexplorer
                  </NavLink>
                </li>
                <li className="menu-item">
                  <HashLink to="/#service">Services</HashLink>
                </li>

                <li className="menu-item">
                  <NavLink
                    to="/projects"
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    Projects
                  </NavLink>
                </li>
              </ul>
            </MotionAnimate>
          </div>
          <div className="hero-overlay"></div>
        </div>
      </div>
      <div className="numbers-section">
        <div className="w-100">
          <div className="number-area" id="popup1">
            <div className="row  slide-anim" style={{marginLeft:"0"}}>
              <div className="col-3 my-3">
                <div className="number-bx">
                  <div className="number-large">
                    <h1>
                      <CountUp
                        start={focus ? 0 : null}
                        end={5}
                        duration={3}
                        redraw={true}
                      >
                        {({ countUpRef }) => (
                          <VisibilitySensor
                            onChange={(isVisible) => {
                              if (isVisible) {
                                setFocus(true);
                              }
                            }}
                          >
                            <span style={{ fontFamily: "" }} ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      +
                      <span style={{ fontFamily: "" }} className="ms-2">
                        Years
                      </span>
                    </h1>
                  </div>
                  <div className="number-desc">
                    <h2>Leading in Industry </h2>
                  </div>
                </div>
              </div>
              <div className="col-3 my-3">
                <div className="number-bx">
                  <div className="number-large">
                    <h1>
                      <CountUp
                        style={{ fontFamily: "" }}
                        start={focus ? 0 : null}
                        end={150}
                        duration={3}
                        redraw={true}
                      >
                        {({ countUpRef }) => (
                          <VisibilitySensor
                            onChange={(isVisible) => {
                              if (isVisible) {
                                setFocus(true);
                              }
                            }}
                          >
                            <span style={{ fontFamily: "" }} ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      +
                    </h1>
                  </div>
                  <div className="number-desc">
                    <h2>No. Projects</h2>
                  </div>
                </div>
              </div>
              <div className="col-3 my-3">
                <div className="number-bx">
                  <div className="number-large">
                    <h1 style={{ fontFamily: "" }}>
                      <CountUp
                        style={{ fontFamily: "" }}
                        start={focus ? 0 : null}
                        end={25}
                        duration={3}
                        redraw={true}
                      >
                        {({ countUpRef }) => (
                          <VisibilitySensor
                            onChange={(isVisible) => {
                              if (isVisible) {
                                setFocus(true);
                              }
                            }}
                          >
                            <span style={{ fontFamily: "" }} ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      .
                      <CountUp
                        style={{ fontFamily: "" }}
                        start={focus ? 0 : null}
                        end={8}
                        duration={3}
                        redraw={true}
                      >
                        {({ countUpRef }) => (
                          <VisibilitySensor
                            onChange={(isVisible) => {
                              if (isVisible) {
                                setFocus(true);
                              }
                            }}
                          >
                            <span style={{ fontFamily: "" }} ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      <span style={{ fontFamily: "" }} className="ms-2">
                        Lac
                      </span>
                    </h1>
                  </div>
                  <div className="number-desc">
                    <h2>Sq.Ft area of work done</h2>
                  </div>
                </div>
              </div>
              <div className="col-3 my-3">
                <div className="number-bx">
                  <div className="number-large">
                    <h1>
                      <CountUp
                        start={focus ? 0 : null}
                        end={5000}
                        duration={3}
                        redraw={true}
                      >
                        {({ countUpRef }) => (
                          <VisibilitySensor
                            onChange={(isVisible) => {
                              if (isVisible) {
                                setFocus(true);
                              }
                            }}
                          >
                            <span style={{ fontFamily: "" }} ref={countUpRef} />
                          </VisibilitySensor>
                        )}
                      </CountUp>
                      +
                      <span style={{ fontFamily: "" }} className="ms-2">
                        MT
                      </span>
                    </h1>
                  </div>
                  <div className="number-desc">
                    <h2>Steel Jobs Delivered </h2>
                  </div>
                </div>
              </div>
              <div className="animation start-home"></div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="assistance request-area py-60"
        style={{ backgroundImage: `url(${attachBg})` }}
        id="popup"
      >
        <div className="request-overlay"></div>
        <div className="container-uid z-ind-3">
          <div
            className="about-heading  mx-auto text-center px-lg-4 mb-4"
            data-aos="fade-up"
          >
            <h1>Need Assistance? Ask Us</h1>

            <p>
              Help us help you in just 3 steps, get a free precise quote by our
              team of experienced engineers, assigned just to cater to your
              requirements within 24 hours. It’s time to put an end to the
              multiple calls you make to the consultancies for a quote.{" "}
            </p>
          </div>
          <div className="row mb-4 assistance-steps">
            <div className="col-md-4 mb-4 px-3">
              <div className="steps-bx d-flex justify-content-center">
                <div className="step-no">
                  <span>1</span>
                </div>
                <div className="step-txt">
                  <span>
                    <i className="bi bi-check-circle step-icon" /> Select Your
                    Service
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4 px-3">
              <div
                className="steps-bx d-flex justify-content-center active"
                style={{ background: "transparent" }}
              >
                <div className="step-no">
                  <span style={{ background: "#ea4433", color: "white" }}>
                    2
                  </span>
                </div>
                <div className="step-txt">
                  <span>
                    <i className="bi bi-cloud-upload"></i> Upload Requirement
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-4 px-3">
              <div className="steps-bx d-flex justify-content-center">
                <div className="step-no">
                  <span>3</span>
                </div>
                <div className="step-txt">
                  <span>
                    <i className="bi bi-file-earmark-text"></i> Get Free Quote
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center enquiry-sec" data-aos="fade-in">
            <div className="contact-area">
              <div className="contact-form">
                <form action="">
                  <div className="row">
                    <div className="col-lg-4 mb-4 text-start">
                      <label>Name</label>
                      <input type="text" placeholder="Enter Name" />
                    </div>
                    <div className="col-lg-4 mb-4 text-start">
                      <label>Company Name</label>
                      <input type="text" placeholder="Enter Company Name" />
                    </div>
                    <div className="col-lg-4 mb-4 text-start">
                      <label>Contact Number</label>
                      <input type="text" placeholder="Enter Contact Number" />
                    </div>
                    <div className="col-lg-4 mb-4 text-start">
                      <label>Email</label>
                      <input type="email" placeholder="Enter Email" />
                    </div>
                    <div className="col-lg-4 mb-4 text-start">
                      <label>Type Of Structure</label>
                      <select
                        className="form-select  mb-3"
                        aria-label=".form-select-lg example"
                        onChange={(e) => {
                          setCategoryStructure(e?.target?.value);
                        }}
                      >
                        <option selected>Select Category</option>
                        <option value="PEB Shed">PEB Shed</option>
                        <option value="Oil and Gas">Oil and Gas</option>
                        <option value="Real Estate">Real Estate</option>
                        <option value="Structural Audit">
                          Structural Audit
                        </option>
                      </select>
                    </div>
                    <div className="col-lg-4 mb-4 text-start">
                      <label>Dimensions In</label>
                      <select
                        className="form-select mb-3"
                        aria-label=".form-select-lg example"
                      >
                        <option selected>Open this select menu</option>
                        <option value="1">Meter</option>
                        <option value="2">Feet</option>
                      </select>
                    </div>
                  </div>
                </form>
                <button className="nav-btn-org w-33" onClick={handleShow}>
                  GET A FREE QUOTE
                </button>
              </div>
            </div>
            <Modal show={showFormModal} onHide={handleClose} animation={false}>
              <Modal.Header>
                <Modal.Title className="pl-3" style={{ fontSize: "20px" }}>
                  Select the Type of Structure
                </Modal.Title>
                <Button className="nav-btn" onClick={handleClose}>
                  <i className="fa-solid fa-xmark"></i>
                </Button>
              </Modal.Header>
              <Modal.Body>
                {showRealEstate && (
                  <EnquiryRealEstate categoryStructure={categoryStructure} />
                )}
                {showStructuralAudit && (
                  <EnquiryStructuralAudit
                    categoryStructure={categoryStructure}
                  />
                )}
                {showOilGasModal && (
                  <EnquiryOilGas categoryStructure={categoryStructure} />
                )}
                {showPEBShedModal && (
                  <EnquiryForm categoryStructure={categoryStructure} />
                )}
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>

      <div className="services-section bg-dark-new pb-5">
        <div className="w-100  pt-5" >
          <div
            className="contact-heading  color-cont-head text-center mb-30 max-600 mx-auto"
            data-aos="fade-up"
          >
            <h1>
              {" "}
              <span style={{ color: "#ea4433" }}>i</span>Bexplorer
            </h1>
            <p>
              A game-changing revolution for civil & structural engineers. Data
              augmented for your ease, by leading engineers recognised globally.
            </p>
          </div>
          <div
            className="product-search mb-4 mx-auto max-600 mb--90"
            data-aos="fade-in"
          >
            <div className="product-search-bx">
              <form>
                <div className="d-flex mb-3 select-for">
                  <select
                    className="form-select form-sel all-cat"
                    aria-label="Default select example"
                    onChange={(e) => {
                      structureMineChangeUrl(e.target.value);
                    }}
                  >
                    <option selected>All Categories </option>
                    <optgroup label="Services">
                      <option value="/oil-and-gas">Oil &amp; gas</option>
                      <option value="/peb-structures">PEB Structures</option>
                      <option value="/real-estate">Real Estate</option>
                      <option value="/bim-services">BIM Services</option>
                      <option value="/sustainable-projects">
                        Sustainable Projects
                      </option>
                      <option value="/structural-audit">
                        Structural Audit
                      </option>
                    </optgroup>
                    <option value="blogs">Blogs</option>
                    <optgroup label="Products">
                      <option value="Product1">IB Library</option>
                      <option value="Product2">IB Ready Project</option>
                      <option value="Product3">IB Calculators</option>
                      <option value="Product3">IB Plugins</option>
                      <option value="Product3">IB Store</option>
                    </optgroup>
                    <option value="careers">Careers</option>
                  </select>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    aria-label="Username"
                  />
                  <button className="search-btn">
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>

          <Slider />
        </div>
      </div>
      <div className="w-100" id="service">
        <div
          className="contact-heading max-600 mx-auto text-center px-4 mb-4"
          data-aos="fade-up"
        >
          <h1>Our business is your solution</h1>

          <p className="home-p">
            Interdisciplinary & cooperative, we work with clients, architects,
            specialist engineers, construction companies, industries, and
            universities all across the globe. Years of merit and the courage to
            break new ground is our catalyst.{" "}
          </p>
        </div>
        <div className=" w-100">
          <div className="row home-serv w-100">
            <div className="col-md-6 px-0">
              <div className="service-bx h-100 ">
                <div
                  className="service-bx-inner "
                  style={{ backgroundImage: `url(${OilGasBg})` }}
                >
                  <div className="service-content">
                    <div className="service-content-inner">
                      <h1>
                        <Link to="/oil-and-gas">Oil & Gas </Link>
                      </h1>
                      <p>
                        <ReactReadMoreReadLess
                          charLimit={160}
                          readMoreText={"Read more"}
                          readLessText={"Read less"}
                          readMoreClassName="read-more-less--more"
                          readLessClassName="read-more-less--less"
                        >
                          We are a leading oil & gas design consultancy service
                          provider, having covered momentous projects for big
                          giants in India. Our expertise lies in Strategy,
                          Operations, Design, Innovation, and Technology
                          Transfer & Implementation helps our clients to solve
                          complex and chronic challenges and unlock their
                          inherent potential.
                        </ReactReadMoreReadLess>
                      </p>
                    </div>
                  </div>
                  <div className="service-gif">
                    {/* <img src="" alt="" /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 px-0">
              <div className="service-bx h-100 ">
                <div
                  className="service-bx-inner "
                  style={{ backgroundImage: `url(${PebBg})` }}
                >
                  <div className="service-content">
                    <div className="service-content-inner">
                      <h1>
                        <Link to="peb-structures">Pre-Engineered Buildings </Link>
                      </h1>
                      <p>
                        <ReactReadMoreReadLess
                          charLimit={160}
                          readMoreText={"Read more"}
                          readLessText={"Read less"}
                          readMoreClassName="read-more-less--more"
                          readLessClassName="read-more-less--less"
                        >
                          From concept to completion – preliminary studies,
                          detailed design analysis, construction, erection &
                          fabrication engineering, intelBUILD is committed to
                          serve all areas in structural engineering, while
                          committing to deliver zero waste generating projects.
                          A leading pioneer in PEB, our design principles
                          revolve around: lightness, resource efficiency, &
                          design quality.
                        </ReactReadMoreReadLess>
                      </p>
                    </div>
                  </div>
                  <div className="service-gif">
                    <img src="" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 px-0">
              <div className="service-bx h-100 ">
                <div
                  className="service-bx-inner "
                  style={{ backgroundImage: `url(${RealEstateBg})` }}
                >
                  <div className="service-content">
                    <div className="service-content-inner">
                      <h1>
                        <Link to="/real-estate">Real Estate </Link>
                      </h1>
                      <p>
                        <ReactReadMoreReadLess
                          charLimit={160}
                          readMoreText={"Read more"}
                          readLessText={"Read less"}
                          readMoreClassName="read-more-less--more"
                          readLessClassName="read-more-less--less"
                        >
                          Filigree, modish, & resource-saving lightweight
                          structures for better efficiency, static & geometrical
                          optimization that impress with their lightness,
                          aesthetic & transparency. A team of passionate
                          engineers & architects working to benefit you with
                          effective design solutions, with minimal footprint.
                        </ReactReadMoreReadLess>
                      </p>
                    </div>
                  </div>
                  <div className="service-gif">
                    <img src="" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 px-0">
              <div className="service-bx h-100 ">
                <div
                  className="service-bx-inner "
                  style={{ backgroundImage: `url(${BimBg})` }}
                >
                  <div className="service-content">
                    <div className="service-content-inner">
                      <h1>
                        <Link to="/bim-services">BIM Services </Link>
                      </h1>
                      <p>
                        <ReactReadMoreReadLess
                          charLimit={160}
                          readMoreText={"Read more"}
                          readLessText={"Read less"}
                          readMoreClassName="read-more-less--more"
                          readLessClassName="read-more-less--less"
                        >
                          Digital tools for coordination, quantity estimation,
                          data & execution management in addition with Building
                          Information Modelling (BIM), to implement technically
                          feasible & exquisite design solutions.
                        </ReactReadMoreReadLess>
                      </p>
                    </div>
                  </div>
                  <div className="service-gif">
                    <img src="" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 px-0">
              <div className="service-bx h-100 ">
                <div
                  className="service-bx-inner "
                  style={{ backgroundImage: `url(${SustainBg})` }}
                >
                  <div className="service-content">
                    <div className="service-content-inner">
                      <h1>
                        <Link to="/sustainable-projects">
                          Sustainable Design & Engineering{" "}
                        </Link>
                      </h1>
                      <p>
                        <ReactReadMoreReadLess
                          charLimit={160}
                          readMoreText={"Read more"}
                          readLessText={"Read less"}
                          readMoreClassName="read-more-less--more"
                          readLessClassName="read-more-less--less"
                        >
                          Regardless of the type, size & location of the project
                          our environmental assessors & building physicists
                          understand their responsibility towards impacting
                          communities, & integrating sustainable design
                          solutions into engineering processes. Our
                          future-oriented advice & response to pioneering trends
                          through innovative design implementation will assist
                          you in achieving your sustainability goals & energy
                          efficient buildings.
                        </ReactReadMoreReadLess>
                      </p>
                    </div>
                  </div>
                  <div className="service-gif">
                    <img src="" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 px-0">
              <div className="service-bx h-100 ">
                <div
                  className="service-bx-inner "
                  style={{ backgroundImage: `url(${StructureBg})` }}
                >
                  <div className="service-content">
                    <div className="service-content-inner">
                      <h1>
                        <Link to="/structural-audit">Structural Auditing </Link>
                      </h1>
                      <p>
                        <ReactReadMoreReadLess
                          charLimit={160}
                          readMoreText={"Read more"}
                          readLessText={"Read less"}
                          readMoreClassName="read-more-less--more"
                          readLessClassName="read-more-less--less"
                        >
                          Our licensed structural engineering experts,
                          well-informed & equipped with the latest tools &
                          technologies adopt the Non-Destructive Testing
                          techniques to asses and analyse your structure.
                        </ReactReadMoreReadLess>
                      </p>
                    </div>
                  </div>
                  <div className="service-gif">
                    <img src="" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-5 px-4 w-100">
        <div className="contact-heading text-center mb-4" data-aos="fade-up">
          <h1>Our Clients</h1>
        </div>
        <div className="contact-heading text-center max-600 mx-auto mb-4">
          <p>
            Our client list speaks for itself. A true partnership is a two-way
            street, synergy of ideas and information alongside trust helped us
            achieve great success & lasting relationships with our clients.{" "}
          </p>
        </div>
        <Clients />
      </div>
      <MotionAnimate
        animation="scrollOpacity"
        ease={[0.17, 0.67, 0.97, -0.148]}
        scrollPositions={[0.2, 0.5, 0.6, 1]}
      >
        <div className="testimonials pt-5 bg-sky">
          <div className="contact-heading text-center mb-4">
            <h1>Endorsements</h1>
          </div>

          <Testimonials />
        </div>
      </MotionAnimate>

      <div className="w-100 pb-5 pt-5 px-4">
        <div className="contact-heading text-center">
          <h1>Latest Insights</h1>
        </div>
        <Blogs />
      </div>
      <Footer />
    </>
  );
}

export default Home;
