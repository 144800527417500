import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import video1 from "./../Images/banner.mp4";
import SecondaryMenu from "./SecondaryMenu";
import Img1 from "./../Images/alain-pham-P_qvsF7Yodw-unsplash.jpg";
import ParallaxSmallBanner from "../SubComponents/ParallaxSmallBanner";
import FlipBook from "./bookflip";
import { HashLink } from "react-router-hash-link";

function GetToKnow(props) {
  return (
    <>
      <Header />

      <div className="scrll">
        <ParallaxSmallBanner img={Img1} title="Get To Know Us" />
      </div>
      <div className="pos-rel">
        <div class="w-100 py-5  pad-60">
          <div className="pt-2 pb-5 text-center">
            <SecondaryMenu linkName={ props.linkName }/>
          </div>
          <div class="row about-container">
            {/* <FlipBook /> */}

            <div class="col-lg-12">
              <div className="contact-heading about-page">
                <div className="video-player mb-4">
                  <h2 class="discover-us">DISCOVER US</h2>
                  <Video autoPlay loop muted controls>
                    <source src={video1} type="video/mp4" />
                  </Video>
                </div>
                <p>
                  IntelBUILD Engineering Services LLP is a licensed engineering
                  service-based company, who is focused on implementing modern
                  technology, blended with advanced solutions to solve complex
                  problems. Our team of highly advanced professional specialists
                  are rooted in technical excellence, driven by creativity and a
                  hankering for innovation, with a solitary goal: to deliver
                  cost-effective engineering solutions that inspire communities
                  and clients.{" "}
                </p>

                <p>
                  Having completed more than 21.6 lakh sq.ft of projects in the
                  last 5 years, we continue to integrate honesty in our business
                  functioning while maintaining the esthetical value of our
                  projects.
                </p>
                <p>
                  The founders of the company have calibrated a research-based
                  working environment to leverage the use of ultra-modern tools
                  while empowering the structural and civil engineering fields
                  by adopting latest 3D technologies, and providing advanced
                  detailed solutions for all types of residential, commercial,
                  and industrial projects, as per national and international
                  standards such as AISC, ASCE & European standards.{" "}
                </p>
                <p>
                  Our services are not just limited to providing excellent, and
                  cost-effective solutions to the private and government sector,
                  but also encouraging the principles of skilful designing, with
                  exceptional quality and adequacy through the adoption of
                  multiple latest software like STAAD Pro, ETABS, Tekla, Revit,
                  idea Statica, and RAM connection.
                </p>

                <div className="text-center">
                  <HashLink smooth to="/#popup1" className="nav-btn me-4">
                    Get A Quote
                  </HashLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default GetToKnow;
