import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import OilAndGasBanner from '../SubComponents/Banner'
import { Link } from 'react-router-dom'
import abt1 from './../Images/abt2.jpg';
import OilGas1 from './../Images/oil-gas1.png';
import OilGas2 from './../Images/oil-gas2.png';
import OilGas3 from './../Images/oil-gas3.png';
import Bld1 from './../Images/bld1.png';
import Bld2 from './../Images/bld2.png';
import Bld3 from './../Images/bld3.png';
import Bld4 from './../Images/bld4.png';
import Magazine from './../Images/Magzine.jpg';
import Certificate2 from './../Images/certificate2.jpeg';


// tabs

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Banner from '../SubComponents/Banner'

import contact from './../Images/popup-bg1.jpeg';
import ProductsFilter from '../SubComponents/ProductsFilter'
import SectionWipes from '../SubComponents/ParallaxHero'
import ParallaxSmallBanner from '../SubComponents/ParallaxSmallBanner'


function Signup() {
    return (
        <>
            <Header />

          
            <ParallaxSmallBanner img={contact} title='Sign Up' />


            <div className='pos-rel '>
                <div class="w-100 form-section py-100 contact-page">
{/* ///////////// */}
{/* <!-- 
Thanks to:
https://medium.com/@AmJustSam/how-to-do-css-only-frosted-glass-effect-e2666bafab91 
--> */}


                    <div class="px-4">
                        <div className="contact-area">
                            <div class="contact-form contact-us">

                                <div class="d-flex justify-content-center  max-400 mx-auto">
                                    <div class="shadow p-4  w-100 signup-form"><div class='blure-sign'></div>
                                        <div className="contact-heading abt-txt text-center mb-4">
                                            <h2>Create Your account</h2>

                                        </div>
                                        <form action="">


                                            <div className=" mb-4">




                                                <label>Name</label>
                                                <input type="text" placeholder="Enter Name" />

                                            </div>
                                            <div className=" mb-4">

                                                <label>Email</label>
                                                <input type="email" placeholder="Enter Email Address" />

                                            </div>
                                            <div className=" mb-4">


                                                <label>Password</label>
                                                <input type="password" placeholder="Enter Password" />


                                            </div>
                                            <div className=" mb-4">


                                                <label>Confirm Password</label>
                                                <input type="password" placeholder="Enter Password" />


                                            </div>


                                            {/* <div className="send-button">
                                           <button>Send</button>
                                         </div> */}




                                            <div className="">
                                                <Link className="nav-btn-org-2 w-100" to="/">Create Account</Link>

                                            </div>
                                        </form>
                                        <div className='text-center mt-3'>
                                            <p>Don't have an account? <Link className='login-org' to="/login">Login Now</Link> </p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div >



                <Footer />

            </div>
        </>
    )
}

export default Signup;