 import structureImage from "../../Images/peb/intel slider images/1.jpg";
import structureImage2 from "../../Images/peb/intel slider images/2.jpg";
import structureImage3 from "../../Images/peb/intel slider images/3.jpg";
import structureImage4 from "../../Images/peb/intel slider images/4.jpg";
import structureImage5 from "../../Images/peb/intel slider images/5.jpg";
import structureImage6 from "../../Images/peb/intel slider images/6.jpg";
import structureImage7 from "../../Images/peb/intel slider images/7.jpg";
import structureImage8 from "../../Images/peb/intel slider images/8.jpg";
import structureImage9 from "../../Images/peb/intel slider images/9.jpg";
import structureImage10 from "../../Images/peb/intel slider images/10.jpg";
import structureImage11 from "../../Images/peb/intel slider images/11.jpg";

export const structuredData = [
  // {
  //   title: "Governance",
  //   content:
  //     " We deliver oversight support, meticulously studying reports, and implementing necessary actions to ensure project compliance.",
  //   image: structureImage,
  // },
  // {
  //   title: "Controls & Contract Review:",
  //   content:
  //     "We carefully scrutinize contract details, enabling reliable monitoring and adherence to contractual obligations.",
  //   image: structureImage2,
  // },
  // {
  //   title: "Risk Development",
  //   content:
  //     "Through a detailed financial analysis, we assess potential risks, avoiding disputes, and ensuring adherence to technical project requirements.",
  //   image: structureImage3,
  // },
  // {
  //   title: "Mitigation Plan",
  //   content:
  //     "We develop robust plans to analyse the full impact of environmental conditions, cost expenditures, and potential material failures.",
  //   image: structureImage4,
  // },
  // {
  //   title: "Consolidate Support",
  //   content:
  //     " Our comprehensive assessment, consolidation, and reporting process incorporates clients' decisions, technical processes, and cutting-edge technology.",
  //   image: structureImage5,
  // },
  // {
  //   title: "Policies and Procedures",
  //   content:
  //     "We shape policies and procedures by structuring activities and aligning objectives with framework requirements, ensuring efficient project management.",
  //   image: structureImage6,
  // },
 
  


  {
    "title": "Scope and Objectives Definition",
    "content": "We begin by defining the precise boundaries of the audit. Our scope encompasses the entire service delivery process, and our objectives include enhancing service quality, ensuring regulatory compliance, and optimizing operational efficiency. This step sets the foundation for a focused and effective audit.",
    image: structureImage,

  },
  {
    title: "Data Collection and Assessment",
    content: "Thorough data collection is central to our audit approach. We compile all relevant documents, from service manuals to process flowcharts, and gather performance metrics and customer feedback. Our experts then meticulously assess these materials, identifying strengths and pinpointing areas where improvements are warranted. This comprehensive assessment enables us to make informed decisions during the audit.",
    image: structureImage2,
  },
  {
    title: "Compliance and Regulation Check",
    content: "The audit wouldn't be complete without a rigorous examination of compliance and regulations. We scrutinize our service delivery against local, national, and international standards to ensure that every aspect is aligned with legal mandates and industry best practices. This thorough compliance check guarantees that our services are not only efficient but also ethically and legally sound.",
    image: structureImage3,
  },
  {
    title: "Resource Utilization Evaluation",
    content: "An integral part of our audit involves evaluating resource allocation. We closely examine how resources, including human capital, finances, and technology, are allocated across our service delivery process. This evaluation enables us to ensure optimal resource utilization, enhancing service effectiveness while minimizing wastage.",
    image: structureImage4,
  },
  {
    title: "Performance Metrics Analysis",
    content: "To measure the effectiveness of our services, we perform an in-depth analysis of performance metrics. These metrics provide a quantitative understanding of how well our services are meeting targets and benchmarks. By comparing actual performance against predefined goals, we can identify trends, patterns, and areas that require attention. This analysis helps us fine-tune our strategies for continual improvement.",
    image: structureImage5,
  },
  {
    title: "Customer Feedback Insights",
    content: "Our commitment to client satisfaction drives us to carefully consider customer feedback. We gather insights from reviews, feedback forms, and direct interactions to gain a clear understanding of our clients' experiences. This feedback loop empowers us to make client-centric improvements, address concerns promptly, and ensure our services consistently align with their needs and expectations.",
    image: structureImage6,
  },
  {
    title: "Recommendations and Implementation",
    content: "Based on the comprehensive findings of our audit, we develop a set of actionable recommendations. These recommendations are grounded in data and insights and are designed to address identified weaknesses, leverage opportunities, and elevate overall service quality. Our dedicated team ensures the seamless implementation of these recommendations, fostering a culture of continuous improvement within our service delivery process.",
    image: structureImage7,
  },

];
