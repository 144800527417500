import React, { useState } from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import OilAndGasBanner from '../SubComponents/Banner'
import { Link } from 'react-router-dom'
import abt1 from './../Images/abt2.jpg';
import OilGas1 from './../Images/oil-gas1.png';
import OilGas2 from './../Images/oil-gas2.png';
import OilGas3 from './../Images/oil-gas3.png';
import Bld1 from './../Images/bld1.jpg';
import Bld2 from './../Images/bld2.jpg';
import Bld3 from './../Images/bld3.jpg';
import Bld4 from './../Images/bld4.jpg';

import ServicePage1 from '../SubComponents/service-page'
import OilGasBg from './../Images/header/oil&gas.jpg';
import Oilandgas2 from './../Images/Oil &Gas/1.jpg';

import Oil1 from './../Images/website-images/Oil-and-Gas-pics/ammonia-plant.jpg';
import Oil2 from './../Images/website-images/Oil-and-Gas-pics/IOCL-Assam.jpg';
import Oil3 from './../Images/website-images/Oil-and-Gas-pics/IOCL-Assam-01.jpg';
import Oil4 from './../Images/website-images/Oil-and-Gas-pics/IOCL-Assam.jpg';
import Oil5 from './../Images/website-images/Oil-and-Gas-pics/IOCL-project-pic.jpg';
import Certificate1 from './../Images/awards-and-achievements/intelBuild-Engineering-Services-LLP-1.png';

import Magazine from './../Images/Magzine.jpg';
import Certificate2 from './../Images/certificate2.jpeg';
import ProductsData from './../Data/ProductsData'



import Carousel from 'react-bootstrap/Carousel';

import ProjectPopup from '../SubComponents/ProjectPopup';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"


// tabs

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Banner from '../SubComponents/Banner'

import contact from './../Images/oil-gas1.png';
import ProductsFilter from '../SubComponents/ProductsFilter'
import OilAndGasProjects from '../SubComponents/OilAndGasProjects'
import SectionWipes from '../SubComponents/ParallaxHero'
import OilAndGasData from '../Data/OilAndGasData'
import pdf from '../Images/IB-Brochure2023.pdf'
import PubServiceContent from "../Images/peb-service.jpg";

import { Col, Form, Row } from "react-bootstrap";
import rightArrow from "../Images/icons/right-arrow.svg";
import leftArrow from "../Images/icons/left-arrow.svg";
import CustomCarousal from "./oil&gas/CustomCarousel";
import { structuredData } from './oil&gas/data'
function OilAndGas() {
    const [buttonPopup, setButtonPopup] = useState(false);
    const [tempdata, setTempdata] = useState([]);
    const [stucturedSlide, setStucturedSlide] = useState(0);
    const [active, setActive] = useState("right");

    const handleStucturedSlide = (index) => {
        setStucturedSlide(index);
    };
    const handleSlideByArrow = (value) => {
        setActive(value);
        if (value === "left" && stucturedSlide > 0) {
            setStucturedSlide(stucturedSlide - 1);
        } else {
            console.log(
                "enter",
                !structuredData?.length > stucturedSlide,
                structuredData?.length,
                stucturedSlide
            );
            if (structuredData?.length - 1 > stucturedSlide) {
                setStucturedSlide(stucturedSlide + 1);
            }
        }
    };
    // brochure
    const handleDownloadPDF = () => {
        const pdfUrl = require('../Images/IB-Brochure2023.pdf'); // Replace with the path to your PDF file
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank';
        link.download = 'intelBuild Brochure.pdf'; // Replace with the desired filename for the downloaded file
        link.click();
    };

    // brochure

    const getData = (image, service, category) => {
        let tempData = [image, service, category];
        setTempdata(item => [image]);

        return setButtonPopup(true);
    }
    return (
        <>
            <Header />

            <SectionWipes img={OilGasBg} title='Oil &amp; Gas' />
            <div className='pos-rel'>
                <div className="container-section">
                    <div className="overview-title">
                        <h3>OVERVIEW</h3>
                        <h1>
                            On <span> Oil & Gas</span>
                        </h1>
                    </div>
                   

                    <p className="content">
                        Introducing our exceptional Pre-Engineered Buildings Design & Build
                        service, where innovation meets efficiency. Our team of skilled
                        architects, engineers, and construction professionals specialize in
                        delivering customized solutions that exceed client expectations. From
                        conceptualization to execution, we prioritize precision and
                        quality,ensuring every detail of the pre-engineered building is
                        meticulously designed and flawlessly constructed. With our expertise
                        in utilizing state-of-the-art technology and industry best practices,
                        we create sustainable and cost-effective structures that are tailored
                        to meet your specific requirements. Experience the seamless
                        integration of design and construction with our Pre-Engineered
                        Buildings Design & Build service, where we turn your vision into
                        reality, ensuring durability, functionality, and aesthetic appeal in
                        every project we undertake.
                    </p>
                </div>
                <div className="custom-flex serv-btn-cont">
                    <Link className="nav-btn me-4 custom-btn quote" to="/">
                        Get A Quote
                    </Link>
                    <Link className="nav-btn btn-w-1 custom-btn download" to="/">
                        Download Brochure
                    </Link>
                </div>

                <div className="img-cont" style={{padding:"50px 200px"}}>
                <img
                        src={Oilandgas2}
                        className="w-100"
                        alt="PubServiceContent"
                    />
                </div>

                <div className="work-section" >
                    <div className="work-title">
                        <h3>HOW IT WORKS</h3>
                        <h1>
                            At IntelBuild we have <span> structured </span>the service process
                            which ensure clients....
                        </h1>
                    </div>
                    <Row className="mt-4">
                        <Col md={4} lg={4}>
                            <div className="check-list-main">
                                <div className="check-list">
                                    {structuredData?.map((item, index) => (
                                        <div
                                            key={index}
                                            className="d-flex align-items-center check-item"
                                            onClick={() => handleStucturedSlide(index)}
                                        >
                                            <Form.Check
                                                type="radio"
                                                aria-label="radio 1"
                                                name="check"
                                                checked={index === stucturedSlide}
                                            />
                                            <h3>{item?.title}</h3>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Col>
                        <Col md={8} lg={8}>
                            <div className="check-list-content">
                                {structuredData?.map(
                                    (item, index) =>
                                        index === stucturedSlide && (
                                            <div key={index}>
                                                <h3 className="mobile">{item?.title}</h3>
                                                <p className="paragraph-content">{item?.content}</p>
                                                <div className="image-container">
                                                    <img className="images"
                                                        src={item?.image}
                                                        alt={item?.image}
                                                    />
                                                </div>
                                            </div>
                                        )
                                )}
                                <div className="arrow-box">
                                    <button
                                        type="button"
                                        className={`arrow-button ${active === "left" && "arrow-active"
                                            }`}
                                        onClick={() => handleSlideByArrow("left")}
                                    >
                                        {active !== "left" ? (
                                            <img src={leftArrow} alt="leftArrow" />
                                        ) : (
                                            <img
                                                src={rightArrow}
                                                alt="rightArrow"
                                                className="direction"
                                            />
                                        )}
                                    </button>
                                    <button
                                        type="button"
                                        className={`arrow-button ${active === "right" && "arrow-active"
                                            }`}
                                        onClick={() => handleSlideByArrow("right")}
                                    >
                                        {active !== "left" ? (
                                            <img src={rightArrow} alt="rightArrow" />
                                        ) : (
                                            <img
                                                src={leftArrow}
                                                alt="leftArrow"
                                                className="direction"
                                            />
                                        )}
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>


                <CustomCarousal />

                <div className="w-100 gr-bg">



                </div >




                {/* <Footer /> */}
            </div>
        </>
    )
}

export default OilAndGas