import React, {useState} from "react";
import { Nav } from "react-bootstrap";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import "react-html5video/dist/styles.css";
import Tab from "react-bootstrap/Tab";
import SecondaryMenu from "./SecondaryMenu";
import ParallaxSmallBanner from "../SubComponents/ParallaxSmallBanner";
import Img1 from "./../Images/alain-pham-P_qvsF7Yodw-unsplash.jpg";
import Magazine from "./../Images/Magzine.jpg";
import Certificate from "./../Images/certificate 1.jpg";
import Certificate1 from "./../Images/certificates/1.jpg";
import Certificate2 from "./../Images/certificates/2.jpg";
import Certificate3 from "./../Images/certificates/3.jpg";
import Certificate4 from "./../Images/certificates/4.jpg";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import CertificationsModel from "./CertificationsModel";
function Certifications(props) {
  const [show, setShow] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };
  return (
    <>
      <Header />
      <CertificationsModel show={show} handleClose={handleClose} activeIndex={activeIndex} handleSelect={handleSelect}/>
      <div className="scrll">
        <ParallaxSmallBanner img={Img1} title="Certifications" />
      </div>
      <div className="pos-rel">
        <div className="py-5 pad-60">
          <div className=" pt-2 pb-5 text-center">
            <SecondaryMenu linkName={ props.linkName } />
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="w-100 shadow">
                <div className="w-100">
                  {/* <div className="contact-heading pt-5 text-center">
                    <h1>Certifications</h1>
                  </div> */}
                  <div className="row">
            <div className="col-lg-12">
              <div className="masonry">
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                >
                  <Masonry>
                    <div className="masonry-img">
                      <div className="shadow">
                        <img src={Certificate} className="w-100" alt="" />
                        <div className="masonry-content p-3">
                          <button className="btn" onClick={()=>{
                            handleShow()
                            setActiveIndex(0)
                            }}>
                            <h2>AMIE Certificate</h2>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="masonry-img">
                      <div className="shadow">
                        <img src={Certificate2} className="w-100" alt="" />
                        <div className="masonry-content p-3">
                          <button className="btn" onClick={()=>{
                            handleShow()
                            setActiveIndex(1)
                            }}>
                            <h2>Charted Engineer, India</h2>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="masonry-img">
                      <div className="shadow">
                        <img src={Certificate3} className="w-100" alt="" />
                        <div className="masonry-content p-3">
                          <button className="btn" onClick={()=>{
                            handleShow()
                            setActiveIndex(2)
                            }}>
                            <h2>ISO 9001:2015 Registration Certificate</h2>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="masonry-img">
                      <div className="shadow">
                        <img src={Certificate4} className="w-100" alt="" />
                        <div className="masonry-content p-3">
                          <button className="btn" onClick={()=>{
                            handleShow()
                            setActiveIndex(3)
                            }}>
                            <h2>IGBC Membership Certifcate</h2>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="masonry-img">
                      <div className="shadow" onClick={()=>{
                            handleShow()
                            setActiveIndex(4)
                            }}>
                        <img src={Magazine} className="w-100" alt="" />
                        <div className="masonry-content p-3">
                          <button className="btn">
                            <h2>BMC Licensed Serveyor</h2>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Masonry>
                </ResponsiveMasonry>
              </div>
            </div>
          </div>
                  {/* <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first"
                  >
                    <div className="row certificates w-100 mx-0">
                      <div className="col-lg-3 my-auto px-0">
                        <Nav variant="pills" className="flex-column">
                          <Nav.Item className="gr-bg pills-li">
                            <Nav.Link eventKey="third">
                              ISO 9001:2015 Registration Certificate
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="gr-bg pills-li">
                            <Nav.Link eventKey="fourth">
                              IGBC Membership Certificate
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="gr-bg pills-li">
                            <Nav.Link eventKey="second">
                              Charted Engineer, India
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="gr-bg pills-li">
                            <Nav.Link eventKey="first">
                              AMIE Certificate
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item className="gr-bg pills-li">
                            <Nav.Link eventKey="fifth">
                              BMC Licensed Serveyor
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </div>
                      <div className="col-lg-9 px-0 mb-lg-0 mb-4">
                        <div className="h-100 p-5 rel-pos">
                          <Tab.Content>
                            <Tab.Pane eventKey="first">
                              <div className="certificate-img">
                                <img src={Certificate1} className="" alt="" />
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                              <div className="certificate-img">
                                <img src={Certificate2} className="" alt="" />
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                              <div className="certificate-img">
                                <img src={Certificate3} className="" alt="" />
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fourth">
                              <div className="certificate-img">
                                <img src={Certificate4} className="" alt="" />
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fifth">
                              <div className="certificate-img">
                                <img src={Magazine} className="" alt="" />
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="sixth">
                              <div className="certificate-img">
                                <img src={Certificate3} className="" alt="" />
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </div>
                      </div>
                    </div>
                  </Tab.Container> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default Certifications;
