import React,{useState} from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import OilAndGasBanner from '../SubComponents/Banner'
import { Link } from 'react-router-dom'
import abt1 from './../Images/abt2.jpg';
import OilGas1 from './../Images/oil-gas1.png';
import OilGas2 from './../Images/oil-gas2.png';
import OilGas3 from './../Images/oil-gas3.png';
import Bld1 from './../Images/bld1.jpg';
import Bld2 from './../Images/bld2.jpg';
import Bld3 from './../Images/bld3.jpg';
import Bld4 from './../Images/bld4.jpg';
import Magazine from './../Images/Magzine.jpg';
import Certificate2 from './../Images/certificate2.jpeg';
import '../Pages/sustainable-1.css'
import SusMap from './../Images/website-images/map.png';
import SusIcon from './../Images/website-images/line-segment.png';
import Sustainable from './sustainable'
// tabs

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Banner from '../SubComponents/Banner'

import contact from './../Images/header/sustainale.jpg';
import ProductsFilter from '../SubComponents/ProductsFilter'
import SectionWipes from '../SubComponents/ParallaxHero'

import Certificate1 from './../Images/awards-and-achievements/intelBuild-Engineering-Services-LLP-1.png';
import ProjectPopup from '../SubComponents/ProjectPopup';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import SustainableData from '../Data/SustainableData'


function SustainableServices() {
    const [buttonPopup, setButtonPopup] = useState(false);
    const [tempdata, setTempdata] = useState([]);


    const getData = (image, service, category) => {
        let tempData = [image, service, category];
        setTempdata(item => [image]);

        return setButtonPopup(true);
    }
    return (
        <>
            <Header />
            <SectionWipes img={contact} title='Sustainable Projects'/>
            <div className='pos-rel'>
            <div className="w-100 pt-5 pb-5 ">
                <div className="contact-heading max-600 mx-auto text-center px-4">
                    <h1>Little Bit Overview On Service</h1>

                    <p>We are a uniquely diverse range of consultants delivering extremely high profile projects across the world, working to meet some of the world’s greatest challenges.
                    </p>

                </div>
            </div>
            <div className="w-100 pt-5 gr-bg">
                <div className="px-4">

                    <div className="row">
                        <div className="col-lg-6 mb-lg-0 mb-4">
                            <div className="contact-heading max-500">
                                <h4>Our Services</h4>
                                <h1>Sustainable Projects</h1>

                                <p>Covering momentous projects under our guidance we are sufficient to deliver complete functional solutions in the field of oil and gas plants. Our workforce consists of selective skilled engineers and percipient management personnel having adequate domain knowledge in this industry and major leading softwares.

                                </p>
                                <div className="custom-flex">
                                    <Link className="nav-btn me-4 custom-btn" to="/">Get A Quote</Link>
                                    <Link className="nav-btn btn-w-1 custom-btn" to="/">Download Brochure</Link>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-6">
                            <div className="row">
                                <div className="col-6 mb-5">
                                    <div className="serv-txt">
                                        <div className="service-icon mb-2">
                                            <i className="bi bi-pencil-square"></i>
                                        </div>
                                        <div className="serv-cont">
                                            <h3>Tools for every stack</h3>
                                            <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
                                                consequat.</p>
                                            <Link className='lib-link' to="">See libraries <i className="bi bi-arrow-right"></i></Link>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-6 mb-5">
                                    <div className="serv-txt">
                                        <div className="service-icon mb-2">
                                            <i className="bi bi-pencil-square"></i>
                                        </div>
                                        <div className="serv-cont">
                                            <h3>Tools for every stack</h3>
                                            <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
                                                consequat.</p>
                                            <Link className='lib-link' to="">See libraries <i className="bi bi-arrow-right"></i></Link>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-6 mb-5">
                                    <div className="serv-txt">
                                        <div className="service-icon mb-2">
                                            <i className="bi bi-pencil-square"></i>
                                        </div>
                                        <div className="serv-cont">
                                            <h3>Tools for every stack</h3>
                                            <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
                                                consequat.</p>
                                            <Link className='lib-link' to="">See libraries <i className="bi bi-arrow-right"></i></Link>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-6 mb-5">
                                    <div className="serv-txt">
                                        <div className="service-icon mb-2">
                                            <i className="bi bi-pencil-square"></i>
                                        </div>
                                        <div className="serv-cont">
                                            <h3>Tools for every stack</h3>
                                            <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
                                                consequat.</p>
                                            <Link className='lib-link' to="">See libraries <i className="bi bi-arrow-right"></i></Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >


                    
                    {/* sustainale start*/}

                   

                    {/* sustainale end  */}
        <Sustainable/>

            {/* in services starts */}
            <div className="w-100 pt-5 pb-5 ">
                <div className="contact-heading max-600 mx-auto text-center px-4">
                    <h4>Our Services</h4>
                    
                    <h1>In Services</h1>
                </div>
                <div className="row  w-100 mx-0">
                    <div className="col-lg-4 px-0 mb-lg-0 mb-4">
                        <div className="team-box">
                            <div className="inserv-img">
                                <img src={OilGas1} alt="" />
                            </div>
                            <div className="team-description testimonial-profile-name text-center p-team">
                                <div className="inserv-inner shadow p-3">

                                    <p>Designing and manufacturing of equipment, equipment supporting structures, platforms, skids, and racks for piping system.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 px-0 mb-lg-0 mb-4">
                        <div className="team-box">
                            <div className="inserv-img">
                                <img src={OilGas2} alt="" />
                            </div>
                            <div className="team-description testimonial-profile-name text-center p-team">
                                <div className="inserv-inner shadow p-3">

                                    <p>Designing and manufacturing of equipment, equipment supporting structures, platforms, skids, and racks for piping system.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 px-0 mb-lg-0 mb-4">
                        <div className="team-box">
                            <div className="inserv-img">
                                <img src={OilGas3} alt="" />
                            </div>
                            <div className="team-description testimonial-profile-name text-center p-team">
                                <div className="inserv-inner shadow p-3">

                                    <p>Designing and manufacturing of equipment, equipment supporting structures, platforms, skids, and racks for piping system.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* in servcices ends */}
            
            <Footer />
            </div>
        </>
    )
}

export default SustainableServices