import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Banner from '../SubComponents/Banner'
import OilGas2 from './../Images/oil-gas2.png';
import abt2 from './../Images/abt3.jpg';
import ban from './../Images/wilhelm-gunkel-rVbvN-iUcEA-unsplash.jpg';
import SectionWipes from '../SubComponents/ParallaxHero';
import Frame from './frame';
import Blog3 from '../Images/website-images/3b.jpeg'


function Blog() {
    return (
        <>
            <Header />
          
            <SectionWipes img={ban} title='Blogs' />
            <div className='pos-rel'>
                <div className="w-100 pt-5 pb-4 ">
                    <div className="contact-heading max-600 mx-auto text-center px-4">
                        <h1>Blogs</h1>

                        <p>We are a uniquely diverse range of consultants delivering extremely high profile projects across the world, working to meet some of the world’s greatest challenges.
                        </p>

                    </div>
                </div>
                <div class="row w-100 mx-0">
                    
                    <div class="col-lg-4">
                        <div class="blog-bx h-100">
                            <div class="blog-img">
                                <img src={Blog3} alt="" />
                            </div>
                            <div class="blog-date">
                                <span class="me-3">08-11-2021</span><span>IntelBuild</span>
                            </div>
                            <div class="blog-text">
                                <h1> Hydrogen refueling at Mathura for Reliance Industries Limited</h1>
                                <p>Hydrogen refueling at Mathura for Reliance Industries Limited is all set to launch. Good to see the journey from Design to Finishes..
                                    Max Cantilever upto 8m
                                    Congratulations to team IntelBUILD Engineering Services LLP</p>
                            </div>
                        </div>

                    </div>
                    <div class="col-lg-4">
                        <div class="blog-bx h-100">
                            <div class="blog-img">
                                <img src='https://media.licdn.com/dms/image/C4D22AQE_1vnEajlMeQ/feedshare-shrink_800/0/1670218718192?e=1688601600&v=beta&t=NGAo0vQoL934AKGQaygZqlqa4BoC9lTQBxrKaRUF6cY' alt="" />
                            </div>
                            <div class="blog-date">
                                <span class="me-3">08-11-2021</span><span>IntelBuild</span>
                            </div>
                            <div class="blog-text">
                                <h1>IntelBUILD Engineering Services LLP</h1>
                                <p>IntelBUILD Engineering Services LLP is elated to announce the completion of another project, for a hotel in Mumbai.
                                    Swipe to see the details.</p>
                            </div>
                        </div>

                    </div>


                    <div class="col-lg-4">
                        <div class="blog-bx h-100">
                            <div class="blog-img">
                                <img src='https://media.licdn.com/dms/image/C4D22AQE-TnrbfRuQaw/feedshare-shrink_800/0/1669973559246?e=1688601600&v=beta&t=g_LoXSPyoEdmwh8QF0blKtHg0S2KssgDn6XQU720gOc' alt="" />
                            </div>
                            <div class="blog-date">
                                <span class="me-3">08-11-2021</span><span>IntelBuild</span>
                            </div>
                            <div class="blog-text">
                                <h1>Turning your imagination and dreams into reality.</h1>
                                <p>G+1 Structure at Thane, Maharashtra, in collaboration with Architect Vikas Bhujbal.
                                    ⬇Swipe to see the 3-D STAAD Model⬇
                                </p>
                            </div>
                        </div>

                    </div>
                    <div class="col-lg-4">
                        <div class="blog-bx h-100">
                            <div class="blog-img">
                                <img src='https://media.licdn.com/dms/image/C4D22AQHc-3skHTAnwQ/feedshare-shrink_800/0/1668776984354?e=1688601600&v=beta&t=11_CIPy2bKba56h95GXb1UT03yDLKipKYO0H0I0bWxs' alt="" />
                            </div>
                            <div class="blog-date">
                                <span class="me-3">08-11-2021</span><span>IntelBuild</span>
                            </div>
                            <div class="blog-text">
                                <h1> Another day, and another exciting project at IntelBUILD Engineering Services LLP.</h1>
                                <p>PEB Canteen Structure for Borosil Renewables Ltd. in Bharuch, Gujarat with our Associate Structenprefab Pvt. Ltd.
                                    Having Mr. Ashwin Modi as the architect, we have successfully completed the Analysis, Design & detail drawings of this project by using multiple software like Tekla, STAAD Pro, AutoCAD, and SketchUp.

                                    The aesthetic of this structure was to resemble heritage buildings while providing the most sustainable designs, which our team has succeeded at.</p>
                            </div>
                        </div>

                    </div>
                    <div class="col-lg-4">
                        <div class="blog-bx h-100">
                            <div class="blog-img">
                                <img src='https://media.licdn.com/dms/image/C4D22AQHqX8FzcXp14g/feedshare-shrink_800/0/1660505818515?e=1688601600&v=beta&t=2SX7ldtxX2wuWCqkt5Kj93_c8SQX2d3tf2reLXlPkUs' alt="" />
                            </div>
                            <div class="blog-date">
                                <span class="me-3">08-11-2021</span><span>IntelBuild</span>
                            </div>
                            <div class="blog-text">
                                <h1>Believing neglected so so allowance existence departure.</h1>
                                <p>Rate us for the Design & Let us know your review which option you like most!!</p>
                            </div>
                        </div>

                    </div>
                    <div class="col-lg-4">
                        <div class="blog-bx h-100">
                            <div class="blog-img">
                                <img src='https://media.licdn.com/dms/image/C4D22AQFSNXXuzpMPUA/feedshare-shrink_800/0/1661096102464?e=1688601600&v=beta&t=65mqE3peuAfp1SG6_OVbFXwG5bkTWaEqYiIRlvcLAtE' alt="" />
                            </div>
                            <div class="blog-date">
                                <span class="me-3">08-11-2021</span><span>IntelBuild</span>
                            </div>
                            <div class="blog-text">
                                <h1> Steel Staircase in a duplex flat @ Piramal Viakunt Thane, MH</h1>
                                <p>Steel Staircase in a duplex flat @ Piramal Viakunt Thane, MH
                                    perfect detailing and zero error in fabrication/erection.</p>
                            </div>
                        </div>

                    </div>
                   
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Blog