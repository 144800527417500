 import structureImage from "../../Images/Oil &Gas/1.jpg";
import structureImage2 from "../../Images/Oil &Gas/3 (1).png";
import structureImage3 from "../../Images/Oil &Gas/4.jpg";
import structureImage4 from "../../Images/Oil &Gas/5.jpg";
import structureImage5 from "../../Images/peb/intel slider images/5.jpg";
import structureImage6 from "../../Images/peb/intel slider images/6.jpg";
import structureImage7 from "../../Images/peb/intel slider images/7.jpg";
import structureImage8 from "../../Images/peb/intel slider images/8.jpg";
import structureImage9 from "../../Images/peb/intel slider images/9.jpg";
import structureImage10 from "../../Images/peb/intel slider images/10.jpg";
import structureImage11 from "../../Images/peb/intel slider images/11.jpg";

export const structuredData = [
  {
    title: "Analysis",
    content:
     "In this phase, our expert team conducts a comprehensive analysis of your oil and gas project's requirements, constraints, and objectives. We carefully assess geological and reservoir data, environmental considerations, regulatory compliance, and economic factors to ensure a robust foundation for the project.",    
     image: structureImage,
  },
  {
    title: "DESIGN",
    content:
    "Our skilled engineers and designers collaborate to create a tailored design that optimizes the extraction, processing, and transportation of oil and gas resources. We leverage cutting-edge technology and industry best practices to develop efficient and safe solutions that align with your project's goals.",    
    image: structureImage2,
  },
  {
    title: "DETAILING",
    content:
     "Our skilled engineers and designers collaborate to create a tailored design that optimizes the extraction, processing, and transportation of oil and gas resources. We leverage cutting-edge technology and industry best practices to develop efficient and safe solutions that align with your project's goals.",    
     image: structureImage3,
  },
  {
    title: "MANUFACTURING",
    content:
     "In this phase, we bring the project to life by fabricating the necessary equipment and components. Our state-of-the-art manufacturing facilities ensure high-quality production that adheres to industry standards and regulations. Our rigorous quality control measures guarantee equipment reliability and performance in the demanding oil and gas environment." ,
     image: structureImage4,
  },
  {
    title: "POST CONSTRUCTION SUPPORT",
    content:
     "Our commitment doesn't end with project completion. We provide comprehensive post-construction support to ensure the smooth operation of your oil and gas facilities. This includes maintenance services, troubleshooting, equipment upgrades, and technical assistance. Our dedicated support team is available to address any issues and optimize your operations for long-term success.",    
     image: structureImage4,
  },
  
  
];
