import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactReadMoreReadLess from "react-read-more-read-less";
import crop1 from "./../Images/crop1.png";
import crop2 from "./../Images/crop3.png";
import crop3 from "./../Images/crop5.png";

const PrevButton = ({ onClick }) => {
  return (
    <button className="previous-btn test-prev" onClick={onClick}>
      <i class="bi bi-arrow-left arrow"></i>
    </button>
  );
};
const NextButton = ({ onClick }) => {
  return (
    <button className="next-btn test-next" onClick={onClick}>
      <i class="bi bi-arrow-right arrow"></i>
    </button>
  );
};

const ArrowButton = ({ imgSrc, imgAlt, onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{ backgroundColor: "transparent", border: "none" }}
    >
      <img
        src={imgSrc}
        alt={imgAlt}
        style={{
          width: "50px",
          height: "50px",
        }}
      />
    </button>
  );
};

export default function Testimonials() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    navs: true,
    autoplayHoverPause: false,
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevButton />,
    nextArrow: <NextButton />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    beforeChange: (current, next) => {
      console.log(next);
    },
  };
  return (
    <div className="pt-md-50 test-slick  w-100 px-4">
      <Slider {...settings}>
        <div className="item slide px-2">
          <div className="test-bx shadow my-4">
            <div className="client-img text-center">
              <img src={crop1} alt="" />
            </div>

            <div className="client-text  padt-40 mb-4">
              <p>
                <ReactReadMoreReadLess
                  charLimit={260}
                  readMoreText={"Read more"}
                  readLessText={"Read less"}
                  readMoreClassName="read-more-less--more"
                  readLessClassName="read-more-less--less"
                >
                  Mr. Umesh Misal from Universal Renewable Integrated Energy
                  Logics (URIEL) says “intelbuild’s dedication, commitment, and
                  deliverable rates made its services & design stand out from
                  the other competitors. In addition, he further proclaims that
                  the major reason he would recommend our services to other
                  professionals in the market is due to our technical support
                  which was offered to URIEL during the course of the project.
                  When asked about the impact intelbuild has created on URIEL’s
                  business, he states “we have received repeated enquiries from
                  previous clients, which has helped our business grow”.
                </ReactReadMoreReadLess>
              </p>
            </div>
            <div className="text-center">
              <div className="client-area">
                <div className="client-name">
                  <h3>Mr. Umesh Misal</h3>
                  <h3>Universal Renewable Integrated Energy Logics (URIEL)</h3>
                </div>
              </div>

              <div className="pb-4">
                <div className="client-reviews d-flex justify-content-center">
                  <span className="rating-stars  d-flex">
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star"></i>
                  </span>
                  <span className="rating-stars text-center">9/10</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item slide px-2">
          <div className="test-bx shadow my-4">
            <div className="client-img text-center">
              <img src={crop2} alt="" />
            </div>

            <div className="client-text  padt-40 mb-4">
              <p>
                <ReactReadMoreReadLess
                  charLimit={260}
                  readMoreText={"Read more"}
                  readLessText={"Read less"}
                  readMoreClassName="read-more-less--more"
                  readLessClassName="read-more-less--less"
                >
                  Mr. Rakesh Jadhav from Sredstva Regionale Chemie Private
                  Limited says” intelbuild presented a detailed fabrication
                  plan, which not only fulfilled all our requirements but also
                  was economical, and matched our budget”. He further added “We
                  shall recommend intelbuild for its super-fast services and
                  quality work, along with strong technical expertise, as they
                  incorporated the use of quality material. When asked about
                  whether his expectations were met or not during the project
                  delivery stage, he states that the fabrication work was
                  completed as per their requirement, and way before time.
                </ReactReadMoreReadLess>
              </p>
            </div>
            <div className="text-center">
              <div className="client-area">
                <div className="client-name">
                  <h3> Mr. Rakesh Jadhav</h3>
                  <h3>Sredstva Regionale Chemie Private Limited</h3>
                  <p>
                    <span className="me-2 org-txt">
                      Customer Service Manager{" "}
                    </span>
                  </p>
                </div>
              </div>

              <div className="pb-4">
                <div className="client-reviews d-flex justify-content-center">
                  <span className="rating-stars  d-flex">
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star"></i>
                  </span>
                  <span className="rating-stars text-center">9/10</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item slide px-2">
          <div className="test-bx shadow my-4">
            <div className="client-img text-center">
              <img src={crop3} alt="" />
            </div>

            <div className="client-text  padt-40 mb-4">
              <p>
                <ReactReadMoreReadLess
                  charLimit={270}
                  readMoreText={"Read more"}
                  readLessText={"Read less"}
                  readMoreClassName="read-more-less--more"
                  readLessClassName="read-more-less--less"
                >
                  Mr. Syed Valayat, Technical Manager at MARCO on their
                  collaboration with intelbuild classified their overall
                  experience in three segments, namely – 1. Team availability at
                  all times 2. Minimum to no-delay in submission of work 3.
                  Technical Support in all stages He further went on to provide
                  his valuable feedback on how differently we could have done
                  things during the design stage, by ensuring co-ordination with
                  other disciplines at their organization. He further added,
                  “Working with intelbuild has impacted MARCO with positive,
                  while keeping in mind all specifications provided by us”
                </ReactReadMoreReadLess>
              </p>
            </div>
            <div className="text-center">
              <div className="client-area">
                <div className="client-name">
                  <h3>Mr. Syed Valayat</h3>
                  <h3>MARCO</h3>
                  <p>
                    <span className="me-2 org-txt"> Technical Manager</span>
                  </p>
                </div>
              </div>

              <div className="pb-4">
                <div className="client-reviews d-flex justify-content-center">
                  <span className="rating-stars  d-flex">
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star-fill"></i>
                    <i class="bi bi-star"></i>
                  </span>
                  <span className="rating-stars text-center">8.5/10</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
}
