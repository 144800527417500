import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import OilAndGasBanner from '../SubComponents/Banner'
import { Link } from 'react-router-dom'
import abt1 from './../Images/abt2.jpg';
import OilGas1 from './../Images/oil-gas1.png';
import OilGas2 from './../Images/oil-gas2.png';
import OilGas3 from './../Images/oil-gas3.png';
import Bld1 from './../Images/bld1.png';
import Bld2 from './../Images/bld2.png';
import Bld3 from './../Images/bld3.png';
import Bld4 from './../Images/bld4.png';
import Magazine from './../Images/Magzine.jpg';
import Certificate2 from './../Images/certificate2.jpeg';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';




// tabs

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Banner from '../SubComponents/Banner'
import Img1 from './../Images/alain-pham-P_qvsF7Yodw-unsplash.jpg';

import contact from './../Images/popup-bg1.jpeg';
import ProductsFilter from '../SubComponents/ProductsFilter'
import SectionWipes from '../SubComponents/ParallaxHero'
import ParallaxSmallBanner from '../SubComponents/ParallaxSmallBanner'


function Cart() {
    return (
        <>
            <Header />

            
            <ParallaxSmallBanner img={Img1} title='Cart' />



            <div className='pos-rel'>
                <div class="w-100 form-section py-100 ">

                    <div class="cart-sec">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="cart-heading">
                                        <h3>Products</h3>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-3">
                                            <div class="cart-heading">
                                                <h3>Price</h3>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="cart-heading">
                                                <h3>Quantity</h3>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="cart-heading">
                                                <h3>Total</h3>
                                            </div>
                                        </div>
                                        <div class="col-3">
                                            <div class="cart-heading">
                                                <h3></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row bord-bot">
                                <div class="col-md-6">
                                    <div class="pro-details">
                                        <div class="row">
                                            <div class="col-4">
                                                <div class="pro-img">
                                                    <img className='w-100' src={Img1} alt="" />
                                                </div>
                                            </div>
                                            <div class="col-8">
                                                <div class="pro-txt">
                                                    <h3>Product Name Here</h3>
                                                    <p>To provide out notch services to our client that outperform</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="row h-100">
                                        <div class="col-3 my-auto">
                                            <div class="cart-sub-heading">
                                                <h6>$45.00</h6>
                                            </div>
                                        </div>
                                        <div class="col-3 my-auto">
                                            <div class="cart-heading">
                                                <span>
                                                    <ButtonGroup className="me-2 btn-grp" aria-label="group">
                                                        <Button><i class="bi bi-dash"></i></Button> <Button>1</Button> <Button className='active'><i class="bi bi-plus"></i></Button>
                                                    </ButtonGroup>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-3 my-auto">
                                            <div class="cart-sub-heading">
                                                <h6>$45.00</h6>
                                            </div>
                                        </div>
                                        <div class="col-3 my-auto">
                                            <div class="cart-sub-heading ">
                                                <button className='trash-btn'><i class="bi bi-trash3"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row bord-bot">
                                <div class="col-md-6">
                                    <div class="pro-details">
                                        <div class="row">
                                            <div class="col-4">
                                                <div class="pro-img">
                                                    <img className='w-100' src={Img1} alt="" />
                                                </div>
                                            </div>
                                            <div class="col-8">
                                                <div class="pro-txt">
                                                    <h3>Product Name Here</h3>
                                                    <p>To provide out notch services to our client that outperform</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="row h-100">
                                        <div class="col-3 my-auto">
                                            <div class="cart-sub-heading">
                                                <h6>$45.00</h6>
                                            </div>
                                        </div>
                                        <div class="col-3 my-auto">
                                            <div class="cart-heading">
                                                <span>
                                                    <ButtonGroup className="me-2 btn-grp" aria-label="group">
                                                        <Button><i class="bi bi-dash"></i></Button> <Button>1</Button> <Button className='active'><i class="bi bi-plus"></i></Button>
                                                    </ButtonGroup>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-3 my-auto">
                                            <div class="cart-sub-heading">
                                                <h6>$45.00</h6>
                                            </div>
                                        </div>
                                        <div class="col-3 my-auto">
                                            <div class="cart-sub-heading ">
                                                <button className='trash-btn'><i class="bi bi-trash3"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row bord-bot">
                                <div class="col-md-6">
                                    <div class="pro-details">
                                        <div class="row">
                                            <div class="col-4">
                                                <div class="pro-img">
                                                    <img className='w-100' src={Img1} alt="" />
                                                </div>
                                            </div>
                                            <div class="col-8">
                                                <div class="pro-txt">
                                                    <h3>Product Name Here</h3>
                                                    <p>To provide out notch services to our client that outperform</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="row h-100">
                                        <div class="col-3 my-auto">
                                            <div class="cart-sub-heading">
                                                <h6>$45.00</h6>
                                            </div>
                                        </div>
                                        <div class="col-3 my-auto">
                                            <div class="cart-heading">
                                                <span>
                                                    <ButtonGroup className="me-2 btn-grp" aria-label="group">
                                                        <Button><i class="bi bi-dash"></i></Button> <Button>1</Button> <Button className='active'><i class="bi bi-plus"></i></Button>
                                                    </ButtonGroup>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-3 my-auto">
                                            <div class="cart-sub-heading">
                                                <h6>$45.00</h6>
                                            </div>
                                        </div>
                                        <div class="col-3 my-auto">
                                            <div class="cart-sub-heading ">
                                                <button className='trash-btn'><i class="bi bi-trash3"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row bord-bot">
                                <div class="col-md-6">
                                    <div class="pro-details">
                                        <div class="row">
                                            <div class="col-4">
                                                <div class="pro-img">
                                                    <img className='w-100' src={Img1} alt="" />
                                                </div>
                                            </div>
                                            <div class="col-8">
                                                <div class="pro-txt">
                                                    <h3>Product Name Here</h3>
                                                    <p>To provide out notch services to our client that outperform</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="row h-100">
                                        <div class="col-3 my-auto">
                                            <div class="cart-sub-heading">
                                                <h6>$45.00</h6>
                                            </div>
                                        </div>
                                        <div class="col-3 my-auto">
                                            <div class="cart-heading">
                                                <span>
                                                    <ButtonGroup className="me-2 btn-grp" aria-label="group">
                                                        <Button><i class="bi bi-dash"></i></Button> <Button>1</Button> <Button className='active'><i class="bi bi-plus"></i></Button>
                                                    </ButtonGroup>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-3 my-auto">
                                            <div class="cart-sub-heading">
                                                <h6>$45.00</h6>
                                            </div>
                                        </div>
                                        <div class="col-3 my-auto">
                                            <div class="cart-sub-heading ">
                                                <button className='trash-btn'><i class="bi bi-trash3"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >



                <Footer />

            </div>
        </>
    )
}

export default Cart;