import React, { useState } from 'react'

export default function FilterTeamsTab({setAllTems, setManagerTems, setHeadTems, setStaffTems, activeTeam,setStructuralTems, setExecutionTems ,setArchitectTems }) {
  const [categoryStructure, setCategoryStructure] = useState("");
  function handleSelect(e){
    setCategoryStructure(e?.target?.value);
    if(e.target.value === "all"){
      setAllTems();
    } 
    // else if(e.target.value === "managers"){
    //   setManagerTems();
    // }
    else if(e.target.value === "heads"){
      setHeadTems();
    }
    else if(e.target.value === "staff"){
      setStaffTems();
    }
    else if(e.target.value === "structural"){
      setStructuralTems();
    }
    else if(e.target.value === "execution"){
      setExecutionTems();
    }
    else if(e.target.value === "architect"){
      setArchitectTems();
    }
  }
  return (
    <>
        <div className="FilterTeamsTab invisible-scrollbar d-none d-md-flex flex-wrap w-100 mb-5 font-artifakt">
            <button onClick={setAllTems} className={`btn border-0 ${activeTeam === "all" ? "btn-warning text-white" : "btn-outline-secondary"} text-24 px-4 py-2`}>All</button>
            
            {/* <button onClick={setManagerTems} className={`btn border-0 ${activeTeam === "managers" ? "btn-warning text-white" : "btn-outline-secondary"} text-24 px-4 py-2`}>Managers</button> */}
            
            <button onClick={setHeadTems} className={`btn border-0 ${activeTeam === "heads" ? "btn-warning text-white" : "btn-outline-secondary"} text-24 px-4 py-2`}>Executive Board</button>
            
            {/* <button onClick={setStructuralTems} className={`btn border-0 ${activeTeam === "structural" ? "btn-warning text-white" : "btn-outline-secondary"} text-24 px-4 py-2`}>Structural Engineering Department</button> */}
        
            <button onClick={setStaffTems} className={`btn border-0 ${activeTeam === "staff" ? "btn-warning text-white" : "btn-outline-secondary"} text-24 px-4 py-2`}>BIM Specialist</button>

            <button onClick={setArchitectTems} className={`btn border-0 ${activeTeam === "architect" ? "btn-warning text-white" : "btn-outline-secondary"} text-24 px-4 py-2`}>Design Team</button>
        </div>
        <div className="FilterTeamsTab d-block d-md-none row flex-wrap justify-content-center w-100 mb-5">
        <div className="w-100 mb-4 mx-auto text-start">
                      {/* <label>Type Of Structure</label> */}
                      <select
                        className="form-select  mb-3"
                        aria-label=".form-select-lg example"
                        onChange={handleSelect}
                      >
                        <option selected value="all">All</option>
                        {/* <option value="managers">Managers</option> */}
                        <option value="heads">Executive Board</option>
                        <option value="structural"> Structural Engineering Department </option>
                        <option value="staff">BIM Specialist </option>
                        <option value="execution"> Execution Department </option>
                      </select>
                    </div>
        </div>
    </>
  )
}
