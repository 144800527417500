import React, { useState } from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import OilAndGasBanner from '../SubComponents/Banner'
import { Link } from 'react-router-dom'
import abt1 from './../Images/abt2.jpg';
import Career from './../Images/career.jpg';
import OilGas2 from './../Images/oil-gas2.png';
import OilGas3 from './../Images/oil-gas3.png';
import Bld1 from './../Images/bld1.png';
import Bld2 from './../Images/bld2.png';
import Bld3 from './../Images/bld3.png';
import Bld4 from './../Images/bld4.png';
import Magazine from './../Images/Magzine.jpg';
import Certificate2 from './../Images/certificate2.jpeg';

import { useRef } from 'react'
import Image1 from './../Images/image-1.png';
// tabs

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Banner from '../SubComponents/Banner'
import emailjs from 'emailjs-com';

import contact from './../Images/oil-gas.jpg';
import TrustedSlider from '../SubComponents/TrustedSlider'
import Reviews from '../SubComponents/Reviews'
import SectionWipes from '../SubComponents/ParallaxHero'

function Careers() {

    const [file, setFile] = useState();
    const [filename, setFilename] = useState();

    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };
    function handleChange(e) {
        console.log(e.target.files);

        setFilename(e.target.files[0].name);
        setFile(URL.createObjectURL(e.target.files[0]));




    }
    const form = useRef()
    function sendEmail(e) {
        // alert('good morning')
        e.preventDefault();

        emailjs.sendForm('service_gv7h749', 'template_mwsiptw', form.current, 'K49l0okANjKtyoz_w')

            .then((result) => {
                console.log(result.text);
                if (result.text) {
                    // window.location.reload()
                    window.location = "/careers"

                    alert('your information has submited')
                    // window.location.reload()
                }
            }, (error) => {
                console.log(error.text);
                alert('your information not submited')
            });
    }
    return (
        <>
            <Header />
            
            <SectionWipes img={contact} title='Careers' />


            {/* Contact form starts */}
            <div className='pos-rel'>
                <div class="w-100 form-section gr-bg">
                    <div class="">
                        <div className="contact-area">
                            <div class="row contact-form w-100 mx-0">
                                <div class="col-lg-6 py-5">
                                    <div class="px-4">
                                        <div className="contact-heading abt-txt">
                                            <h1>Careers With intelBUILD</h1>

                                            <p>We are constantly looking out for talented and professional detailers, checkers, and engineers. If you a professional who can make a difference by constantly contributing to the organization and an evolving personality who aims at higher growth aspiration with a zeal to prove yourself, Please feel free to reach out to us.</p>

                                        </div>

                                        <form action="" ref={form} onSubmit={sendEmail}>
                                            <div className="row">

                                                <div className="col-lg-6 mb-4">




                                                    <label>First Name</label>
                                                    <input type="text" placeholder="Enter First name" name='toFirstName' />

                                                </div>
                                                <div className="col-lg-6 mb-4">


                                                    <label>Last Name</label>
                                                    <input type="text" placeholder="Enter Last name" name='toLastName' />


                                                </div>
                                                <div className="col-lg-6 mb-4">

                                                    <label>Email</label>
                                                    <input type="email" placeholder="Enter Email Address" name='toEmailId' />

                                                </div>
                                                <div className="col-lg-6 mb-4">
                                                    <label>Subject</label>
                                                    <input type="text" placeholder="Enter Subject" name='subject' />
                                                </div>
                                                <div className="mb-4">
                                                    <label>Message</label>
                                                    <textarea type="text" rows={4} placeholder="Enter Message" name='message' />
                                                </div>

                                         
                                                <div className="send-button">
                                                  
                                                    <button className="nav-btn">Send</button>

                                                </div>


                                            </div>
                                            <div className="">
                                                

                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="col-lg-6 px-0">
                                    <div class="careers-img">
                                        <img src={Career} alt="" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div >
                {/* contact form ends */}




                {/* buildings starts */}
                {/* <div className="w-100 gr-bg">
                <div className="">
                    <div className="row">
                        <div className="col-lg-6 px-0">
                            <div className="contact-heading pt-5 pb-4 px-4">
                                <h1>Buildings</h1>
                                <p>Covering momentous projects under our guidance we are sufficient to deliver complete functional solutions in the field of oil and gas plants. Our workforce consists of selective skilled engineers and percipient management personnel having adequate domain knowledge in this industry and major leading softwares.The movement of people ensures that our cities thrive and grow. But as the world’s population increases, and globalisation and urbanisation continue t
                                </p>
                            </div>
                            <div class="row w-100 px-0 mx-0 h-100">
                                <div class="col-6 px-0">
                                    <div class="blg-img">
                                        <img src={Bld2} alt="" />
                                    </div>
                                </div>
                                <div class="col-6 px-0">
                                    <div class="blg-img">
                                        <img src={Bld3} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 px-0">
                            <div class="h-100 rel-pos">
                                <div class="blg-big-img">
                                    <img src={Bld1} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 px-0">
                            <div class="h-100 rel-pos">
                                <div class="blg-large-img">
                                    <img src={Bld4} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div > */}
                {/* buildings ends */}




                <Footer />
            </div>
        </>
    )
} export default Careers