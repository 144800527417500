import React from 'react'
import IbLibraryImg from "../Images/IbLibrary/iblibrary.jpeg"
import Header from '../Components/Header'
import Footer from '../Components/Footer'
export default function IbLibrary() {
  return (
    <>
        <Header />
        <img style={{width: "100%"}} src={IbLibraryImg} alt="IbLibraryImg" />
        <Footer />
    </>
  )
}
