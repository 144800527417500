// import React, { useState } from 'react';
// import './service-page.css'
// function ServicePage() {

//     const [isPopupOpen, setIsPopupOpen] = useState(false);
//     const [popupContent, setPopupContent] = useState('');
    
//     const handleServiceBoxClick = (content) => {
//         setPopupContent({ content });
//         setIsPopupOpen(true);
//     };


//     const closePopup = () => {
//         setIsPopupOpen(false);
//     };

//     const renderList = () => {
//         switch (popupContent) {
   
//             case 'Analysis':
//                 return (
//                     <ul className='service-popup'>


//                         <li><strong>Conduction of comprehensive load study:</strong> We meticulously
//                             analyze various factors affecting the stability and strength of
//                             structures, such as dead load, live load, seismic load, wind load,
//                             equipment load, and pipe load. This detailed load analysis helps
//                             us develop robust structures.</li>
//                         <li><strong>Implementation of Finite Element Methods (FEM):</strong> Utilizing
//                             cutting-edge software like STAAD.pro CONNECT EDITION, ETABS,
//                             SAP2000, SAFE, we employ FEM to understand parameters such
//                             as deflections, stiffness, and moments. The generated mesh
//                             helps us identify areas most affected by loads through color
//                             contouring and motion analysis.</li>
//                     </ul>
//                 );
//             case 'Design':
//                 return (
//                     <ul className='service-popup'>
//                         <li><strong>Adhering to design codes:</strong> Our experts possess extensive
//                             knowledge of design codes, allowing us to follow basic norms
//                             and generate the required outputs. We design models based
//                             on various code parameters, including Indian standard code,
//                             American Institute of Steel Construction (AISC), British standard
//                             codes, Australian standard codes, and Euro Codes.</li>
//                         <li><strong>Optimize design:</strong> Optimization is our top priority during the
//                             analysis and design phases. Through proof checking at every
//                             major step and leveraging our industrial experience, we aim to
//                             minimize revisions and optimize available resources. Our
//                             design process not only fulfills client requirements but also
//                             ensures structural stability.</li>

//                     </ul>
//                 );

//             case 'Detailing':
//                 return (
//                     <ul className='service-popup'>

import React, { useState } from 'react';
import Service1 from "../Images/realestate/real-estate-1.jpg";
import Service2 from "../Images/realestate/real-estate-2.jpg";
import Service3 from "../Images/realestate/real-estate-3.jpg";
import Service4 from "../Images/realestate/real-estate-4.jpg";
import Service5 from "../Images/realestate/real-estate-5.jpg";
import Service6 from "../Images/realestate/real-estate-6.jpg";
import "./service-page.css";

function ServicePage() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupContent, setPopupContent] = useState("");
  const [popupBgColor, setPopupBgColor] = useState("");
  const [popupTextColor, setPopupTextColor] = useState("");
  const [popupImage, setPopupImage] = useState(Service1);

  const handleServiceBoxClick = (content, realEstateImg) => {
    setPopupContent(content);
    setPopupImage(realEstateImg)
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const changePopupColors = (bgColor, textColor) => {
    setPopupBgColor(bgColor);
    setPopupTextColor(textColor);
  };

  const renderList = () => {
    switch (popupContent) {
      case "Analysis":
        return (
          <ul className="service-popup">
            <li>
              <strong>Conduction of comprehensive load study:</strong> We
              meticulously analyze various factors affecting the stability and
              strength of structures, such as dead load, live load, seismic
              load, wind load, equipment load, and pipe load. This detailed load
              analysis helps us develop robust structures.
            </li>
            <li>
              <strong>Implementation of Finite Element Methods (FEM):</strong>{" "}
              Utilizing cutting-edge software like STAAD.pro CONNECT EDITION,
              ETABS, SAP2000, SAFE, we employ FEM to understand parameters such
              as deflections, stiffness, and moments. The generated mesh helps
              us identify areas most affected by loads through color contouring
              and motion analysis.
            </li>
          </ul>
        );
      case "Design":
        return (
          <ul className="service-popup">
            <li>
              <strong>Adhering to design codes:</strong> Our experts possess
              extensive knowledge of design codes, allowing us to follow basic
              norms and generate the required outputs. We design models based on
              various code parameters, including Indian standard code, American
              Institute of Steel Construction (AISC), British standard codes,
              Australian standard codes, and Euro Codes.
            </li>
            <li>
              <strong>Optimize design:</strong> Optimization is our top priority
              during the analysis and design phases. Through proof checking at
              every major step and leveraging our industrial experience, we aim
              to minimize revisions and optimize available resources. Our design
              process not only fulfills client requirements but also ensures
              structural stability.
            </li>
          </ul>
        );
      case "Detailing":
        return (
          <ul className="service-popup">
            <li>
              <strong>Software modeling:</strong> Our structural engineering
              team utilizes software to perform 3D modeling, facilitating
              project execution and coordination. Additionally, we are
              proficient in BIM software such as Revit, AutoCAD 2D, and 3D,
              enabling us to meet client requirements and deliver high-quality
              results. Equipment 3D modeling provides visual clarity and aids in
              inspecting designed components.
            </li>
            <li>
              <strong>Piping and Equipment modeling and detailing:</strong>{" "}
              Detailed attention is given to the critical aspects of piping work
              and its placement in heavy-scale industries. We prepare
              fabrication drawings to ensure a thorough understanding of the
              project.
            </li>
          </ul>
        );

      case "Manufacturing":
        return (
          <ul className="service-popup">
            <li>
              <strong>Emphasis on quality:</strong> Our detailed drawings and
              models contribute to error-free erection of structures and
              equipment. We prioritize the quality of materials and services
              from the initial design stage, ensuring the longevity of equipment
              and supporting structures. Fabrication work undergoes rigorous
              inspections to adhere to design parameters, including weld types
              and sizes.
            </li>
            <li>
              <strong>Safety achievement:</strong> Safety is our utmost priority
              during on-site project execution. With experienced supervisors and
              engineers, we have maintained a zero-risk record, successfully
              completing activities while adhering to all safety protocols.
            </li>
          </ul>
        );

      case "Support":
        return (
          <ul className="service-popup">
            <li>
              <strong>Efficient delivery:</strong> Our track record showcases
              timely completion of multiple projects. By closely collaborating
              with clients and execution teams, we work in tandem to meet
              project deadlines. Sequential activities ensure satisfactory
              results within the specified timeframe
            </li>
            <li>
              <strong>Inspection and testing:</strong> We perform thorough
              quality inspections and testing of materials, both in laboratories
              and on- site. Grades of materials are checked on-site to verify
              accurate gauge size, thickness, rust-proofing, and other relevant
              details. Activities such as proper handling, coating,
              sandblasting, and barrier coating contribute to clean and durable
              metal surfaces.
            </li>
            <li>
              <strong>Logistics:</strong> As a trusted company, we operate
              securely throughout India and overseas, following all legal
              guidelines. We prioritize smooth and efficient product delivery to
              our clients.
            </li>
          </ul>
        );
      default:
        return null;
    }
  };

    return (




        <div class="service-container2">


            <div class="service-box-blank box-o">
                <h1>service delivery PROCESS </h1>
                <span>oil & gas services</span>

            </div>

            {/* <div class="service-box-blank box-o2">

                <h1>PROCESS </h1>
                <span>oil & gas services</span>
            </div> */}

            {/* /////////////////////////////// */}
            <div class="service-box-blank box-o3">

                <h1>service delivery PROCESS </h1>
                <span>oil & gas services</span>
            </div>
            {/* /////////////////////////////// */}

            <div class="service-box box-a" onClick={() => handleServiceBoxClick('Analysis', Service1)}>
                <div class="service-left">
                    <div class="service-number">01</div>
                    <div class="service-icon2"><i class="bi bi-pie-chart-fill"></i></div>
                </div>
                <div class="service-content2">
                    <h2>Analysis</h2>
                    <ul>
                        <li>Conduction of comprehensive load study</li>
                        <li>Implementation of Finite Element Methods (FEM)</li>
                    </ul>
                </div>
            </div>


            <div class="service-box box-b" onClick={() => handleServiceBoxClick('Design', Service2)}>
                <div class="service-left">
                    <div class="service-number">02</div>
                    <div class="service-icon2"><i class="bi bi-brush"></i></div>
                </div>
                <div class="service-content2">
                    <h2>Design</h2>
                    <ul>
                        <li>Adhere to design codes:</li>
                        <li>Optimize design:</li>
                    </ul>
                </div>
            </div>

            {/* <!-- ////////////////////////////// --> */}
            <div class="service-box box-c" onClick={() => handleServiceBoxClick('Detailing', Service3)}>
                <div class="service-left">
                    <div class="service-number">03</div>

                    <div class="service-content2">
                        <h2>Detailing</h2>
                        <ul>
                            <li>Software modeling:</li>
                            <li>Piping and Equipment modeling and detailing</li>
                        </ul>
                    </div>

                    <div class="service-icon2"><i class="bi bi-binoculars-fill"></i></div>
                </div>

            </div>
            {/* <!-- ////////////////////////////// --> */}
            <div class="service-box box-c-2" onClick={() => handleServiceBoxClick('Detailing', Service3)}>
                <div class="service-left">
                    <div class="service-number">03</div>
                    <div class="service-icon2"><i class="bi bi-binoculars-fill"></i></div>
                </div>
                <div class="service-content2">
                    <h2>Detailing</h2>
                    <ul>
                        <li>Software modeling:</li>
                        <li>Piping and Equipment modeling and detailing</li>
                    </ul>
                </div>

            </div>


            {/* <!-- ////////////////////////////// --> */}


            <div class="service-box box-d" onClick={() => handleServiceBoxClick('Manufacturing', Service4)}>
                <div class="service-left">
                    <div class="service-number">04</div>
                    <div class="service-icon2"><i class="bi bi-gear"></i></div>
                </div>
                <div class="service-content2">
                    <h2>Manufacturing</h2>
                    <ul>
                        <li>Emphasize quality</li>
                        <li>Achieve safety</li>
                    </ul>
                </div>
            </div>
            <div class="service-box box-e" onClick={() => handleServiceBoxClick('Support', Service5)}>
                <div class="service-left">
                    <div class="service-number">05</div>
                    <div class="service-icon2"><i class="bi bi-building"></i></div>
                </div>
                <div class="service-content2">
                    <h2>Post Construction Support</h2>
                    <ul>
                        <li>Ensure efficient delivery</li>
                        <li>Inspection and testing</li>
                        <li>Manage logistics</li>
                    </ul>
                </div>
            </div>
            {/* ////////////////////////////////////// 1  */}

            
      {isPopupOpen && (
        <div className="popup">
          <div className="popup-inner" style={{ background: "" }}>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              style={{}}
              onClick={closePopup}
            ></button>

            <div
              className="float-popup-bg service-popup-container"
              style={{
                backgroundPositionX: "left",
                height: "35rem",
                border: "20px solid red",
              }}
            >
              <div
                style={{
                  height: "35rem",
                  width: "90%",
                  padding: "3rem 0 0 2rem",
                }}
              >
                <h2>Oil and Gas:</h2>
                <p>
                  We offer a comprehensive range of services focused on
                  designing, building, and delivering exceptional structures in
                  the oil and gas sector. Our dedicated team, comprised of
                  structural engineers, civil engineers, draftsmen, and project
                  managers, possesses a deep understanding of the technical
                  terminologies and requirements specific to this industry. Our
                  primary focus lies in front-end engineering services, where we
                  prioritize strict adherence to project requirements from the
                  initial stages. Through continuous growth, we have developed
                  expertise in government rules and regulations governing the
                  oil and gas industry. Our service methodology revolves around
                  four key steps to ensure successful execution and provide a
                  satisfactory output while maintaining a unique and responsible
                  approach. The services we offer include:
                </p>

                {/* <HashLink className='nav-btn' onClick={() => props.setTrigger(false)} to="/#popup" >Get Your Free Estimation</HashLink> */}
              </div>
            </div>
          </div>
        </div>
      )}

      {isPopupOpen && (
        <div className="popup">
          <div
            className="popup-inner"
            style={{ background: "#eee", color: "#171717" }}
          >
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              style={{}}
              onClick={closePopup}
            ></button>

            <div
              className="float-popup-bg"
              style={{
                backgroundPositionX: "left",
                height: "35rem",
                overflow: "scroll",
              }}
            >
              <div
                style={{
                  height: "35rem",
                  width: "100%",
                  padding: "2rem 0 0 2rem",
                  background: "#eee"
                }}
              >
                <h3>{popupContent}</h3>
                <center>
                  <img style={{margin: "auto", maxWidth: "450px", height: "auto", aspectRatio: "1 / 1", objectFit:"contin", objectPosition: "center"}} className="mt-3 mb-3 d-block" src={popupImage} alt="popupImage" />
                </center>
                {renderList()}
              </div>
            </div>
          </div>
        </div>
      )}
        </div>
    )
}
export default ServicePage;