import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Award1 from "./../Images/awards-and-achievements/certificate1.jpg";
import abt2 from "./../Images/real-estate-bg1.jpeg";
import "react-html5video/dist/styles.css";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import ParallaxSmallBanner from "../SubComponents/ParallaxSmallBanner";
import SecondaryMenu from "./SecondaryMenu";
import Img1 from "./../Images/alain-pham-P_qvsF7Yodw-unsplash.jpg";
import pic1 from "./../Images/featured news/pav5.jpg";

function FeaturedNews(props) {
  return (
    <>
      <Header />
      <div className="scrll">
        <ParallaxSmallBanner img={Img1} title="Featured News" />
      </div>
      <div className="pos-rel">
        <div className="w-100 py-5 pad-60">
          <div className="pt-2 pb-5 text-center">
            <SecondaryMenu linkName={props.linkName} />
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="masonry">
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                >
                  <Masonry>
                    <div className="masonry-img">
                      <div className="blog-bx h-100">
                        <div className="blog-img-1">
                          <img src={Award1} className="w-100" alt="" />
                        </div>
                        <div className="blog-date">
                          <span className="me-3">08-11-2021</span>
                          <span>Category</span>
                        </div>
                        <div className="blog-text">
                          <h1>
                            Believing neglected so so allowance existence
                            departure.
                          </h1>
                          <p>
                            Blessing welcomed ladyship she met humoured sir
                            breeding her. Six curiosity day assurance bed
                            necessary.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="masonry-img">
                      <div className="blog-bx h-100">
                        <div className="blog-img-1">
                          <img src={pic1} alt="" />
                        </div>
                        <div className="blog-date">
                          <span className="me-3">08-11-2021</span>
                          <span>Category</span>
                        </div>
                        <div className="blog-text">
                          <h1>
                            Believing neglected so so allowance existence
                            departure.
                          </h1>
                          <p>
                            Blessing welcomed ladyship she met humoured sir
                            breeding her. Six curiosity day assurance bed
                            necessary.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="masonry-img">
                      <div className="blog-bx h-100">
                        <div className="blog-img-1">
                          <img src={abt2} alt="" />
                        </div>
                        <div className="blog-date">
                          <span className="me-3">08-11-2021</span>
                          <span>Category</span>
                        </div>
                        <div className="blog-text">
                          <h1>
                            Believing neglected so so allowance existence
                            departure.
                          </h1>
                          <p>
                            Blessing welcomed ladyship she met humoured sir
                            breeding her. Six curiosity day assurance bed
                            necessary.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="masonry-img">
                      <div className="blog-bx h-100">
                        <div className="blog-img-1">
                          <img src={abt2} alt="" />
                        </div>
                        <div className="blog-date">
                          <span className="me-3">08-11-2021</span>
                          <span>Category</span>
                        </div>
                        <div className="blog-text">
                          <h1>
                            Believing neglected so so allowance existence
                            departure.
                          </h1>
                          <p>
                            Blessing welcomed ladyship she met humoured sir
                            breeding her. Six curiosity day assurance bed
                            necessary.
                          </p>
                        </div>
                      </div>
                    </div>
                  </Masonry>
                </ResponsiveMasonry>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default FeaturedNews;
