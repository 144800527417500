import React from "react";
import { Link, NavLink } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";

const Footer = () => {
  return (
    <>
      <footer>
        <div class="w-100 ">
          <div class="footer-menu">
            <div class="menu-outer">
              <div class="px-60 py-2">
                <div class="footer-menu-list d-flex justify-content-between align-items-center">
                  <ul className="d-flex">
                    <li>
                      <NavLink
                        to="/about"
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        About Us
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/blogs"
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        Latest Insights
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/careers"
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        Career
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/contact"
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        Contact
                      </NavLink>
                    </li>
                  </ul>
                  <div class="footer-menu-list d-flex justify-content-between align-items-center new-social-icon">
                    <span class="social">
                      <a
                        target="_blank"
                        href="https://www.facebook.com./intelbuildhuzaifa"
                        rel="noreferrer"
                      >
                        <i class="fa-brands fa-facebook-f"></i>
                      </a>
                    </span>
                    <span class="social">
                      <a
                        target="_blank"
                        href="https://www.twitter.com/intelbuildarc"
                        rel="noreferrer"
                      >
                        <i class="fa-brands fa-twitter"></i>
                      </a>
                    </span>
                    <span class="social">
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/intelbuild-engineering-services-llp/?originalSubdomain=in"
                        rel="noreferrer"
                      >
                        <i class="fa-brands fa-instagram"></i>
                      </a>
                    </span>
                    <span class="social">
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/company/intelbuild-engineering-services-llp/?originalSubdomain=in"
                        rel="noreferrer"
                      >
                        <i class="fa-brands fa-linkedin"></i>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="footer-bg">
            <div class="main-footer pt-lg-80">
              <div class="row">
                <div class="col-lg-5 ps-50 col-md-6 mb-4 text-start ftr-pd">
                  <div class="footer-heading">
                    <h3>About Us</h3>
                  </div>
                  <div class="footer-desc">
                    <p>
                      IntelBuild is a one-stop platform to nurture the
                      entrepreneurial mindset in people across the globe. We are
                      committed to transforming the world through innovation and
                      collaboration.
                    </p>
                  </div>
                  <div class="footer-address d-flex ">
                    <span>
                      <span className="spanbox">
                        <i class="bi bi-geo-alt"></i>
                      </span>
                    </span>
                    <span class="ps-2 ftr-mrg">
                      {" "}
                      304 Stanford Plaza, Off Link Road, Andheri (W),
                      Mumbai-400058. India
                    </span>
                  </div>
                  <div class="footer-address d-flex align-items-center">
                    <span>
                      <span className="spanbox">
                        <i class="bi bi-send"></i>
                      </span>
                    </span>
                    <span class="ps-2 ftr-mrg">
                      <a
                        href="mailto:info@intelbuild.in"
                        target="_blank"
                        rel="noreferrer"
                      >
                        info@intelbuild.in
                      </a>
                    </span>
                  </div>
                </div>
                <div class="col-lg-2 col-md-6 mb-4 text-start">
                  <div class="footer-heading">
                    <h3>Quick Links</h3>
                  </div>
                  <div class="footer-link">
                    <ul class="ps-0">
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li>
                        <Link to="/careers">Careers</Link>
                      </li>
                      <li>
                        <Link to="/projects">Projects</Link>
                      </li>

                      <li>
                        <Link to="/products">Products</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-2 col-md-6 mb-4 text-start">
                  <div class="footer-heading">
                    <h3>Services</h3>
                  </div>
                  <div class="footer-link">
                    <ul class="ps-0">
                      <li>
                        <Link to="/oil-and-gas">Oil &amp; gas</Link>
                      </li>
                      <li>
                        <Link to="/peb-structures">Pre-Engineered Buildings</Link>
                      </li>
                      <li>
                        <Link to="/real-estate">Real Estate</Link>
                      </li>
                      <li>
                        <Link to="/bim-services">BIM Servises</Link>
                      </li>
                      <li>
                        <Link to="/sustainable-projects">
                          Sustainable Design &amp; Engineering
                        </Link>
                      </li>
                      <li>
                        <Link to="/structural-audit">Structural Audit</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 mb-4 text-start">
                  <div class="footer-heading">
                    <h3>Resources</h3>
                  </div>
                  <div class="footer-link">
                    <ul class="ps-0">
                      <li>
                        <Link to="/careers">Careers</Link>
                      </li>
                      <li>
                        <Link to="">Inquiry</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom-footer top-border pt-4 d-flex flex-md-row text-center flex-column justify-content-center">
              <div class="footer-copyright pb-4 text-center">
                © 2022 intelBUILD . Designed and Developed by : Visom6
                Technology Pvt Ltd
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
