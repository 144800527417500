import React, { Component } from "react";

class InputOpenings extends Component{
    constructor() {
     super();
     this.state= {
      inputSize: 0
     }
    }
    
    handleOnChange(value){
    this.setState({
    inputSize: value.target.value
    });
    }
    
    renderInputs(value){
     const inputs=[];
     for(let i=0; i<value; i++){
       inputs.push(<div className="row mb-4">
                    <div className="col-lg-6 mb-3">
                                        <label>Numbers</label>
                                        <input type="text" placeholder="Enter Numbers" />

                                    </div>
                                    <div className="col-lg-6 mb-3">


                                        <label>Size</label>
                                        <input type="text" placeholder="Enter Size" />


                                    </div>
   </div>)
     }
     return inputs;
    }
    
     render() {
     console.log(this.state.inputSize);
       return (<div>
        <div className="col-lg-12 mb-4">
                                        <label>Numbers of openings</label>
                                        <input type="number"  name="openings" min="0" max="100" onChange={(value)=>this.handleOnChange(value)} placeholder="How many Size" />
                                    </div>
            
      
       <div>
       {this.renderInputs(this.state.inputSize)}
       </div>
       </div>
       )
     }
   }
   export default InputOpenings;
   