import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { Link } from "react-router-dom";
import Career from "./../Images/career.jpg";
import OilGas2 from "./../Images/oil-gas2.png";

import Banner from "../SubComponents/Banner";


function Enquiry() {
  return (
    <>
      <Header />
      <Banner img={OilGas2} title="Enquiry" />

      <div className="w-100 form-section gr-bg">
        <div className="">
          <div className="contact-area">
            <div className="row contact-form">
              <div className="col-lg-6 py-5">
                <div className="px-4">
                  <div className="contact-heading">
                    <h1>Enquiry With intelBUILD</h1>

                    <p>
                      We are constantly looking out for talented and
                      professional detailers, checkers, and engineers. If you a
                      professional who can make a difference by constantly
                      contributing to the organization and an evolving
                      personality who aims at higher growth aspiration with a
                      zeal to prove yourself, Please feel free to reach out to
                      us.
                    </p>
                  </div>
                  <form action="">
                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <label>First Name</label>
                        <input type="text" placeholder="Enter First name" />
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label>Last Name</label>
                        <input type="text" placeholder="Enter Last name" />
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label>Email</label>
                        <input type="email" placeholder="Enter Email Address" />
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label>Subject</label>
                        <input type="text" placeholder="Enter Subject" />
                      </div>
                      <div className="mb-4">
                        <label>Message</label>
                        <textarea
                          type="text"
                          rows={4}
                          placeholder="Enter Message"
                        />
                      </div>
                    </div>
                    <div className="">
                      <Link className="nav-btn" to="/">
                        Submit
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="careers-img">
                  <img src={Career} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Enquiry;
