import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import Parallaximg from './Parallaximg';


const Banner = (page) => {
  return (
    <>
      <div className='parallax-sec'>
        <ParallaxProvider>
          <Parallaximg className="par-img" imgsrc={page.img} opacity="0.5">
            <section className="contact-bg parallax">

              <div className="social-bg parallax-window">
              <div className="info-inn">
                <h1 className="info" style={{ color: "#ffffff" }}>{page.title}</h1>
                </div>
              </div>
            </section>
          </Parallaximg>

        </ParallaxProvider>
      </div>
    </>
  )
}

export default Banner