import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import OilAndGasBanner from '../SubComponents/Banner'
import { Link } from 'react-router-dom'
import abt1 from './../Images/abt2.jpg';
import OilGas1 from './../Images/oil-gas1.png';
import OilGas2 from './../Images/oil-gas2.png';
import OilGas3 from './../Images/oil-gas3.png';
import Bld1 from './../Images/bld1.png';
import Bld2 from './../Images/bld2.png';
import Bld3 from './../Images/bld3.png';
import Bld4 from './../Images/bld4.png';
import Magazine from './../Images/Magzine.jpg';
import Certificate2 from './../Images/certificate2.jpeg';
import { lazy } from "react";




// tabs

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Banner from '../SubComponents/Banner'

import contact from './../Images/popup-bg1.jpeg';
import ProductsFilter from '../SubComponents/ProductsFilter'
import SectionWipes from '../SubComponents/ParallaxHero'


function Projects() {
    return (
        <>
            <Header />
          
           
            <SectionWipes img={contact} title='Projects'/>
<div className='pos-rel'>
            <ProductsFilter />
            



            <Footer />
            </div>
        </>
    )
}

export default Projects