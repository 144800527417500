import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import "react-html5video/dist/styles.css";
import SoftwarePartners from "../SubComponents/SoftwarePartners";
import ParallaxSmallBanner from "../SubComponents/ParallaxSmallBanner";
import Img1 from "./../Images/alain-pham-P_qvsF7Yodw-unsplash.jpg";
import SecondaryMenu from "./SecondaryMenu";

function SoftwareAssociates(props) {
  return (
    <>
      <Header />

      <div className="scrll">
        <ParallaxSmallBanner img={Img1} title="Software Associates" />
      </div>
      <div className="pos-rel">
        <div className="w-100 py-5  pad-60">
          <div className="pt-2 pb-5 text-center">
            <SecondaryMenu linkName={props.linkName} />
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="pt-5 px-4 w-100 partners">
                <SoftwarePartners />
                <div className="contact-heading text-center max-600 mx-auto mb-4">
                  <p>
                    We’ve done it carefully and simply. Combined with the
                    ingredients make for beautiful landings. It is definitely
                    the tool you need to speed up your design process.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default SoftwareAssociates;
