import React, { useState } from "react";
import { Link } from "react-router-dom";

function EnquiryOilGas() {
  const [isFormOpen, setFormOpen] = useState(false);
  const [file, setFile] = useState();
  const [filename, setFilename] = useState();
  const [file1, setFile1] = useState();
  const [filename1, setFilename1] = useState();
  const [showhide, setShowhide] = useState("no");

  const [openings, setOpenings] = useState("typical");
  const handleOpenings = (e) => {
    const getOpenings = e.target.value;
    setOpenings(getOpenings);
  };
  const handleShowhide = (e) => {
    const getShowhide = e.target.value;
    setShowhide(getShowhide);
  };

  const [turbos, setTurbos] = useState("no");
  const handleTurbos = (e) => {
    const getTurbos = e.target.value;
    setTurbos(getTurbos);
  };

  const [showcrane, setShowCrane] = useState("no");
  const handleShowCrane = (e) => {
    const getShowCrane = e.target.value;
    setShowCrane(getShowCrane);
  };

  const [showCanopy, setShowCanopy] = useState("no");
  const handleShowCanopy = (e) => {
    const getShowCanopy = e.target.value;
    setShowCanopy(getShowCanopy);
  };
  const [showMezzanine, setShowMezzanine] = useState("no");
  const handleShowMezzanine = (e) => {
    const getShowMezzanine = e.target.value;
    setShowMezzanine(getShowMezzanine);
  };
  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  function handleChange(e) {
    console.log(e.target.files);

    setFilename(e.target.files[0].name);
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  const hiddenFileInput1 = React.useRef(null);
  const handleClick1 = (event) => {
    hiddenFileInput1.current.click();
  };
  function handleChange1(e) {
    console.log(e.target.files);

    setFilename1(e.target.files[0].name);
    setFile1(URL.createObjectURL(e.target.files[0]));
  }
  const toggleFormOpen = () => {
    setFormOpen(!isFormOpen);
  };
  return (
    <>
      <div className="contact-area">
        <div className="contact-form">
          <div className="col-lg-12 pt-4 pb-4">
            <div className="px-4">
              <div className="form-title mb-4">
                <h1>Oil and Gas</h1>
              </div>
              <form action="">
                <div className="row">
                  <div className="col-lg-6 mb-4">
                    <label>Plant Type</label>
                    <select
                      class="form-select"
                      aria-label=".form-select-lg example"
                    >
                      <option selected>Select Plant Type</option>
                      <option value="main plant">
                        Main Plant/ Process Plant
                      </option>
                      <option value="2">Storage &amp; Handling</option>
                      <option value="4">Service Buildings</option>
                      <option value="5">Utilities(Liquid System)</option>
                      <option value="6">Other</option>
                    </select>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <div className="divider"></div>
                  </div>

                  <div className="col-lg-6 mb-4">
                    <label>Equipment Loads</label>
                    <select
                      class="form-select"
                      aria-label=".form-select-lg example"
                    >
                      <option selected>Select Equipment Loads</option>
                      <option value="1">Dead Load(Empty Weight)</option>
                      <option value="2">Dead Load(Filled Weight)</option>
                      <option value="3">Live Load</option>
                    </select>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <div className="divider"></div>
                  </div>

                  <div className="col-lg-12 mb-4">
                    <label>Dimensions Of Plots:</label>

                    <div className="col-lg-6 mb-3">
                      <label>Length</label>
                      <input type="text" placeholder="Enter Length" />
                    </div>
                    <div className="col-lg-6 mb-3">
                      <label>Width</label>
                      <input type="text" placeholder="Enter Width" />
                    </div>
                    <div className="col-lg-12">
                      <span className="upld-btn" onClick={handleClick}>
                        <i class="bi bi-upload"></i> Upload an Image
                      </span>
                      <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <div className="divider"></div>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <label>Size of structure</label>

                    <div className="col-lg-4 mb-3">
                      <label>Length</label>
                      <input type="text" placeholder="Enter Length" />
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label>Width</label>
                      <input type="text" placeholder="Enter Width" />
                    </div>
                    <div className="col-lg-4">
                      <label>Clear Height</label>
                      <input type="text" placeholder="Enter Clear Height" />
                    </div>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <div className="divider"></div>
                  </div>

                  <div className="col-lg-12 mb-4">
                    <label>Location</label>
                    <input type="text" placeholder="Enter Location" />
                  </div>
                  <div className="col-lg-12 mb-4">
                    <div className="divider"></div>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <label>Design Code</label>
                    <div className="col-lg-12">
                      <input
                        class="form-check-input me-2"
                        type="radio"
                        name="flexRadioDefault15"
                        checked
                      />
                      <label class="form-check-label me-4">Indian</label>
                      <input
                        class="form-check-input me-2"
                        type="radio"
                        name="flexRadioDefault15"
                      />
                      <label class="form-check-label">AISC</label>
                    </div>
                  </div>

                  <div className="col-lg-12 mb-4">
                    <div className="divider"></div>
                  </div>

                  <div className="col-lg-12 mb-4">
                    <label>
                      7. Any other design specifications / additional
                      requirements{" "}
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Additional Requirements"
                      className="mb-3"
                    />
                    <div className="col-lg-12 mb-3">
                      <span className="upld-btn" onClick={handleClick1}>
                        <i class="bi bi-upload"></i> Upload an Image
                      </span>
                      <input
                        type="file"
                        ref={hiddenFileInput1}
                        onChange={handleChange1}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="">
                  <Link className="nav-btn" to="/">
                    Submit
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EnquiryOilGas;
