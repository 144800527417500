import React, { useState } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import OilAndGasBanner from "../SubComponents/Banner";
import { Link } from "react-router-dom";
import abt1 from "./../Images/abt2.jpg";
import OilGas1 from "./../Images/oil-gas1.png";
import OilGas2 from "./../Images/oil-gas2.png";
import OilGas3 from "./../Images/oil-gas3.png";
import Bld1 from "./../Images/bld1.jpg";
import Bld2 from "./../Images/bld2.jpg";
import Bld3 from "./../Images/bld3.jpg";
import Bld4 from "./../Images/bld4.jpg";
import Magazine from "./../Images/Magzine.jpg";
import Certificate2 from "./../Images/certificate2.jpeg";

import PEBBG from "./../Images/header/peb.jpg";

import PEB1 from "./../Images/website-images/Industrial-PEB-Projects/04_PEB-Shed-at-Angola.jpeg";
import PEB2 from "./../Images/website-images/Industrial-PEB-Projects/PEB-shed-at-Angola.jpeg";
import PEB3 from "./../Images/website-images/Industrial-PEB-Projects/PEB-Shed-at-Odhisa.jpg";
import PEB4 from "./../Images/website-images/Industrial-PEB-Projects/Shed-at-Surat-GJ.jpeg";
import PEB5 from "./../Images/website-images/Industrial-PEB-Projects/PEB-Bhiwandi.jpg";
import PEB6 from "./../Images/website-images/Industrial-PEB-Projects/02_PEB-Shed-at-Surat.jpeg";
import PEB7 from "./../Images/website-images/Industrial-PEB-Projects/PEB2.jpeg";
import PEB8 from "./../Images/website-images/Industrial-PEB-Projects/Chemical-Plant-at-Boiser.jpg";

import Certificate1 from "./../Images/awards-and-achievements/intelBuild-Engineering-Services-LLP-1.png";

import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Banner from "../SubComponents/Banner";

import contact from "./../Images/peb-bg.jpg";
import ProductsFilter from "../SubComponents/ProductsFilter";
import SectionWipes from "../SubComponents/ParallaxHero";

import ProjectPopup from "../SubComponents/ProjectPopup";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import PebData from "../Data/PebData";

import PebPage from "./pebPage";
import PebPageLeft from "./pebPage-left";
import PebPageRight from "./pebPage-right";
import PieChart from "./peb";

function PEBStructures() {
  const [buttonPopup, setButtonPopup] = useState(false);
  const [tempdata, setTempdata] = useState([]);

  const slices = [
    { color: "#8fc741", value: 12.5, icon: "bi bi-heart" },
    { color: "#1c907f", value: 12.5, icon: "bi bi-star" },
    { color: "#3f66b0", value: 12.5, icon: "bi bi-lightbulb" },
    { color: "#655ca8", value: 12.5, icon: "bi bi-music-note" },
    { color: "#9f4098", value: 12.5, icon: "bi bi-camera" },
    { color: "#f05623", value: 12.5, icon: "bi bi-people" },
    { color: "#f7901e", value: 12.5, icon: "bi bi-gift" },
    { color: "#fbcd09", value: 12.5, icon: "bi bi-leaf" },
    // { color: 'transparent', value: 12.5, icon: 'bi bi-leaf' },
  ];

  const getData = (image, service, category) => {
    let tempData = [image, service, category];
    setTempdata((item) => [image]);

    return setButtonPopup(true);
  };

  return (
    <>
      <Header />
      <SectionWipes img={PEBBG} title="PEB Structures" />
      <div className="pos-rel">
        <div className="w-100 pt-5 pb-5 ">
          <div className="contact-heading max-600 mx-auto text-center px-4">
            <h1>Little Bit Overview On Service</h1>

            <p>
              Introducing our exceptional Pre-Engineered Buildings Design &
              Build service, where innovation meets efficiency. Our team of
              skilled architects, engineers, and construction professionals
              specialize in delivering customized solutions that exceed client
              expectations. From conceptualization to execution, we prioritize
              precision and quality, ensuring every detail of the pre-engineered
              building is meticulously designed and flawlessly constructed. With
              our expertise in utilizing state-of-the-art technology and
              industry best practices, we create sustainable and cost-effective
              structures that are tailored to meet your specific requirements.
              Experience the seamless integration of design and construction
              with our Pre-Engineered Buildings Design & Build service, where we
              turn your vision into reality, ensuring durability, functionality,
              and aesthetic appeal in every project we undertake
            </p>
          </div>
        </div>
        <div className="w-100 pt-5 gr-bg">
          <div className="px-4">
            <div className="w-100 ">
              <div className="row w-100">
                {/* <div className="col-lg-6 mb-lg-0 mb-4">
                  <div className="contact-heading max-500">
                    <h4>Our Services</h4>
                    <h1>PEB Structures</h1>

                    <p>
                      Covering momentous projects under our guidance we are
                      sufficient to deliver complete functional solutions in the
                      field of oil and gas plants. Our workforce consists of
                      selective skilled engineers and percipient management
                      personnel having adequate domain knowledge in this
                      industry and major leading softwares.
                    </p>
                    <div className="custom-flex">
                      <Link className="nav-btn me-4 custom-btn" to="/">
                        Get A Quote
                      </Link>
                      <Link className="nav-btn btn-w-1 custom-btn" to="/">
                        Download Brochure
                      </Link>
                    </div>
                  </div>
                </div> */}
                <h1 className="font-artifakt">How It Works</h1>

                <div className="col-lg-12">
                  <div className="row justify-content-center">
                    <div className="col-3 mb-5">
                      <div className="serv-txt">
                        <div className="service-icon mb-2">
                          <i className="bi bi-pencil-square"></i>
                        </div>
                        <div className="serv-cont">
                          <h3 class="mobile">Project Assessment</h3>
                          <p>
                            Understand the project requirements, including the
                            purpose of the building, its size, location, and any
                            specific functional or design considerations.
                          </p>
                          <Link className="lib-link" to="">
                            See libraries <i className="bi bi-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 mb-5">
                      <div className="serv-txt">
                        <div className="service-icon mb-2">
                          <i className="bi bi-pencil-square"></i>
                        </div>
                        <div className="serv-cont">
                          <h3 class="mobile">Site Evaluation: </h3>
                          <p>
                            Assess the site where the pre-engineered building
                            will be constructed, taking into account factors
                            such as topography, soil conditions, accessibility,
                            and local building codes.
                          </p>
                          <Link className="lib-link" to="">
                            See libraries <i className="bi bi-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 mb-5">
                      <div className="serv-txt">
                        <div className="service-icon mb-2">
                          <i className="bi bi-pencil-square"></i>
                        </div>
                        <div className="serv-cont">
                          <h3 class="mobile">Conceptual Design</h3>
                          <p>
                            Develop a preliminary design concept for the
                            pre-engineered building, considering factors like
                            the layout, architectural style, and overall
                            functionality. This includes determining the clear
                            span requirements and any specific design features.
                          </p>
                          <Link className="lib-link" to="">
                            See libraries <i className="bi bi-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 mb-5">
                      <div className="serv-txt">
                        <div className="service-icon mb-2">
                          <i className="bi bi-pencil-square"></i>
                        </div>
                        <div className="serv-cont">
                          <h3 class="mobile">Structural Analysis:</h3>
                          <p>
                            Perform a structural analysis to determine the
                            load-bearing capacity required for the building
                            based on factors such as wind loads, snow loads,
                            seismic activity, and other relevant environmental
                            considerations.
                          </p>
                          <Link className="lib-link" to="">
                            See libraries <i className="bi bi-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 mb-5">
                      <div className="serv-txt">
                        <div className="service-icon mb-2">
                          <i className="bi bi-pencil-square"></i>
                        </div>
                        <div className="serv-cont">
                          <h3 class="mobile">Material Selection: </h3>
                          <p>
                            Choose the appropriate materials for the
                            pre-engineered building, considering factors such as
                            structural integrity, durability, thermal
                            performance, and aesthetics. This includes selecting
                            the type of steel framing, roofing systems, wall
                            panels, insulation, and other components.
                          </p>
                          <Link className="lib-link" to="">
                            See libraries <i className="bi bi-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 mb-5">
                      <div className="serv-txt">
                        <div className="service-icon mb-2">
                          <i className="bi bi-pencil-square"></i>
                        </div>
                        <div className="serv-cont">
                          <h3 class="mobile">Detailed Design: </h3>
                          <p>
                            Develop detailed construction drawings and
                            specifications based on the conceptual design,
                            structural analysis, and material selection. This
                            includes the design of individual components,
                            connections, and interfaces.
                          </p>
                          <Link className="lib-link" to="">
                            See libraries <i className="bi bi-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 mb-5">
                      <div className="serv-txt">
                        <div className="service-icon mb-2">
                          <i className="bi bi-pencil-square"></i>
                        </div>
                        <div className="serv-cont">
                          <h3 class="mobile">Permitting and Approvals: </h3>
                          <p>
                            Obtain the necessary permits and approvals from
                            local authorities and regulatory bodies before
                            proceeding with the construction of the
                            pre-engineered building.
                          </p>
                          <Link className="lib-link" to="">
                            See libraries <i className="bi bi-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 mb-5">
                      <div className="serv-txt">
                        <div className="service-icon mb-2">
                          <i className="bi bi-pencil-square"></i>
                        </div>
                        <div className="serv-cont">
                          <h3 class="mobile">Fabrication and Manufacturing: </h3>
                          <p>
                            Fabricate the pre-engineered building components in
                            a controlled factory environment, ensuring precise
                            measurements, quality control, and adherence to
                            design
                          </p>
                          <Link className="lib-link" to="">
                            See libraries <i className="bi bi-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 mb-5">
                      <div className="serv-txt">
                        <div className="service-icon mb-2">
                          <i className="bi bi-pencil-square"></i>
                        </div>
                        <div className="serv-cont">
                          <h3 class="mobile">Site Preparation: </h3>
                          <p>
                            Prepare the construction site by clearing the area,
                            leveling the ground, and ensuring proper foundation
                            preparation in accordance with the building design
                            and local requirements.
                          </p>
                          <Link className="lib-link" to="">
                            See libraries <i className="bi bi-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 mb-5">
                      <div className="serv-txt">
                        <div className="service-icon mb-2">
                          <i className="bi bi-pencil-square"></i>
                        </div>
                        <div className="serv-cont">
                          <h3 class="mobile">Erection and Installation: </h3>
                          <p>
                            Assemble and install the pre-engineered building
                            components on-site, following the manufacturer's
                            guidelines and recommended construction practices.
                            This includes erecting the steel frame, installing
                            wall panels, roofing systems, and other structural
                            elements.
                          </p>
                          <Link className="lib-link" to="">
                            See libraries <i className="bi bi-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 mb-5">
                      <div className="serv-txt">
                        <div className="service-icon mb-2">
                          <i className="bi bi-pencil-square"></i>
                        </div>
                        <div className="serv-cont">
                          <h3 class="mobile">Finishing and Interior Work: </h3>
                          <p>
                            Complete the finishing touches of the pre-engineered
                            building, including interior partitions, electrical
                            and plumbing systems, HVAC installations, and any
                            other required interior features.
                          </p>
                          <Link className="lib-link" to="">
                            See libraries <i className="bi bi-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 mb-5">
                      <div className="serv-txt">
                        <div className="service-icon mb-2">
                          <i className="bi bi-pencil-square"></i>
                        </div>
                        <div className="serv-cont">
                          <h3 class="mobile">Quality Assurance and Inspections: </h3>
                          <p>
                            Conduct thorough inspections throughout the
                            construction process to ensure compliance with
                            design specifications, quality standards, and safety
                            regulations.
                          </p>
                          <Link className="lib-link" to="">
                            See libraries <i className="bi bi-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-3 mb-5">
                      <div className="self-center">
                        <Link className="nav-btn me-4 custom-btn" to="/">
                          Get A Quote
                        </Link>
                        <Link className="nav-btn btn-w-1 custom-btn" to="/">
                          Download Brochure
                        </Link>
                      </div>
                    </div>
                    <div className="col-3 mb-5">
                      <div className="serv-txt">
                        <div className="service-icon mb-2">
                          <i className="bi bi-pencil-square"></i>
                        </div>
                        <div className="serv-cont">
                          <h3 class="mobile">Commissioning and Handover:</h3>
                          <p>
                            Test and commission all building systems and
                            components to ensure proper functioning. Once the
                            pre-engineered building is deemed ready, hand it
                            over to the client or end-user.
                          </p>
                          <Link className="lib-link" to="">
                            See libraries <i className="bi bi-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <PebPage />
        {/* <PebPageLeft /> */}
        {/* <hr /> */}
        {/* <PebPageRight /> */}
        <div className="w-100 pt-5 pb-5 ">
          <div className="contact-heading max-600 mx-auto text-center px-4">
            <h4>Our Services</h4>
            <h1>In Services</h1>
          </div>
          <div className="w-100 ">
            <div className="row w-100 mx-0">
              <div className="col-lg-4 px-0 mb-lg-0 mb-4">
                <div className="team-box">
                  <div className="inserv-img">
                    <img src={PEB2} alt="" />
                  </div>
                  <div className="team-description testimonial-profile-name text-center p-team">
                    <div className="inserv-inner shadow p-3">
                      <p>
                        Designing and manufacturing of equipment, equipment
                        supporting structures, platforms, skids, and racks for
                        piping system.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 px-0 mb-lg-0 mb-4">
                <div className="team-box">
                  <div className="inserv-img">
                    <img src={PEB3} alt="" />
                  </div>
                  <div className="team-description testimonial-profile-name text-center p-team">
                    <div className="inserv-inner shadow p-3">
                      <p>
                        Designing and manufacturing of equipment, equipment
                        supporting structures, platforms, skids, and racks for
                        piping system.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 px-0 mb-lg-0 mb-4">
                <div className="team-box">
                  <div className="inserv-img">
                    <img src={PEB4} alt="" />
                  </div>
                  <div className="team-description testimonial-profile-name text-center p-team">
                    <div className="inserv-inner shadow p-3">
                      <p>
                        Designing and manufacturing of equipment, equipment
                        supporting structures, platforms, skids, and racks for
                        piping system.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="appex-pie"></div>
        <Footer />
      </div>
    </>
  );
}

export default PEBStructures;
