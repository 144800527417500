import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { Link } from "react-router-dom";
import "react-select-search/style.css";

function Products() {
  const options = [
    { name: "Swedish", value: "sv" },
    { name: "English", value: "en" },
    {
      type: "group",
      name: "Group name",
      items: [{ name: "Spanish", value: "es" }],
    },
  ];

  return (
    <>
      <Header />
      <div className="product-banner">
        <div className="w-100 h-100 d-flex flex-column justify-content-center ">
          <div className="products-outer text-center">
            <div className="product-heading mb-5 ">
              <h1>Structural Mine</h1>
            </div>
            <div className="product-search mb-5 mx-auto max-600">
              <div className="product-search-bx">
                <form className="select-for select-for-2">
                  <div class="d-flex mb-3" data-flip="false">
                    <select
                      class="form-select form-sel all-cat"
                      aria-label="Default select example"
                    >
                      <option selected>All Categories</option>
                      <optgroup label="Services">
                        <option value="1">Oil and Gas</option>
                        <option value="2">PEB Structures</option>
                        <option value="3">Real Estate</option>
                        <option value="4">BIM Services</option>
                        <option value="5">Sustainable Projects</option>
                        <option value="6">Structural Audit</option>
                      </optgroup>
                      <option value="blogs">Blogs</option>
                      <optgroup label="Products">
                        <option value="Product1">Product 1</option>
                        <option value="Product2">Product 2</option>
                        <option value="Product3">Product 3</option>
                      </optgroup>
                      <option value="careers">Careers</option>
                    </select>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      aria-label="Username"
                    />
                    <button className="search-btn">
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="product-buttons mb-70">
              <Link className="nav-btn me-3" to="">
                Sign up for free
              </Link>
              <Link className="nav-btn btn-w-1 ms-3" to="">
                7 days free trial
              </Link>
            </div>
            <div className="products-slgans  w-100 mb-5">
              <div className="row  w-100">
                <div className="col-4">
                  <div className="slogan-icon">
                    <i class="fa-solid fa-cloud"></i>
                  </div>
                  <div className="slogan-heading">
                    <h6>No Installation</h6>
                  </div>
                </div>
                <div className="col-4">
                  <div className="slogan-icon">
                    <i class="fa-solid fa-bolt-lightning"></i>
                  </div>
                  <div className="slogan-heading">
                    <h6>Powerful Modelling</h6>
                  </div>
                </div>
                <div className="col-4 ">
                  <div className="slogan-icon">
                    <i class="fa-solid fa-user-group"></i>
                  </div>
                  <div className="slogan-heading">
                    <h6>Collaborate and Share</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 pt-5 pb-4 ">
        <div className="contact-heading max-600 mx-auto text-center px-4">
          <h1>Products</h1>

          <p>
            We are a uniquely diverse range of consultants delivering extremely
            high profile projects across the world, working to meet some of the
            world’s greatest challenges.
          </p>
        </div>
      </div>

      {/* certifications starts */}
      <div className="w-100 mb-5">
        <div className=" px-4">
          <div class="row">
            <div class="col-lg-4 mb-4">
              <div class="prod-bx shadow h-100 p-4 text-center">
                <div class="prod-icon mb-4">
                  <span>
                    <i class="bi bi-box"></i>
                  </span>
                </div>
                <div class="prod-head">
                  <h1>Structural 3D</h1>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dolorum corporis itaque earum minima ex obcaecati, magnam
                    quidem doloremque assumenda architecto pariatur nostrum nam
                    deserunt suscipit, vero at maxime eos culpa.
                  </p>
                </div>
                <div class="learn-more">
                  <Link to="/product-details">
                    Learn More <i class="fa-solid fa-right-long"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="prod-bx shadow h-100 p-4 text-center">
                <div class="prod-icon mb-4">
                  <span>
                    <i class="bi bi-box"></i>
                  </span>
                </div>
                <div class="prod-head">
                  <h1>Structural 3D</h1>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dolorum corporis itaque earum minima ex obcaecati, magnam
                    quidem doloremque assumenda architecto pariatur nostrum nam
                    deserunt suscipit, vero at maxime eos culpa.
                  </p>
                </div>
                <div class="learn-more">
                  <Link to="/product-details">
                    Learn More <i class="fa-solid fa-right-long"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="prod-bx shadow h-100 p-4 text-center">
                <div class="prod-icon mb-4">
                  <span>
                    <i class="bi bi-box"></i>
                  </span>
                </div>
                <div class="prod-head">
                  <h1>Structural 3D</h1>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dolorum corporis itaque earum minima ex obcaecati, magnam
                    quidem doloremque assumenda architecto pariatur nostrum nam
                    deserunt suscipit, vero at maxime eos culpa.
                  </p>
                </div>
                <div class="learn-more">
                  <Link to="/product-details">
                    Learn More <i class="fa-solid fa-right-long"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="prod-bx shadow h-100 p-4 text-center">
                <div class="prod-icon mb-4">
                  <span>
                    <i class="bi bi-box"></i>
                  </span>
                </div>
                <div class="prod-head">
                  <h1>Structural 3D</h1>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dolorum corporis itaque earum minima ex obcaecati, magnam
                    quidem doloremque assumenda architecto pariatur nostrum nam
                    deserunt suscipit, vero at maxime eos culpa.
                  </p>
                </div>
                <div class="learn-more">
                  <Link to="/product-details">
                    Learn More <i class="fa-solid fa-right-long"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="prod-bx shadow h-100 p-4 text-center">
                <div class="prod-icon mb-4">
                  <span>
                    <i class="bi bi-box"></i>
                  </span>
                </div>
                <div class="prod-head">
                  <h1>Structural 3D</h1>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dolorum corporis itaque earum minima ex obcaecati, magnam
                    quidem doloremque assumenda architecto pariatur nostrum nam
                    deserunt suscipit, vero at maxime eos culpa.
                  </p>
                </div>
                <div class="learn-more">
                  <Link to="/product-details">
                    Learn More <i class="fa-solid fa-right-long"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="prod-bx shadow h-100 p-4 text-center">
                <div class="prod-icon mb-4">
                  <span>
                    <i class="bi bi-box"></i>
                  </span>
                </div>
                <div class="prod-head">
                  <h1>Structural 3D</h1>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dolorum corporis itaque earum minima ex obcaecati, magnam
                    quidem doloremque assumenda architecto pariatur nostrum nam
                    deserunt suscipit, vero at maxime eos culpa.
                  </p>
                </div>
                <div class="learn-more">
                  <Link to="/product-details">
                    Learn More <i class="fa-solid fa-right-long"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-100 mb-5">
        <div className="contact-heading max-600 mx-auto text-center px-4">
          <h1>Structural Design Software</h1>

          <p>
            We are a uniquely diverse range of consultants delivering extremely
            high profile projects across the world, working to meet some of the
            world’s greatest challenges.
          </p>
        </div>
        <div className=" px-4">
          <div class="row">
            <div class="col-lg-4 mb-4">
              <div class="prod-bx shadow h-100 p-4 text-center">
                <div class="prod-icon mb-4">
                  <span>
                    <i class="bi bi-box"></i>
                  </span>
                </div>
                <div class="prod-head">
                  <h1>Structural 3D</h1>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dolorum corporis itaque earum minima ex obcaecati, magnam
                    quidem doloremque assumenda architecto pariatur nostrum nam
                    deserunt suscipit, vero at maxime eos culpa.
                  </p>
                </div>
                <div class="learn-more">
                  <Link to="/product-details">
                    Learn More <i class="fa-solid fa-right-long"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="prod-bx shadow h-100 p-4 text-center">
                <div class="prod-icon mb-4">
                  <span>
                    <i class="bi bi-box"></i>
                  </span>
                </div>
                <div class="prod-head">
                  <h1>Structural 3D</h1>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dolorum corporis itaque earum minima ex obcaecati, magnam
                    quidem doloremque assumenda architecto pariatur nostrum nam
                    deserunt suscipit, vero at maxime eos culpa.
                  </p>
                </div>
                <div class="learn-more">
                  <Link to="/product-details">
                    Learn More <i class="fa-solid fa-right-long"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="prod-bx shadow h-100 p-4 text-center">
                <div class="prod-icon mb-4">
                  <span>
                    <i class="bi bi-box"></i>
                  </span>
                </div>
                <div class="prod-head">
                  <h1>Structural 3D</h1>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dolorum corporis itaque earum minima ex obcaecati, magnam
                    quidem doloremque assumenda architecto pariatur nostrum nam
                    deserunt suscipit, vero at maxime eos culpa.
                  </p>
                </div>
                <div class="learn-more">
                  <Link to="/product-details">
                    Learn More <i class="fa-solid fa-right-long"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="prod-bx shadow h-100 p-4 text-center">
                <div class="prod-icon mb-4">
                  <span>
                    <i class="bi bi-box"></i>
                  </span>
                </div>
                <div class="prod-head">
                  <h1>Structural 3D</h1>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dolorum corporis itaque earum minima ex obcaecati, magnam
                    quidem doloremque assumenda architecto pariatur nostrum nam
                    deserunt suscipit, vero at maxime eos culpa.
                  </p>
                </div>
                <div class="learn-more">
                  <Link to="/product-details">
                    Learn More <i class="fa-solid fa-right-long"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="prod-bx shadow h-100 p-4 text-center">
                <div class="prod-icon mb-4">
                  <span>
                    <i class="bi bi-box"></i>
                  </span>
                </div>
                <div class="prod-head">
                  <h1>Structural 3D</h1>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dolorum corporis itaque earum minima ex obcaecati, magnam
                    quidem doloremque assumenda architecto pariatur nostrum nam
                    deserunt suscipit, vero at maxime eos culpa.
                  </p>
                </div>
                <div class="learn-more">
                  <Link to="/product-details">
                    Learn More <i class="fa-solid fa-right-long"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mb-4">
              <div class="prod-bx shadow h-100 p-4 text-center">
                <div class="prod-icon mb-4">
                  <span>
                    <i class="bi bi-box"></i>
                  </span>
                </div>
                <div class="prod-head">
                  <h1>Structural 3D</h1>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dolorum corporis itaque earum minima ex obcaecati, magnam
                    quidem doloremque assumenda architecto pariatur nostrum nam
                    deserunt suscipit, vero at maxime eos culpa.
                  </p>
                </div>
                <div class="learn-more">
                  <Link to="/product-details">
                    Learn More <i class="fa-solid fa-right-long"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="gr-bg py-60">
        <div class="container z-ind-3">
          <div className="contact-heading max-700 mx-auto text-center px-4 ">
            <h1>Join 10,000+ Professional Engineers Worldwide!</h1>

            <p>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo
              neque distinctio delectus nihil exercitationem nam labore
              voluptatum nobis similique.
            </p>
            <Link className="nav-btn" to="#">
              Get started for free
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Products;
