import React from "react";
import { Controller, Scene } from "react-scrollmagic";

const SectionWipes = (page) => (
  <div className="over-hide">
    <Controller globalSceneOptions={{ triggerHook: "onLeave" }}>
      <Scene pin>
        <div
          className="panel blue"
          style={{ backgroundImage: `url(${page.img})` }}
          id="popup"
        >
          <span className="hero-title">{page.title}</span>
        </div>
      </Scene>
    </Controller>
  </div>
);

export default SectionWipes;
