import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import OilAndGasBanner from '../SubComponents/Banner'
import { Link } from 'react-router-dom'
import abt1 from './../Images/abt2.jpg';
import OilGas1 from './../Images/oil-gas1.png';
import OilGas2 from './../Images/oil-gas2.png';
import OilGas3 from './../Images/oil-gas3.png';
import Bld1 from './../Images/bld1.png';
import Bld2 from './../Images/bld2.png';
import Bld3 from './../Images/bld3.png';
import Bld4 from './../Images/bld4.png';
import Magazine from './../Images/Magzine.jpg';
import Certificate2 from './../Images/certificate2.jpeg';


// tabs

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Banner from '../SubComponents/Banner'

import contact from './../Images/popup-bg1.jpeg';
import ProductsFilter from '../SubComponents/ProductsFilter'
import SectionWipes from '../SubComponents/ParallaxHero'
import ParallaxSmallBanner from '../SubComponents/ParallaxSmallBanner'


function Login() {
    return (
        <>
            <Header />

            {/* <div className="w-100 pt-5 pb-4 ">
                <div className="contact-heading max-600 mx-auto text-center px-4">
                    <h1>Little Bit Overview On Service</h1>

                    <p>We are a uniquely diverse range of consultants delivering extremely high profile projects across the world, working to meet some of the world’s greatest challenges.
                    </p>

                </div>
            </div>
            */}
            <ParallaxSmallBanner img={contact} title='Login' />



            <div className='pos-rel'>
                <div class="w-100 form-section py-100 ">
                    <div class="px-4">
                        <div className="contact-area">
                            <div class="contact-form contact-us">
                                
                                <div class="d-flex justify-content-center  max-400 mx-auto">
                                    <div class="shadow p-4  w-100">
                                        <div className="contact-heading abt-txt text-center mb-4">
                                            <h2>Login to Your Account</h2>

                                        </div>
                                        <form action="">


                                            <div className=" mb-4">

                                                <label>Email</label>
                                                <input type="email" placeholder="Enter Email Address" />

                                            </div>
                                            <div className=" mb-4">


                                                <label>Password</label>
                                                <input type="password" placeholder="Enter Password" />


                                            </div>
                                          
                                            <div className="">
                                                <Link className="nav-btn-org-2 w-100" to="/">Login Now</Link>

                                            </div>
                                        </form>
                                        <div className='text-center mt-3'>
                                            <p>Already have and account? <Link className='login-org' to="/signup">Create an account</Link> </p>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>

                        </div>
                    </div>
                </div >



                <Footer />

            </div>
        </>
    )
}

export default Login;