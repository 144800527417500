import React, { useState } from "react";
import { Link } from "react-router-dom";
import InputOpenings from "./InputOpenings";

function EnquiryForm() {
  const [isFormOpen, setFormOpen] = useState(false);
  const [file, setFile] = useState();
  const [filename, setFilename] = useState();
  const [showhide, setShowhide] = useState("no");
  const [openings, setOpenings] = useState("typical");
  const [turbos, setTurbos] = useState("no");
  const [showcrane, setShowCrane] = useState("no");
  const [showCanopy, setShowCanopy] = useState("no");
  const [showMezzanine, setShowMezzanine] = useState("no");
  const hiddenFileInput = React.useRef(null);

  const handleOpenings = (e) => {
    const getOpenings = e.target.value;
    setOpenings(getOpenings);
  };

  const handleShowhide = (e) => {
    const getShowhide = e.target.value;
    setShowhide(getShowhide);
  };

  const handleTurbos = (e) => {
    const getTurbos = e.target.value;
    setTurbos(getTurbos);
  };

  const handleShowCrane = (e) => {
    const getShowCrane = e.target.value;
    setShowCrane(getShowCrane);
  };

  const handleShowCanopy = (e) => {
    const getShowCanopy = e.target.value;
    setShowCanopy(getShowCanopy);
  };

  const handleShowMezzanine = (e) => {
    const getShowMezzanine = e.target.value;
    setShowMezzanine(getShowMezzanine);
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  function handleChange(e) {
    setFilename(e.target.files[0].name);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  return (
    <>
      <div className="contact-area">
        <div className="contact-form">
          <div className="col-lg-12 pt-4 pb-4">
            <div className="px-4">
              <div className="form-title mb-3">
                <h1>PEB Shed</h1>
              </div>
              <form action="">
                <div className="row">
                  <div className="col-lg-12 mb-2">
                    <label>Dimensions Of Plots:</label>
                  </div>

                  <div className="col-lg-6 mb-3">
                    <label>Length</label>
                    <input type="text" placeholder="Enter Length" />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label>Width</label>
                    <input type="text" placeholder="Enter Width" />
                  </div>
                  <div className="col-lg-12 mb-4">
                    <span className="upld-btn" onClick={handleClick}>
                      <i className="bi bi-upload"></i> Upload an Image
                    </span>
                    <input
                      type="file"
                      ref={hiddenFileInput}
                      onChange={handleChange}
                      style={{ display: "none" }}
                    />
                  </div>

                  <div className="col-lg-12 mb-3">
                    <div className="divider"></div>
                  </div>
                  <div className="col-lg-12 mb-2">
                    <label>Size of structure</label>
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label>Length</label>
                    <input type="text" placeholder="Enter Length" />
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label>Width</label>
                    <input type="text" placeholder="Enter Width" />
                  </div>
                  <div className="col-lg-4 mb-4">
                    <label>Clear Height</label>
                    <input type="text" placeholder="Enter Clear Height" />
                  </div>

                  <div className="col-lg-12 mb-4">
                    <div className="divider"></div>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <label>Building Type</label>
                    <select
                      className="form-select mb-3"
                      aria-label=".form-select-lg example"
                    >
                      <option selected>Select Building Type</option>
                      <option value="1">Clear Span</option>
                      <option value="2">Intermediate Columns</option>
                    </select>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <div className="divider"></div>
                  </div>

                  <div className="col-lg-6 mb-4">
                    <label>Bay Spacing</label>
                    <input type="text" placeholder="Enter Bay Spacing" />
                  </div>
                  <div className="col-lg-12 mb-4">
                    <div className="divider"></div>
                  </div>
                  <div className="col-lg-6 mb-4">
                    <label>Periphery Wall</label>
                    <div className="col-lg-6">
                      <input
                        className="form-check-input me-2"
                        type="radio"
                        name="userdetail"
                        value="yes"
                        onClick={handleShowhide}
                        id="flexRadioDefault111"
                      />
                      <label
                        className="form-check-label me-4"
                        for="flexRadioDefault111"
                      >
                        Yes
                      </label>
                      <input
                        className="form-check-input me-2"
                        type="radio"
                        name="userdetail"
                        value="no"
                        onClick={handleShowhide}
                        checked={showhide === "no"}
                        id="flexRadioDefault112"
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault112"
                      >
                        No
                      </label>
                    </div>
                  </div>

                  {showhide === "yes" && (
                    <>
                      <div className="col-lg-6 ">
                        <label>Select Periphery Wall</label>
                        <select
                          className="form-select mb-3"
                          aria-label=".form-select-lg example"
                        >
                          <option selected>Select Periphery Wall</option>
                          <option value="brick-wall">Brick Wall</option>
                          <option value="siporex">Siporex</option>
                        </select>
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label>Height</label>
                        <input type="text" placeholder="Enter Height" />
                      </div>
                    </>
                  )}
                  <div className="col-lg-12 mb-4">
                    <div className="divider"></div>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <label>Openings</label>

                    <div className="col-lg-12 mb-2">
                      <input
                        className="form-check-input me-2"
                        type="radio"
                        name="openings"
                        value="typical"
                        onClick={handleOpenings}
                        id="flexRadioDefault121"
                        checked={openings === "typical"}
                      />
                      <label
                        className="form-check-label me-4"
                        for="flexRadioDefault121"
                      >
                        Typical
                      </label>
                      <input
                        className="form-check-input me-2"
                        type="radio"
                        name="openings"
                        value="atypical"
                        onClick={handleOpenings}
                        id="flexRadioDefault122"
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault122"
                      >
                        Atypical
                      </label>
                    </div>

                    {openings === "typical" && (
                      <>
                        <div className="col-lg-6">
                          <label>Size</label>
                          <input type="text" placeholder="Enter Size" />
                        </div>
                      </>
                    )}
                    {openings === "atypical" && (
                      <>
                        <InputOpenings />
                      </>
                    )}
                  </div>
                  <div className="col-lg-12 mb-4">
                    <div className="divider"></div>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <label>Sheeting Type</label>
                    <select
                      className="form-select"
                      aria-label=".form-select-lg example"
                    >
                      <option selected>Select Sheeting Type</option>
                      <option value="1">Galvallium Sheet</option>
                      <option value="2">Standing Seems</option>
                      <option value="3">Puff Panels</option>
                    </select>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <div className="divider"></div>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <label>Crane</label>
                    <div className="col-lg-12">
                      <input
                        className="form-check-input me-2"
                        type="radio"
                        value="yes"
                        onClick={handleShowCrane}
                        name="crane"
                      />
                      <label className="form-check-label me-4">Yes</label>
                      <input
                        className="form-check-input me-2"
                        type="radio"
                        value="no"
                        onClick={handleShowCrane}
                        checked={showcrane === "no"}
                        name="crane"
                      />
                      <label className="form-check-label">No</label>
                    </div>
                    {showcrane === "yes" && (
                      <>
                        <div className="col-lg-6 mb-4">
                          <label>Capacity</label>
                          <input type="text" placeholder="Enter Capacity" />
                        </div>
                      </>
                    )}
                  </div>

                  <div className="col-lg-12 mb-4">
                    <div className="divider"></div>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <label>Turbos and Skylights</label>

                    <div className="col-lg-12">
                      <input
                        className="form-check-input me-2"
                        type="radio"
                        name="turbos"
                        value="yes"
                        onClick={handleTurbos}
                      />
                      <label className="form-check-label me-4">Yes</label>
                      <input
                        className="form-check-input me-2"
                        type="radio"
                        value="no"
                        onClick={handleTurbos}
                        checked={turbos === "no"}
                        name="turbos"
                      />
                      <label className="form-check-label">No</label>
                    </div>
                    {turbos === "yes" && (
                      <>
                        <div className="col-lg-6 mb-4">
                          <label>Turbo</label>
                          <input type="text" placeholder="Enter Numbers" />
                        </div>
                        <div className="col-lg-6 mb-4">
                          <label>Skylights</label>
                          <input type="text" placeholder="Enter Numbers" />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-lg-12 mb-4">
                    <div className="divider"></div>
                  </div>

                  <div className="col-lg-12">
                    <label>Canopy</label>

                    <div className="col-lg-12 mb-4">
                      <input
                        className="form-check-input me-2"
                        type="radio"
                        value="yes"
                        onClick={handleShowCanopy}
                        name="canopy"
                      />
                      <label className="form-check-label me-4">Yes</label>
                      <input
                        className="form-check-input me-2"
                        type="radio"
                        value="no"
                        onClick={handleShowCanopy}
                        checked={showCanopy === "no"}
                        name="canopy"
                      />
                      <label className="form-check-label">No</label>
                    </div>

                    {showCanopy === "yes" && (
                      <>
                        <div className="col-lg-6 mb-4">
                          <label>Width</label>
                          <input type="text" placeholder="Enter Width" />
                        </div>
                        <div className="col-lg-6 mb-4">
                          <label>Height</label>
                          <input type="text" placeholder="Enter Height" />
                        </div>
                      </>
                    )}
                  </div>

                  <div className="col-lg-12 mb-4">
                    <div className="divider"></div>
                  </div>

                  <div className="col-lg-12">
                    <label>Mezzanine</label>
                    <div className="col-lg-12 mb-4">
                      <input
                        className="form-check-input me-2"
                        type="radio"
                        value="yes"
                        onClick={handleShowMezzanine}
                        name="mezzanine"
                        checked
                      />
                      <label className="form-check-label me-4">Yes</label>
                      <input
                        className="form-check-input me-2"
                        type="radio"
                        value="no"
                        onClick={handleShowMezzanine}
                        checked={showMezzanine === "no"}
                        name="mezzanine"
                      />
                      <label className="form-check-label">No</label>
                    </div>
                    {showMezzanine === "yes" && (
                      <>
                        <div className="col-lg-6 mb-4">
                          <label>Width</label>
                          <input type="text" placeholder="Enter Width" />
                        </div>
                        <div className="col-lg-6 mb-4">
                          <label>Height</label>
                          <input type="text" placeholder="Enter Height" />
                        </div>
                      </>
                    )}
                  </div>

                  <div className="col-lg-12 mb-4">
                    <div className="divider"></div>
                  </div>
                  <div className="col-lg-12 mb-0">
                    <label>Design Code</label>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <input
                      className="form-check-input me-2"
                      type="radio"
                      name="flexRadioDefault15"
                      checked
                    />
                    <label className="form-check-label me-4">Indian</label>
                    <input
                      className="form-check-input me-2"
                      type="radio"
                      name="flexRadioDefault15"
                    />
                    <label className="form-check-label">AISC</label>
                  </div>
                </div>
                <div className="">
                  <Link className="nav-btn" to="/">
                    Submit
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EnquiryForm;
