import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import Magazine from "./../Images/Magzine.jpg";
import Certificate from "./../Images/certificate 1.jpg";
import Certificate1 from "./../Images/certificates/1.jpg";
import Certificate2 from "./../Images/certificates/2.jpg";
import Certificate3 from "./../Images/certificates/3.jpg";
import Certificate4 from "./../Images/certificates/4.jpg";
export default function CertificationsModel({
  show,
  handleClose,
  activeIndex,
  handleSelect,
}) {
  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Certifications</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel
            slide={false}
            onSelect={handleSelect}
            activeIndex={activeIndex}
            fade
          >
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Certificate}
                alt="Certificate1"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Certificate2}
                alt="Certificate2"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Certificate3}
                alt="Certificate3"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Certificate4}
                alt="Certificate4"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={Magazine} alt="Magzine" />
            </Carousel.Item>
          </Carousel>
        </Modal.Body>
      </Modal>
    </div>
  );
}
