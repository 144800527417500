import React from "react";
import CanvasJSReact from "@canvasjs/react-charts";

function chart() {
  const CanvasJSChart = CanvasJSReact.CanvasJSChart;
  const options = {
    // exportEnabled: true,
    animationEnabled: true,
    title: {
      text: "",
    },
    data: [
      {
        type: "pie",
        startAngle: 90,
        toolTipContent: "<b>{label}</b>: {y}%",
        showInLegend: "true",
        legendText: "{label}",
        indexLabelFontSize: 15,
        dataPoints: [
          { y: 12.5, label: "Design and Engineering" },
          { y: 12.5, label: "Construction Process" },
          { y: 12.5, label: "Cost and Efficiency" },
          { y: 12.5, label: "Flexibility and Customization" },
          { y: 12.5, label: "Social" },
          { y: 12.5, label: "Social" },
          { y: 12.5, label: "Social" },
          { y: 12.5, label: "Social" },
        ],
      },
    ],
  };
  return (
    <div>
      <CanvasJSChart
        options={options}
        /* onRef={ref => this.chart = ref} */
      />
    </div>
  );
}

export default chart;
