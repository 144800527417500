import React, { useState } from "react";
import ProductsData from "./../Data/ProductsData";
import ProductsData2 from "./../Data/ProductsData2";
import ProductsData3 from "./../Data/ProductsData3";
import { v4 as uuidv4 } from "uuid"
import ProjectPopup from "./ProjectPopup";
import { Swiper, SwiperSlide } from "swiper/react";
import "./ProductsFilter.css"

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
// import required modules
import { Grid, Navigation } from "swiper/modules";

// import css
import './ProductsFilter.css'
import { useNavigate } from "react-router-dom";

const ProductsFilter = () => {
  const [buttonPopup, setButtonPopup] = useState(false);
  const [modal, setModal] = useState(false);
  const [tempdata, setTempdata] = useState([]);
  const [items, setItems] = useState(ProductsData);
  const [items2, setItems2] = useState(ProductsData2);
  const [items3, setItems3] = useState(ProductsData3);
  const [activeButton, setActiveButton] = useState("All");
  const [index, setIndex] = useState(0);

  const navigate = useNavigate()

  const getData = (image, service, category) => {
    let tempData = [image, service, category];
    setTempdata((item) => [image]);
    return setButtonPopup(true);
  };
  const getData2 = (image, service, category) => {
    let tempData = [image, service, category];
    setTempdata((item) => [image]);
    return setButtonPopup(true);
  };
  const settings = {
    slidesToShow: 3,
    speed: 500,
    rows: 2,
    slidesPerRow: 2
  };
  // const handleShow = () => setShow(true);

  const filterItem = (catItem) => {
    setActiveButton(catItem);
    const updatedItems = ProductsData.filter((curElem) => {
      return curElem.category === catItem;
    });
    setItems(updatedItems);
  };

  const handleRedirect = () => {
    navigate("/projectview")
  }

  return (
    <>
      <div className="w-100 pt-5">
        <div className="contact-heading max-600 mx-auto text-center px-4">
          <h1>Our Projects</h1>
          <p>
            We are a uniquely diverse range of consultants delivering extremely
            high profile projects across the world, working to meet some of the
            world’s greatest challenges.
          </p>
        </div>
        <div className="products-tabs">
          <div className="products-tab mb-4 d-flex justify-content-between">
            {/* <button
              className={
                activeButton === "All" ? "filter-btn active" : "filter-btn"
              }
              onClick={() => filterItem("All")}
            >
              All
            </button> */}
            <button
              className={
                activeButton === "oil-and-gas"
                  ? "filter-btn active"
                  : "filter-btn"
              }
              onClick={() => filterItem("oil-and-gas")}
            >
              Oil And Gas
            </button>
            <button
              className={
                activeButton === "peb-structures"
                  ? "filter-btn active"
                  : "filter-btn"
              }
              onClick={() => filterItem("peb-structures")}
            >
              Pre-Engineered Building
            </button>
            <button
              className={
                activeButton === "real-estate"
                  ? "filter-btn active"
                  : "filter-btn"
              }
              onClick={() => filterItem("real-estate")}
            >
              Real Estate
            </button>
            <button
              className={
                activeButton === "bim" ? "filter-btn active" : "filter-btn"
              }
              onClick={() => filterItem("bim")}
            >
              3D Automation Engineering
            </button>
            <button
              className={
                activeButton === "sus" ? "filter-btn active" : "filter-btn"
              }
              onClick={() => filterItem("sus")}
            >
              Sustainable Design &amp; Engineering
            </button>
            <button
              className={
                activeButton === "tructural-audit"
                  ? "filter-btn active"
                  : "filter-btn"
              }
              onClick={() => filterItem("tructural-audit")}
            >
              Structural Audit
            </button>
          </div>
        </div>
        {/* <div className="all-products px-2">
          <div className="row-1"> */}
        <div className="container-fluid">
          <h2 style={{color:"rgba(29, 29, 29, 0.6)"}} className="ps-5">Oil and Gas</h2>
        </div>
        <Swiper
          modules={[Grid, Navigation]}
          spaceBetween={20}
          slidesPerView={3}
          grid={{
            fill: "row",
            rows: 1,
          }}
          breakpoints={{
            360: {
              rows: 1,
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              rows: 1,
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1450: {
              rows: 2,
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          loop={true}
          navigation={true}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
          className="mySwiper"
        >
          {items.map((item, index) => {
            return (
              <SwiperSlide>
                <div className="filter-bx" onClick={handleRedirect}>
                  <div className="filter-img">
                    <img src={item.image} alt="" />
                    <div className="filter-txt">
                      <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
                    </div>
                  </div>
                  
                  <h2 className="project-location">Tamil Nadu</h2>

                  <h3 className="project-title" onClick={() => getData(item.image, item.image)}>
                    {item.service}
                  </h3>

                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>

        <hr/>
        <div className="container-fluid">
          <h2 style={{color:"rgba(29, 29, 29, 0.6)"}} className="ps-5">Pre-Engineered Buildings</h2>
        </div>
        <Swiper
          modules={[Grid, Navigation]}
          spaceBetween={20}
          slidesPerView={3}
          grid={{
            fill: "row",
            rows: 1,
          }}
          breakpoints={{
            360: {
              rows: 1,
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              rows: 1,
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1450: {
              rows: 2,
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          loop={true}
          navigation={true}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
          className="mySwiper"
        >
          {items2.map((item, index) => {
            return (
              <SwiperSlide>
                <div className="filter-bx" onClick={handleRedirect}>
                  <div className="filter-img">
                    <img src={item.image} alt="" />
                    <div className="filter-txt">
                      <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
                    </div>
                  </div>

                  <h2 className="project-location">Tamil Nadu</h2>

                  <h3 className="project-title" onClick={() => getData2(item.image, item.image)}>
                    {item.service}
                  </h3>

                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <hr/>

        <div className="container-fluid">
          <h2 style={{color:"rgba(29, 29, 29, 0.6)"}} className="ps-5">Real State</h2>
        </div>
        <Swiper
          modules={[Grid, Navigation]}
          spaceBetween={20}
          slidesPerView={3}
          grid={{
            fill: "row",
            rows: 1,
          }}
          breakpoints={{
            360: {
              rows: 1,
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              rows: 1,
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1450: {
              rows: 2,
              slidesPerView: 3,
              spaceBetween: 20,
            },
          }}
          loop={true}
          navigation={true}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
          className="mySwiper"
        >
          {items3.map((item, index) => {
            return (
              <SwiperSlide>
                <div className="filter-bx" onClick={handleRedirect}>
                  <div className="filter-img">
                    <img src={item.image} alt="" />
                    <div className="filter-txt">
                      <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
                    </div>
                  </div>

                  <h2 className="project-location">Tamil Nadu</h2>

                  <h3 className="project-title" onClick={() => getData(item.image, item.image)}>
                    {item.service}
                  </h3>

                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        {/* <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
            >
              <Masonry>
                {items.map((item, index) => {
                  return (
                    <>
                      <div className="item" key={index}>
                        <div className="filter-bx">
                          <div className="filter-img">
                            <img src={item.image} alt="" />
                            <div className="filter-txt">
                            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</span>
                          </div>
                          </div>
                            <h3 className="project-title" onClick={() => getData(item.image, item.image)}>
                              {item.service}
                            </h3>
                          
                        </div>
                      </div>
                    </>
                  );
                })}
              </Masonry>
            </ResponsiveMasonry> */}
        {/* </div> */}
        {/* </div> */}
        <ProjectPopup
          image={tempdata[0]}
          trigger={buttonPopup}
          setTrigger={setButtonPopup}
        ></ProjectPopup>
      </div>
    </>
  );
};

export default ProductsFilter;
