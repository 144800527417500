import React, { useEffect, useRef, useState } from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

import contact from './../Images/popup-bg1.jpeg';
import SectionWipes from '../SubComponents/ParallaxHero';
import ProjectImage1 from "../Images/projectimage1.jpg"
import ProjectImage2 from "../Images/projectimage2.jpg"
import ProjectImage3 from "../Images/projectimage3.jpg"
import ProjectImage4 from "../Images/projectimage4.jpg"
import ProjectImage5 from "../Images/projectimage5.jpg"
import './Projectview.css'
import Icon from '@ant-design/icons/lib/components/Icon';
import { Modal } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid, Navigation } from 'swiper/modules';
import { AiOutlineClose, AiOutlineUndo } from "react-icons/ai";
import { useCallback } from 'react';


function ProjectView() {

    const [showModel, setShowModel] = useState(false)
    const [button, setButton] = useState(false)
    const [items, setItems] = useState(["demo", "demo", "demo", "demo", "demo"]);
    const [isMobileView, setIsMobileView] = useState(false)
    const sliderRef = useRef(null);

    useEffect(() => {
        handleCheckMobileView()
    }, [])

    const handleCheckMobileView = (value) => {
        if (window.screen.availWidth <= 767) {
            setIsMobileView(true)
        } else {
            setIsMobileView(false)
        }

        if (value !== undefined) {
            setShowModel(!showModel)
        }
    }

    const handleNext = useCallback(() => {
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
    }, []);

    return (
        <>
            <Header />
            <SectionWipes img={contact} title='Projects' />
            <div className='pos-rel'>
                <section className='first_detail'>
                    <div className='text-center'>
                        <h1>A Natural Fit</h1>
                    </div>
                    <div className='block d-flex justify-content-evenly align-items-start containers px-md-5 px-1' >
                        <div className='details_text'>
                            <p className='mb-4'>LG wanted to consolidate its North American headquarters on a single site across the Hudson River from northern Manhattan.</p>
                            <p className='mb-4'>The facility needed to sustain the Seoul-based company’s leadership in consumer electronics while also protecting the iconic vistas and integrity of the adjacent Palisades Interstate Park, a National Historic Landmark.</p>
                            <p className='mb-4'>HOK’s team collaborated with LG’s leaders and local conservation groups to create a design that nestles the 350,000-sq.-ft. building into the landscape and below the tree line. The horizontal headquarters, or ‘groundscraper,’ is 1,300 feet long—equivalent to a 120-story office tower.</p>
                        </div>
                        <div className='d-xl-block d-none d-flex justify-content-center align-items-center'>
                            <button className='text-gray btn border-0 bg-transparent p-0 m-0' onClick={() => setButton(!button)}>PROJECT FACTS <span class="icon"><svg height="13" viewBox="0 0 13 13" width="13" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" stroke="#999" stroke-linecap="square" transform="translate(1.04 1.04)"><path d="m5.46 0v10.92"></path><path d="m5.46 0v10.92" transform="matrix(0 -1 1 0 0 10.92)"></path></g></svg></span></button>
                        </div>
                    </div>
                    <div className='d-flex flex-column flex-md-row justify-content-between align-items-center img_wrapper block containers px-md-5 px-1' >
                        <div className='overflow-hidden'>
                            <img src={ProjectImage1} alt="img" className='d-inline-block' />
                        </div>
                        <div className='overflow-hidden'>
                            <img src={ProjectImage2} alt="img" className='d-inline-block' />
                        </div>
                    </div>
                    <div className='details_text d-flex justify-content-center align-items-start block containers'>
                        <div className='details_text'>
                            <p className='mb-4 fw-bold fs-5' >Suburban Office Model</p>
                            <p className='mb-4'>LG’s new campus has become a post-COVID-19 model for companies seeking flexible office space outside urban centers.</p>
                            <p className='mb-4'>The design rejects the deep floor plates that have been so common in suburban office buildings. Instead, two narrow office wings rest on a vegetated plinth that appears to grow out of the earth. The office wings each are less than 100 feet wide, allowing daylight to flood the space and providing views of nature. Open floor plates give people plenty of space to spread out. Stairways are located along the perimeter of the building and enclosed with glass, offering a pleasant alternative to elevators.</p>
                            <p className='mb-4'>A three-story glass ‘cube’ with a public atrium links the two buildings.</p>
                        </div>
                    </div>

                    <div className='block px-5 containers'>
                        <hr />
                    </div>
                    <div className='d-flex flex-column flex-md-row justify-content-center align-items-center block containers'>
                        <div className='w-md-75 w-100 slide_img d-flex justify-content-end align-items-center'>
                            <img src={ProjectImage5} alt='img' />
                        </div>
                        <div className='w-md-25 w-100 pt-md-0 pt-4 d-flex flex-column justify-content-center align-items-start ps-4'>
                            <p className='text-gray mb-0'>SLIDESHOW</p>
                            <p className='fw-bold mb-0'>The Glass Cube Connector</p>
                            <p className='w-50'>An 18,000-square-foot, custom-built glass box between the two office wings acts as the fulcrum for campus activity.</p>
                            <p className='text-gray cursor-pointer' onClick={() => handleCheckMobileView("click")}>View Slides <svg height="16" viewBox="0 0 15 16" width="15" xmlns="http://www.w3.org/2000/svg"><g fill-rule="evenodd" opacity=".5" transform="translate(0 .5)"><path d="m13.9047428 12.4643601v.2649021c0 .1493414.1224744.2707378.2735543.2707378h.5481485c.152158 0 .2735544-.1215652.2735544-.2715235v-12.45695302c0-.15112974-.1229081-.27152348-.2745229-.27152348h-12.45095419c-.15002615 0-.27452291.12137505-.27452291.27109877v.55451994c0 .14954047.12263776.27109876.2739191.27109876h.25047227c.00755893-.0013022.01532235-.00198063.02323398-.00198063h11.22053875c.0757177 0 .1370991.06184482.1370991.13709903v11.22053883c0 .0040383-.000176.0080358-.0005204.0119854z"></path><path d="m0 2.27152348c0-.14995828.12449675-.27152348.27452291-.27152348h12.45095419c.1516148 0 .2745229.12039373.2745229.27152348v12.45695302c0 .1499583-.1244968.2715235-.2745229.2715235h-12.45095419c-.15161482 0-.27452291-.1203937-.27452291-.2715235zm.9331467.95787367v10.38107135c0 .0747642.06211068.1346603.13872809.1346603h10.69466031c.0770227 0 .1387281-.0602895.1387281-.1346603v-10.38107135c0-.07476423-.0621107-.1346603-.1387281-.1346603h-10.69466031c-.07702269 0-.13872809.06028946-.13872809.1346603z" fill-rule="nonzero"></path></g></svg></p>
                        </div>
                    </div>
                    <div className='block px-5 containers'>
                        <hr />
                    </div>
                    <div className='w-100 block full-image overflow-hidden'>
                        <img src={ProjectImage3} alt="img" className='w-100' />
                    </div>
                    <div className='details_text d-flex justify-content-center align-items-start block containers'>
                        <div className='details_text'>
                            <p className='mb-4 fw-bold fs-5' >Healthy Work Environment</p>
                            <p className='mb-4'>Along with providing views out to the Palisades environment and river, easy access to the outdoors enhances the mental and physical well-being of LG’s people. The grounds include outdoor terraces, restored woodlands, contemplative Korean gardens, a walking trail that weaves through the site and a basketball court.</p>
                            <p className='mb-4'>The cafeteria opens to a landscaped plaza and the fitness center opens to a courtyard.</p>
                        </div>
                    </div>
                    <div className='w-100 block full-image overflow-hidden'>
                        <img src={ProjectImage3} alt="img" className='w-100' />
                    </div>
                    <div className='block'>
                    </div>
                </section>
                <Footer />
            </div>
            {showModel &&
                <Modal show={showModel} fullscreen onHide={() => setShowModel(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>LG North American Headquarters</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <>
                            {isMobileView ?
                                <>
                                    {items.map((item, index) => {
                                        return (
                                            <div className='d-flex flex-column flex-md-row justify-content-center align-items-center block containers'>
                                                {(items.length - 1) === index ?
                                                    <>
                                                        <div className='w-md-25 w-100 pt-md-0 d-flex justify-content-center align-items-center cursor-pointer' onClick={() => setShowModel(false)}>
                                                            <p className='fw-bold mb-3'>Close Slideshow <AiOutlineClose /></p>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className='w-md-75 w-100 slide_img d-flex justify-content-end align-items-center'>
                                                            <img src={ProjectImage5} alt='img' />
                                                        </div>
                                                        <div className='w-md-25 w-100 pt-md-0 pt-4 d-flex flex-column justify-content-center align-items-start ps-4'>
                                                            <p className='fw-bold mb-0'>The Glass Cube Connector</p>
                                                            <p className='w-75'>An 18,000-square-foot, custom-built glass box between the two office wings acts as the fulcrum for campus activity.</p>
                                                        </div>
                                                        <div className='containers'>
                                                            <hr />
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        )
                                    })}
                                </>
                                :
                                <Swiper
                                    ref={sliderRef}
                                    modules={[Grid, Navigation]}
                                    spaceBetween={70}
                                    slidesPerView={3}
                                    grid={{
                                        fill: "row",
                                        rows: 1,
                                    }}
                                    breakpoints={{
                                        360: {
                                            rows: 1,
                                            slidesPerView: 1,
                                            spaceBetween: 20,
                                        },
                                        768: {
                                            rows: 1,
                                            slidesPerView: 1,
                                            spaceBetween: 50,
                                        },
                                        1450: {
                                            rows: 1,
                                            slidesPerView: 1,
                                            spaceBetween: 70,
                                        },
                                    }}
                                    loop={true}
                                    navigation={true}
                                    onSwiper={(swiper) => console.log(swiper)}
                                    onSlideChange={() => console.log("slide change")}
                                    className="mySwiper"
                                >
                                    {items.map((item, index) => {
                                        return (
                                            <SwiperSlide>
                                                <section className='first_detail'>
                                                    <div className='d-flex flex-column flex-md-row justify-content-center align-items-center block containers'>
                                                        {(items.length - 1) === index ?
                                                            <>
                                                                <div className='block w-md-25 w-100 pt-md-0 pt-4 d-flex flex-column justify-content-center align-items-end ps-4 cursor-pointer' onClick={() => setShowModel(false)}>
                                                                    <p className='fw-bold mb-3'>Close Slideshow <AiOutlineClose /></p>
                                                                </div>
                                                                <div className='block w-md-25 w-100 pt-md-0 pt-4 d-flex flex-column justify-content-center align-items-start ps-4 cursor-pointer' onClick={handleNext}>
                                                                    <p className='fw-bold mb-3'>Restart Slideshow <AiOutlineUndo /></p>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div className='w-md-75 w-100 slide_imgs d-flex justify-content-end align-items-center'>
                                                                    <img src={ProjectImage5} alt='img' />
                                                                </div>
                                                                <div className='w-md-25 w-100 pt-md-0 pt-4 d-flex flex-column justify-content-center align-items-start ps-4'>
                                                                    <p className='fw-bold mb-3'>The Glass Cube Connector</p>
                                                                    <p className='w-50 mb-3'>An 18,000-square-foot, custom-built glass box between the two office wings acts as the fulcrum for campus activity.</p>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </section>
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                            }
                        </>
                    </Modal.Body>
                </Modal>
            }
            {button === true &&
                < div className='popup'>
                    <div className='popup-inner'>
                        <button className='close-btn nav-btn' onClick={() => setButton(false)}><i class="fa-solid fa-xmark"></i></button>
                        <div className="form-section">
                            <div className="contact-area">
                                <div className="contact-form">
                                    <div className="col-lg-12 pt-5 pb-4">
                                        <div className="px-4">
                                            <div className='mt-5'>
                                                <hr style={{ width: "100%" }} />
                                            </div>
                                            <div className='d-flex block'>
                                                <div>
                                                    <p className='text-gray w-75'>CERTIFICATION</p>
                                                    <p className='text-gray w-75 fw-bold'>LEED-NC Platinum</p>
                                                    <p className='text-gray w-75'>SIZE</p>
                                                    <p className='text-gray w-75 fw-bold'>350,000 sq.-ft. / 32,515 sq. m.</p>
                                                    <p className='text-gray w-75'>AWARDS</p>
                                                    <p className='text-gray w-75 fw-bold'>USGBC Leadership Award – Mid-Atlantic & Northeast Region</p>
                                                    <p className='text-gray w-75 fw-bold'>IIDA PA/NJ/DE Chapter – Best of Corporate Honorable Mention Award</p>
                                                    <p className='text-gray w-75 fw-bold'>ASLA Potomac Chapter – Honor Award</p>
                                                    <p className='text-gray w-75 fw-bold'>ASLA Maryland Chapter – Honor Award</p>
                                                    <p className='text-gray w-75 fw-bold'>AIA Philadelphia – General Built</p>
                                                    <p className='text-gray w-75 fw-bold'>AIA Pennsylvania – Architectural Excellence COTE Award</p>
                                                    <p className='text-gray w-75 fw-bold'>AIA New Jersey – Built Sustainability</p>
                                                </div>
                                                <div>
                                                    <p className='text-gray'>SERVICES</p>
                                                    <p className='text-gray fw-bold'>Architecture</p>
                                                    <p className='text-gray fw-bold'>Engineering</p>
                                                    <p className='text-gray fw-bold'>Health + Well-Being</p>
                                                    <p className='text-gray fw-bold'>Interiors</p>
                                                    <p className='text-gray'>Landscape Architecture</p>
                                                    <p className='text-gray fw-bold'>Lighting Design</p>
                                                    <p className='text-gray fw-bold'>Planning + Urban Design</p>
                                                    <p className='text-gray fw-bold'>Regenerative Design</p>
                                                    <p className='text-gray fw-bold'>Sustainable Design</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div >
                    </div>
                </div >
            }
        </>
    )
}

export default ProjectView