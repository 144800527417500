 import structureImage from "../../Images/realestate/real-estate-1.jpg";
import structureImage2 from "../../Images/realestate/real-estate-2.jpg";
import structureImage3 from "../../Images/realestate/real-estate-3.jpg";
import structureImage4 from "../../Images/realestate/real-estate-4.jpg";
import structureImage5 from "../../Images/realestate/real-estate-5.jpg";
import structureImage6 from "../../Images/realestate/real-estate-6.jpg";
import structureImage7 from "../../Images/realestate/real-estate-6.jpg";
import structureImage8 from "../../Images/peb/intel slider images/8.jpg";
import structureImage9 from "../../Images/peb/intel slider images/9.jpg";
import structureImage10 from "../../Images/peb/intel slider images/10.jpg";
import structureImage11 from "../../Images/peb/intel slider images/11.jpg";

export const structuredData = [
  
    {
      "title": "Project Planning & Designing",
      "content": "Effective real estate project planning involves meticulous site analysis, market research, and integration of architectural design. This ensures that commercial buildings align with business needs, while residential structures prioritize functionality and aesthetics.",
      "image": structureImage,    
    },
    {
      "title": "Foundation Design & Construction",
      "content": "A solid real estate foundation balances structural integrity and soil conditions. Techniques like deep piling for commercial projects and versatile shallow foundations for residential buildings lay the groundwork for stability and longevity.",
      "image": structureImage2,
    },
    {
      "title": "Structural Design & Reinforcement",
      "content": "In real estate, structural design combines engineering principles with innovative materials. This optimizes load distribution for commercial edifices and strategically reinforces residential dwellings to withstand loads effectively.",
      "image": structureImage3,
    },
    {
      "title": "Formwork & Reinforcement Placement",
      "content": "Real estate construction employs advanced formwork for precise shaping of commercial spaces and optimized reinforcement positioning in residential structures. This enhances strength and safety while accommodating architectural visions.",
      "image": structureImage4,
    },
    {
      "title": "Construction & Finishing Work",
      "content": "Commercial real estate construction emphasizes efficient execution, while residential projects focus on intricate finishes. Balancing efficiency and attention to detail, this phase transforms blueprints into functional, visually appealing properties.",
      "image": structureImage5,
    },
    {
      "title": "Quality Control & Testing",
      "content": "Real estate quality control involves rigorous inspections at various stages. This guarantees compliance with safety standards and regulations for commercial and residential properties, ensuring longevity and habitability.",
      "image": structureImage6,
    },
    {
      "title": "MEP Installation",
      "content": "Incorporating Mechanical, Electrical, and Plumbing (MEP) systems into real estate demands meticulous planning and execution. This provides efficient climate control, power distribution, and sanitation for commercial spaces and comfortable living in residential units.",
      "image": structureImage7,
    }
  ];
  