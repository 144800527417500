import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import "react-html5video/dist/styles.css";
import SecondaryMenu from "./SecondaryMenu";
import ParallaxSmallBanner from "../SubComponents/ParallaxSmallBanner";
import Img1 from "./../Images/alain-pham-P_qvsF7Yodw-unsplash.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tab from "react-bootstrap/Tab";
import coreimg2 from "./../Images/core principles/a011dc2a-e224-4ff0-975c-b49f3e2f632f.jpg";
import Purpose from "../Images/purpose-1.jpg";
import Goals from "../Images/goals.jpg";
import Mission from "../Images/mission.jpg";
import Vision from "../Images/vision.jpg";
import Values from "../Images/values.jpg";
const Principles = (props) => {
  const [toggleValues, setToggleValues] = useState(false);
  const [toggleGoals, setToggleGoals] = useState(false);
  const [toggleMission, setToggleMission] = useState(false);
  const [toggleVision, setToggleVision] = useState(false);
  const [togglePurpose, setTogglePurpose] = useState(false);
  function handleTogglePurpose() {
    setTogglePurpose((prev) => !prev);
  }
  function handleToggleValues() {
    setToggleValues((prev) => !prev);
  }
  function handleToggleGoals() {
    setToggleGoals((prev) => !prev);
  }
  function handleToggleMission() {
    setToggleMission((prev) => !prev);
  }
  function handleToggleVision() {
    setToggleVision((prev) => !prev);
  }
  return (
    <>
      <Header />
      <div className="scrll">
        <ParallaxSmallBanner img={Img1} title="Core Principles" />
      </div>

      <div className="pos-rel">
        <div className="pad-60 py-5">
          <div className="pt-2 pb-5 text-center">
            <SecondaryMenu linkName={props.linkName} />
          </div>
          <div class="container-fluid">
            {/* <section className="row">
              <div className="col-md-6">
                <img src={Vision} alt="Purpose"/>
              </div>
              <div className="col-md-6">
              <div class="principle-content">
                <div className="d-flex flex-nowrap justify-content-start">
                  <div className="w-5p">&nbsp;</div>
                  <h1 className="manager title">Purpose</h1>
                </div>
                <p>
                  To provide top-notch services to our client that outperform.
                </p>
                </div>
              </div>
            </section> */}
            <section className="principles-layout">
              <div className="d-flex">
                <img src={Vision} alt="Purpose" />
                <div class="principle-content left">
                  <div className="d-flex flex-nowrap justify-content-start">
                    <div className="w-5p">&nbsp;</div>
                    <h1 className="manager title">Purpose</h1>
                  </div>
                  <p>
                    Our purpose is to provide customized steel solutions for our
                    clients. These solutions include the design, fabrication,
                    and installation of steel structures and components for
                    industrial & residential buildings, towers, and other
                    infrastructure projects. Our primary goal is to provide our
                    clients with safe, durable, and cost-effective steel
                    products that meet their specific needs and requirements.
                    This involves working closely with clients to understand
                    their project requirements and developing custom designs
                    that meet those requirements. 
                    <span
                          onClick={handleTogglePurpose}
                          style={{ color: "#ea4433", cursor: "pointer" }}
                        >
                          {!togglePurpose ? "...Read more" : ""}
                        </span>

                    {togglePurpose && (" We provide consulting and engineering services to help our clients determine the most appropriate steel solutions for their projects. Additionally, we offer maintenance and repair services to ensure that the steel structures remain safe and functional over time. To summarise, our purpose is to provide high-quality steel products and services that help clients achieve their construction goals efficiently and effectively.")
                    }
                    <span
                          onClick={handleTogglePurpose}
                          style={{ color: "#ea4433", cursor: "pointer" }}
                        >
                          {!togglePurpose ? "" : "...Read less"}
                        </span>
                  </p>
                </div>
              </div>

              <div className="d-flex">
                <div class="principle-content right">
                  <div className="d-flex flex-nowrap justify-content-start">
                    <div className="w-5p">&nbsp;</div>
                    <h1 className="manager title">Mission</h1>
                  </div>
                  <p>
                    The mission of a steel designing company is a statement that
                    defines its core purpose and reason for being. It outlines
                    the company's fundamental values and goals, and provides a
                    roadmap for its day-to-day operations and long-term
                    strategy.
                    <br />
                    The mission of a steel designing company may include the
                    following elements:
                    <ul>
                      <li>
                        Providing customized steel solutions: Our primary
                        mission is to design and deliver high-quality,
                        customized steel solutions that meet the specific needs
                        and requirements of our clients.
                      </li>

                      <li>
                        Ensuring safety and quality: We are committed in
                        providing safe and high-quality steel products and
                        services that meet or exceed industry standards and
                        regulations.
                        <span
                          onClick={handleToggleMission}
                          style={{ color: "#ea4433", cursor: "pointer" }}
                        >
                          {!toggleMission ? "...Read more" : ""}
                        </span>
                      </li>

                      {toggleMission && (
                        <>
                          <li>
                            Providing exceptional customer service: We aim to
                            provide exceptional customer service by building
                            strong relationships with our clients, understanding
                            their needs, and delivering on its promises.
                          </li>

                          <li>
                            Fostering innovation: We are highly determines &
                            dedicated to fostering a culture of innovation,
                            encouraging our employees to come up with new and
                            better ways to design, fabricate, and install steel
                            structures and components.
                            <span
                              onClick={handleToggleMission}
                              style={{ color: "#ea4433", cursor: "pointer" }}
                            >
                              {!toggleMission ? "" : "...Read less"}
                            </span>
                          </li>
                        </>
                      )}
                    </ul>
                  </p>
                </div>
                <img src={Mission} alt="Mission" />
              </div>
              <div className="d-flex">
                <img src={Purpose} alt="Vision" />

                <div class="principle-content left">
                  <div className="d-flex flex-nowrap justify-content-start">
                    <div className="w-5p">&nbsp;</div>
                    <h1 className="manager title">Vision</h1>
                  </div>
                  <p>
                    The vision of a steel designing company is a statement that
                    outlines the company's long-term aspirations and goals. It
                    reflects the company's desired future state and sets the
                    direction for its strategy and actions. <br />
                    The vision of a steel designing company may include the
                    following elements:
                    <ul>
                      <li>
                        Growth: We aim to grow our business by expanding its
                        operations and entering new markets.
                      </li>
                      <li>
                        Talent development: We prioritize the development of our
                        employees by providing training and development
                        opportunities that enable them to reach their full
                        potential while creating a positive work culture that
                        attracts and retains top talent.
                        <span
                          onClick={handleToggleVision}
                          style={{ color: "#ea4433", cursor: "pointer" }}
                        >
                          {!toggleVision ? "...Read more" : ""}
                        </span>
                      </li>
                      {toggleVision && (
                        <>
                          <li>
                            Reputation: We aspire to be known for our integrity,
                            professionalism, and commitment to excellence,
                            earning a reputation as a trusted partner and a
                            respected industry leader.
                          </li>
                          <li>
                            Leading Partner: To be a leader in the consulting
                            engineer’s community by providing our clients with
                            supreme services, building an abiding association
                            and scaling.
                            <span
                              onClick={handleToggleVision}
                              style={{ color: "#ea4433", cursor: "pointer" }}
                            >
                              {!toggleVision ? "" : "...Read less"}
                            </span>
                          </li>
                        </>
                      )}
                    </ul>
                  </p>
                </div>
              </div>
              <div className="d-flex">
                <div class="principle-content right">
                  <div className="d-flex flex-nowrap justify-content-start">
                    <div className="w-5p">&nbsp;</div>
                    <h1 className="manager title">Values</h1>
                  </div>
                  <p>
                    We believe in treating our customers with respect and faith.
                    We grow through creativity, invention and innovation. We
                    integrate honesty, integrity and business functioning.
                    <ul>
                      <li>
                        Quality: We strive to provide high-quality steel
                        services and products that meet or exceed industry
                        standards and our customer expectations.
                      </li>

                      <li>
                        Innovation: We prioritize staying up-to-date with the
                        latest technology, techniques, and trends in steel
                        designing and manufacturing, alongside encouraging
                        creative thinking and problem-solving amongst our
                        employees.
                      </li>

                      <li>
                        Safety: Prioritizing the safety of our employees,
                        customers, and the environment, while adhering to strict
                        safety protocols and regulations.
                        <span
                          onClick={handleToggleValues}
                          style={{ color: "#ea4433", cursor: "pointer" }}
                        >
                          {!toggleValues ? "...Read more" : ""}
                        </span>
                      </li>

                      {toggleValues && (
                        <>
                          <li>
                            Integrity: We value honesty, transparency, and
                            ethical business practices in all of our dealings
                            with customers, suppliers, and employees.
                          </li>

                          <li>
                            Customer service: We find value in providing
                            excellent customer service, and endeavour to build
                            strong, long-lasting relationships with our clients
                            by being responsive, attentive, and in-touch with
                            the latest technological reformations in & around
                            us.
                          </li>

                          <li>
                            Sustainability: We are aware about our
                            responsibilities towards the environment and adopt
                            sustainable methodologies in our operations, hence
                            minimizing its impact on the environment through
                            practices such as zero-waste designing and
                            recycling.
                          </li>

                          <li>
                            Teamwork: We value teamwork, encourage
                            collaboration, communication, and mutual respect
                            amongst our employees, hence promoting a positive
                            work culture.
                          </li>

                          <li>
                            Efficiency: We efficiently maximize our operations
                            to reduce costs and improve productivity. This
                            involves optimizing processes and utilizing the
                            latest technology to improve the speed and accuracy
                            of our work.
                          </li>

                          <li>
                            Innovation: Innovation is the key to staying
                            competitive in the steel industry. We are constantly
                            looking for new and better ways to design and
                            manufacture steel products, as well as to improve
                            our existing products & strategies.
                            <span
                              onClick={handleToggleValues}
                              style={{ color: "#ea4433", cursor: "pointer" }}
                            >
                              {!toggleValues ? "" : "...Read less"}
                            </span>
                          </li>
                        </>
                      )}
                    </ul>
                  </p>
                </div>
                <img src={Values} alt="Values" />
              </div>
              <div className="d-flex">
                <img src={Goals} alt="Goals" />
                <div class="principle-content left">
                  <div className="d-flex flex-nowrap justify-content-start">
                    <div className="w-5p">&nbsp;</div>
                    <h1 className="manager title">Goals</h1>
                  </div>
                  <p>
                    <ul style={{ marginBottom: 0 }}>
                      <li>
                        Delivering high-quality products and services: We aim to
                        provide our clients with safe, durable, and
                        cost-effective steel solutions that meet their specific
                        needs and requirements.
                      </li>
                      <li>
                        Meeting project timelines and budgets: We strive to
                        complete projects within the agreed-upon timelines and
                        budgets, while maintaining the quality of work.
                      </li>
                      <li>
                        Improving operational efficiency: We strive to
                        streamline our processes, reduce waste, thus increase
                        productivity to improve our operational efficiency and
                        reduce costs.
                        
                      </li>
                      <li>
                        Expanding its market reach: We seek to expand our
                        business by entering new markets, offering new products
                        and services, while forming strategic partnerships with
                        other companies.<span
                          onClick={handleToggleGoals}
                          style={{ color: "#ea4433", cursor: "pointer" }}
                        >
                          {!toggleGoals ? "...Read more" : ""}
                        </span>
                      </li>
                    </ul>
                    {toggleGoals && (
                      <span>
                        To become a leading key player in the industry, while
                        amplifying our roots regionally in the field of property
                        management, and to build a strong base of key customers.
                        Scale the assets of the company to support the
                        development of services, while maintaining a good
                        reputation in the real estate and property management
                        community.
                        <span
                          onClick={handleToggleGoals}
                          style={{ color: "#ea4433", cursor: "pointer" }}
                        >
                          {!toggleGoals ? "" : "...Read less"}
                        </span>
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </section>
          </div>
          {/* <div className="row">
            <div className="col-lg-12">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div className="row certificates w-100 mx-0">
                  <div className="col-lg-3 my-auto px-0">
                    <div className="contact-heading mb-4">
                      <h1>It all began with a design brief.</h1>
                      <p>
                        A quick, interactive guide helped them understand their
                        design style and captured exactly what they needed in
                        their poster.
                      </p>
                    </div>

                    <Nav variant="pills" className="flex-column">
                      <Nav.Item className="gr-bg pills-li">
                        <Nav.Link eventKey="first">Purpose</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="gr-bg pills-li">
                        <Nav.Link eventKey="second">Core Values</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="gr-bg pills-li">
                        <Nav.Link eventKey="third">Vision</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="gr-bg pills-li">
                        <Nav.Link eventKey="fourth">Goals</Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="gr-bg pills-li">
                        <Nav.Link eventKey="fifth">Mission</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <div className="col-lg-9 px-0 mb-lg-0 mb-4">
                    <div className="h-100 p-5 rel-pos">
                      <div className="shadow p-4 h-100 padd-100">
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            <div class="core-bx text-center">
                              <div class="core-title">
                                <h1>Purpose</h1>
                              </div>
                              <div class="core-desc">
                                <p>
                                  To provide top-notch services to our client
                                  that outperform.
                                </p>
                              </div>
                            </div>
                            <div className="certificate-img">
                              <img src="" className="" alt="" />
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                            <div class="core-bx text-center">
                              <div class="core-title">
                                <h1>Core Values</h1>
                              </div>
                              <div class="core-desc">
                                <p>
                                  We believe in treating our customers with
                                  respect and faith. We grow through creativity,
                                  invention and innovation. We integrate
                                  honesty, integrity and business functioning.
                                </p>
                              </div>
                            </div>
                            <div className="certificate-img">
                              <img src={coreimg2} className="" alt="" />
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="third">
                            <div class="core-bx text-center">
                              <div class="core-title">
                                <h1>Vision</h1>
                              </div>
                              <div class="core-desc">
                                <p>
                                  To be a leader in the consulting engineer’s
                                  community by providing our clients with
                                  supreme services, building an abiding
                                  association and scaling.
                                </p>
                              </div>
                            </div>
                            <div className="certificate-img">
                              <img src="" className="" alt="" />
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="fourth">
                            <div class="core-bx text-center">
                              <div class="core-title">
                                <h1>Goals</h1>
                              </div>
                              <div class="core-desc">
                                <p>
                                  To become a leading key player in the
                                  industry, while amplifying our roots
                                  regionally in the field of property
                                  management, and to build a strong base of key
                                  customers. Scale the assets of the company to
                                  support the development of services, while
                                  maintaining a good reputation in the real
                                  estate and property management community.
                                </p>
                              </div>
                            </div>
                            <div className="certificate-img">
                              <img src="" className="" alt="" />
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="fifth">
                            <div class="core-bx text-center">
                              <div class="core-title">
                                <h1>Mission</h1>
                              </div>
                              <div class="core-desc">
                                <p>
                                  To provide top-notch services to our client
                                  that outperform.
                                </p>
                              </div>
                            </div>
                            <div className="certificate-img">
                              <img src="" className="" alt="" />
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div> */}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Principles;
