
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import abt3 from './../Images/abt3.jpg';
// import { IoArrowForward } from "react-icons/io5";
// import { IoArrowBack, IoTrendingUpSharp } from "react-icons/io5";
// import { BsStarFill, BsStarHalf, BsStar } from "react-icons/bs";
// import { BiCheckShield } from "react-icons/bi";

// import { FaStar, FaChevronRight, FaChevronLeft, FaUserAlt, FaMapMarkerAlt, FaWallet, FaRegBuilding } from "react-icons/fa";


// import client1 from './../Images/HINDALCO.png';
// import client2 from './../Images/gcc-hotel-and-club.png';
// import client3 from './../Images/HP.jpg';
// import client4 from './../Images/indian-oil.jpg';
// import client5 from './../Images/ltgrouplogo.jpg';
// import client6 from './../Images/platinum.jpg';
// import client7 from './../Images/Raheja_Universal.jpg';
// import client8 from './../Images/sail.png';



export default function TrustedSlider() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 4,
        autoplayHoverPause: true,
        slidesToScroll: 1,
        // prevArrow: <PrevButton />,
        // nextArrow: <NextButton />,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,

                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,

                }
            }

        ],
        beforeChange: (current, next) => {
            console.log(next);
        }
    };
    return (

        <div className="w-100 over-x">

            <Slider {...settings}>

                <div className="item slide py-3 px-2">

                
                        <div class="trusted-bx shadow">
                            <div class="trusted-img">
                                <img src={abt3} alt="" />
                            </div>
                            <div class="trusted-txt text-center p-3">
                                <h3>Lorem Ipsum</h3>
                                <p>Designing and manufacturing of equipment, equipment supporting structures, platforms</p>
                            </div>
                        </div>

                  
                </div>
                <div className="item slide py-3 px-2">

                   
                        <div class="trusted-bx shadow">
                            <div class="trusted-img">
                                <img src={abt3} alt="" />
                            </div>
                            <div class="trusted-txt text-center p-3">
                                <h3>Lorem Ipsum</h3>
                                <p>Designing and manufacturing of equipment, equipment supporting structures, platforms</p>
                            </div>
                        </div>

                 
                </div>
                <div className="item slide py-3 px-2">

                    
                        <div class="trusted-bx shadow">
                            <div class="trusted-img">
                                <img src={abt3} alt="" />
                            </div>
                            <div class="trusted-txt text-center p-3">
                                <h3>Lorem Ipsum</h3>
                                <p>Designing and manufacturing of equipment, equipment supporting structures, platforms</p>
                            </div>
                        </div>

                </div>


                <div className="item slide py-3 px-2">

                  
                        <div class="trusted-bx shadow">
                            <div class="trusted-img">
                                <img src={abt3} alt="" />
                            </div>
                            <div class="trusted-txt text-center p-3">
                                <h3>Lorem Ipsum</h3>
                                <p>Designing and manufacturing of equipment, equipment supporting structures, platforms</p>
                            </div>
                        </div>

                    
                </div>
                <div className="item slide py-3 px-2">

                   
                        <div class="trusted-bx shadow">
                            <div class="trusted-img">
                                <img src={abt3} alt="" />
                            </div>
                            <div class="trusted-txt text-center p-3">
                                <h3>Lorem Ipsum</h3>
                                <p>Designing and manufacturing of equipment, equipment supporting structures, platforms</p>
                            </div>
                        </div>

                    
                </div>
                <div className="item slide py-3 px-2">

                    <div class="trusted-bx shadow">
                        
                            <div class="trusted-img">
                                <img src={abt3} alt="" />
                            </div>
                            <div class="trusted-txt text-center p-3">
                                <h3>Lorem Ipsum</h3>
                                <p>Designing and manufacturing of equipment, equipment supporting structures, platforms</p>
                            </div>
                      

                    </div>
                </div>
            </Slider>
        </div>
    );
}