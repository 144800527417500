import React, { useRef, useState, useEffect } from "react";
import "./../SubComponents/vid.css";
import video1 from "./../Images/intro-videos/intro1.mp4";
import video2 from "./../Images/intro-videos/intro2.mp4";
import video3 from "./../Images/intro-videos/intro3.mp4";
import video4 from "./../Images/intro-videos/intro4.mp4";
import video5 from "./../Images/intro-videos/intro5.mp4";
const delays = [10000, 55000, 23000, 20000, 13000];
const videos = [video1, video2, video3, video4, video5];

function VideoPlayer() {
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === videos.length - 1 ? 0 : prevIndex + 1
        ),
      delays[index]
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className="slideshow">
      <div
        className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {videos.map((videoSource, idx) => (
          <video
            className="slide"
            key={idx}
            src={videoSource}
            autoPlay
            muted
            loop
            onEnded={() =>
              setIndex((prevIndex) =>
                prevIndex === videos.length - 1 ? 0 : prevIndex + 1
              )
            }
          ></video>
        ))}
      </div>

      <div className="slideshowDots" style={{ zIndex: 100 }}>
        {videos.map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${index === idx ? " active" : ""}`}
            onClick={() => {
              setIndex(idx);
            }}
          ></div>
        ))}
      </div>
    </div>
  );
}
export default VideoPlayer;
