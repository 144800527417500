import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Award2 from "./../Images/awards-and-achievements/Certificate.jpg";
import Award3 from "./../Images/awards-and-achievements/Trophy.jpeg";
import Award4 from "./../Images/awards-and-achievements/certificate2.jpeg";
import Award5 from "./../Images/awards-and-achievements/award1.png";
import "react-html5video/dist/styles.css";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import ParallaxSmallBanner from "../SubComponents/ParallaxSmallBanner";
import Img1 from "./../Images/alain-pham-P_qvsF7Yodw-unsplash.jpg";
import SecondaryMenu from "./SecondaryMenu";

function Achievements(props) {
  return (
    <>
      <Header />
      <div className="scrll">
        <ParallaxSmallBanner img={Img1} title="Achievements" />
      </div>
      <div className="pos-rel">
        <div className="w-100 py-5  pad-60">
          <div className="pt-2 pb-5 text-center">
            <SecondaryMenu linkName={props.linkName} />
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="masonry">
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                >
                  <Masonry>
                    <div className="masonry-img">
                      <div className="shadow">
                        <img src={Award4} className="w-100" alt="" />
                        <div className="masonry-content p-3">
                          <h2>Indian Achievers' Award 2023</h2>
                          <h6 className="text-muted">Business Leadership</h6>
                        </div>
                      </div>
                    </div>
                    <div className="masonry-img">
                      <div className="shadow">
                        <img src={Award5} className="w-100" alt="" />
                        <div className="masonry-content p-3">
                          <h2>Indian Achievers' Award 2022</h2>
                          <h6 className="text-muted">Business Leadership</h6>
                        </div>
                      </div>
                    </div>
                    <div className="masonry-img">
                      <div className="shadow">
                        <img src={Award2} className="w-100" alt="" />
                        <div className="masonry-content p-3">
                          <h2>Indian Achievers' Award 2021</h2>
                          <h6 className="text-muted">For Young Entrepreneur</h6>
                        </div>
                      </div>
                    </div>
                    <div className="masonry-img">
                      <div className="shadow">
                        <img src={Award3} className="w-100" alt="" />
                        <div className="masonry-content p-3">
                          <h2>Indian Achievers' Award 2021</h2>
                          <h6 className="text-muted">For Young Entrepreneur</h6>
                        </div>
                      </div>
                    </div>
                  </Masonry>
                </ResponsiveMasonry>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default Achievements;
